.checkout-form {
  .checkout-step {
    padding: 28px 24px 40px;
    background: $color-white;

    &.summary-step {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.last-step {
      padding-top: 20px;
    }
  }

  h2 {
    margin-bottom: 26px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: $color-black;
  }

  .products-summary-wrp {
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: 33px;
    padding: 16px 24px 20px;
    border-top: 1px solid $color-lighter-grey;
    border-bottom: 1px solid $color-lighter-grey;
  }

  .order-summary-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin-bottom: 0;
    }
  }

  .checkout-products-list {
    .inner {
      padding-top: 20px;
    }

    .product-card-small {
      align-items: center;

      + .product-card-small {
        margin-top: 32px;
      }

      .img-wrp {
        img {
          width: 72px;
          height: 72px;
        }
      }

      .details {
        .name {
          color: $color-grey;
        }

        .price-qnt {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .quantity {
            margin-left: 12px;
            font-family: $font-secondary;
          }
        }
      }
    }
  }

  //.buyer-details {
  //  margin-bottom: 40px;
  //
  //  p {
  //    margin-bottom: 12px;
  //    font-family: $font-secondary;
  //    font-size: 16px;
  //    line-height: 1.125;
  //
  //    &.title {
  //      margin-bottom: 16px;
  //      font-weight: 600;
  //    }
  //  }
  //
  //  .cta-btn {
  //    margin-top: 20px;
  //  }
  //}

  .delivery-options {
    margin-bottom: 32px;
  }
  
  .checkout-step {
    &.step-1 {
      .checkbox-group {
        .checkbox {
          display: inline;
        }
      }
    }
  }

  .bonus-euro {
    margin-bottom: 32px;
    padding: 24px 18px;
    background: $color-lighter-grey;

    h3 {
      margin-bottom: 2px;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.33;
      color: $color-natural-black;
    }

    .bonus-input-wrp {
      margin-top: 20px;
    }

    input {
      height: 48px;
      margin-bottom: 8px;
      border: 0;
      padding: 0 20px;
    }

    .bonus-euro {
      width: 100%;
    }

    .euro-available {
      font-family: $font-secondary;
      color: $color-natural-black;

      .outlet-euros {
        display: inline-flex;
        align-items: center;
        margin-left: 5px;
        font-weight: 600;

        .outlet-icon {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          margin-top: -1px;
          margin-right: 6px;
          width: 20px;
          height: 20px;
          background: $color-red;

          i {
            font-size: 12px;
            color: $color-white;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    h2 {
      font-size: 32px;
    }

    .bonus-euro {
      .bonus-input-wrp {
        display: flex;
        align-items: center;

        input {
          margin-bottom: 0;
        }

        .cta-btn {
          min-width: 129px;
          max-width: 200px;
          margin-left: 8px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .container-content {
      display: grid;
      grid-template-columns: 1.02fr 0.7fr;
      gap: 8px 20px;
      grid-template-areas:
      "step-1 summary-step"
      "step-2 summary-step"
      "last-step summary-step";
    }

    .products-summary-wrp {
      position: relative;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      border-top: 0;
      border-bottom: 0;

      &.show {
        &:after {
          background: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: -32px;
        right: -32px;
        height: 70px;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%);
      }

      .show-more {
        position: absolute;
        bottom: 18px;
        left: 50%;
        z-index: 1;
        align-items: center;
        transform: translateX(-50%);
        padding: 11px 16px;
        border: 0;
        background: $color-white;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 10px;
        line-height: 1;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $color-black;
        transition: all 0.3s ease;

        &:hover {
          background: $color-black;
          color: $color-white;
        }

        i {
          margin-left: 12px;
          font-size: 10px;
        }
      }
    }

    .checkout-products-list {
      max-height: 294px;
      transition: all 0.3s ease;
      //overflow: hidden;

      .inner {
        padding-top: 0;
        padding-bottom: 70px;
      }
    }

    .checkout-step {
      &.step-1 {
        grid-area: step-1;
      }

      &.step-2 {
        grid-area: step-2;
      }

      &.summary-step {
        position: sticky;
        top: 88px;
        grid-area: summary-step;
        align-self: flex-start;
        padding: 32px;

        h2 {
          margin-bottom: 15px;
        }
      }

      &.last-step {
        grid-area: last-step;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .container-content {
      gap: 8px 64px;
    }

    .content-right-sidebar {
      gap: 8px 64px;
    }

    .checkout-step {
      padding: 40px;
    }
  }
}