.customer-order-history {
  display: grid;
  row-gap: 2px;

  h1 {
    margin-bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .order-history-item {
    .product-images {
      display: grid;
      gap: 2px;
      grid-auto-rows: 50%;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      aspect-ratio: 1 / 1;

      &.single {
        grid-auto-rows: 100%;
        grid-template-columns: 1fr;
      }

      .img {
        height: 100%;
        background: $color-lighter-grey;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          padding: 10%;
        }
      }
    }

    .short-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 23px 38px;

      h3 {
        margin-bottom: 5px;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 10px;
        line-height: 1.5;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: $color-grey;
      }

      p {
        margin-bottom: 0;
        font-family: $font-secondary;
        color: $color-black;
      }

      .status {
        position: relative;
        display: flex;
        align-items: center;
        grid-column: span 2;
        margin-top: 22px;
        margin-bottom: 5px;
        font-style: italic;
        font-size: 18px;
        line-height: 1;
        color: $color-black;

        img {
          margin-right: 8px;
        }

        i {
          font-size: 18px;
          margin-right: 8px;

          &.fa-arrow-right {
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      padding-top: 43px;
      padding-bottom: 43px;
    }

    .order-history-item {
      display: flex;
      align-items: center;

      .product-images {
        width: 170px;
      }

      .short-details {
        flex-grow: 1;
        padding-left: 48px;
        padding-right: 48px;

        .status {
          margin-top: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .order-history-item {
      .short-details {
        .status {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .order-history-item {
      .product-images {
        width: 150px;
      }

      .short-details {
        row-gap: 15px;
        padding-left: 30px;
        padding-right: 0;

        .item {
          grid-column: span 2;
        }

        .status {
          &:hover {
            span {
              text-decoration-line: underline;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .order-history-item {
      .product-images {
        width: 170px;
      }

      .short-details {
        row-gap: 23px;
        padding-left: 48px;
        padding-right: 48px;

        .item {
          grid-column: span 1;
        }
      }
    }
  }
}