.bootstrap-select {
  min-width: 100%;

  &.type-grey {
    .btn {
      padding: 17px 20px;
      border: 0;
      background: $color-lighter-grey;

      &.bs-placeholder {
        color: $color-black !important;
      }
    }

    .dropdown-menu {
      background: $color-lighter-grey;
      border: 0;

      li:hover > .dropdown-item {
        background: $color-lighter-grey-2;
      }

      .active {
        > .dropdown-item {
          background: $color-lighter-grey-2;
        }
      }
    }
  }

  .btn {
    padding: 12px 14px 12px 16px;
    border: 1px solid $color-light-grey;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: $color-black;

    &:after {
      border-top-color: $color-black;
    }

    &.dropdown-toggle {
      &:focus {
        outline: none !important;
      }
    }

    &.show {
      border-color: $color-black;
    }

    &.bs-placeholder {
      color: $color-grey !important;
    }
  }

  .dropdown-menu {
    padding: 8px 0 12px;
    border-color: $color-black;
    box-shadow: none;

    li:hover > .dropdown-item {
      background: $color-lighter-grey;
      color: $color-black;
    }

    .active {
      > .dropdown-item {
        background: $color-lighter-grey;
        color: $color-black;
        font-weight: 600;
      }
    }
  }

  .dropdown-item {
    padding: 9px 16px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: $color-black;
  }
}