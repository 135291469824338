.page-block-banners {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .item {
    position: relative;
    background-color: $color-lighter-grey;
    padding: 48px 24px 40px;

    &._2 {
      margin-bottom: 24px;
    }

    &.photo {
      padding: 0;

      &.cover {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $color-natural-black;
          opacity: 0.6;
        }

        .logo {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate3d(-50%, -50%, 0);
          width: 192px;
          height: auto;
        }
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .content {
      width: 100%;

      h3 {
        margin-bottom: 15px;
        padding-left: 3px;
        padding-right: 3px;
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2;
        color: $color-black;
      }

      p {
        margin-bottom: 40px;
        padding-left: 3px;
        padding-right: 3px;
        font-style: italic;
        letter-spacing: 0.02em;
      }

      .cta-btn {
        width: 100%;
      }
    }
  }

  #subscribers_subscribe {
    form {
      input {
        width: 100%;
        height: 48px;
        margin-bottom: 12px;
        padding: 0 20px;
        border: 0;
        background-color: $color-white;
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 14px;
        color: $color-black;

        &::placeholder {
          color: $color-light-grey;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .container {
      display: flex;
      flex-wrap: wrap;
      padding-left: 24px;
      padding-right: 24px;
    }

    .item {
      display: flex;
      align-items: center;
      width: 50%;
      min-height: 360px;
      padding: 48px 78px 40px;

      &.photo {
        picture {
          display: block;
          height: 100%;

          img {
            height: 100%;
            object-fit: cover;
          }
        }
      }

      &._1 {
        order: 2;
      }

      &._2 {
        order: 1;
        margin-bottom: 0;
      }

      &._3 {
        order: 3;
      }

      &._4 {
        order: 4;
      }

      .content {
        .cta-btn {
          width: auto;
        }
      }
    }

    #subscribers_subscribe {
      form {
        .cta-btn {
          width: 100%;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .item {
      min-height: 419px;

      &.photo {
        padding: 0;

        &.cover {
          .logo {
            width: 247px;
          }
        }
      }

      .content {
        h3 {
          font-size: 32px;
        }

        p {
          margin-bottom: 60px;
          font-size: 18px;
        }
      }
    }

    #subscribers_subscribe {
      margin-top: 75px;

      form {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        input {
          width: auto;
          flex-grow: 1;
          margin-bottom: 0;
        }

        .cta-btn {
          width: auto;
        }
      }
    }
  }
}