.template-2sides-content {
  padding-bottom: 32px;
  margin-bottom: 45px;
  border-bottom: 1px solid $color-lighter-grey-2;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 45px;
    border-bottom: 0;
  }

  > *:last-child {
    margin-bottom: 0 !important;
  }

  .full-content {
    margin-bottom: 28px;

    > *:last-child {
      margin-bottom: 0 !important;
    }
  }

  .template-notice {
    margin-bottom: 0;
  }

  .deliveries-options {
    margin-top: 40px;
    margin-bottom: 40px;

    .do-item {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid $color-lighter-grey-2;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      .l {
        margin-bottom: 10px;
      }

      h5 {
        margin-bottom: 0;

        i {
          margin-right: 12px;
          font-size: 16px;
        }

        img {
          max-width: 22px;
          max-height: 22px;
          margin-right: 12px;
        }
      }

      p {
        margin-top: 10px;
      }
    }
  }

  .side {
    margin-bottom: 28px;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.left {
      h5 {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
      }
    }
  }

  h5 {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.125;
    color: $color-black;

    i {
      margin-right: 16px;
      font-size: 24px;
    }

    img {
      max-width: 34px;
      max-height: 24px;
      width: auto;
      height: auto;
      object-fit: contain;
      margin-right: 16px;
    }
  }

  a {
    i {
      margin-right: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 30px 80px;
    padding-bottom: 48px;

    .template-notice {
      grid-column: span 2;
    }

    .full-content {
      grid-column: span 2;
      margin-bottom: 0;
    }

    .deliveries-options {
      grid-column: span 2;
      margin-top: 0;
      margin-bottom: 0;

      .do-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 0;
        padding-bottom: 14px;
        padding-top: 14px;

        .l, .r {
          width: 50%;
        }

        .r {
          text-align: right;
        }
      }
    }

    .side {
      margin-bottom: 0;

      &.right {
        text-align: right;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    column-gap: 50px;

    .side {
      &.left {
        h5 {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 16px;

          img, i {
            margin-bottom: 20px;
            margin-right: 0;
          }
        }
      }

      &.right {
        padding-top: 44px;
      }

      .cta-btn {
        margin-top: 12px;
      }
    }

    a {
      i {
        font-size: 18px;
      }
    }
  }
}