#filters {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 48px;
  background-color: $color-white;
  z-index: $zindex-fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  #filter {
    padding-bottom: 48px;
    overflow: auto;

    @include media-breakpoint-down(lg) {
      padding-bottom: 88px;
      
      .buttons {
        display: block!important;
        background-color: #fff;
        border-top: 1px solid #EBEBEB;
        height: 88px;
        width: 100%;
        position: absolute;
        padding: 20px;
        bottom: 0;
        z-index: 1;
      }
    }
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  h2 {
    margin-bottom: 20px;
    padding-left: 40px;
    padding-right: 40px;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    color: $color-black;
  }

  .filter-item {
    margin-left: 40px;
    margin-right: 40px;
    transition: all 0.3s ease-in-out;

    &.open {
      //background: $color-lighter-grey;

      h3 {
        i {
          &.fa-minus {
            display: block;
          }

          &.fa-plus {
            display: none;
          }
        }
      }
    }

    .checkbox {
      padding-left: 24px;
      input {
        &:checked {
          ~ .box {
            background: $color-black;

            i {
              display: block;
            }
          }
        }
      }

      /*.box {
        background: $color-lighter-grey-2;

        i {
          color: $color-white;
        }
      }*/
    }
    
    h3 {
      position: relative;
      padding-top: 18px;
      padding-bottom: 12px;
      margin-bottom: 0;
      font-family: $font-secondary;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.11;
      color: $color-black;
      cursor: pointer;

      i {
        &.fa-minus,
        &.fa-plus {
          position: absolute;
          top: 50%;
          right: -5px;
          z-index: 1;
          padding: 5px;
          transform: translateY(-50%);
          font-size: 16px;
          opacity: 0.2;
        }

        &.fa-minus,
        &.fa-caret-down {
          display: none;
        }
      }
    }
    
    &.selected {
      h3:before {
        content: '';
        position: absolute;
        left: -15px;
        top: 23px;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $color-red;
      }
    }

    .inner-content {
      padding-top: 12px;
      padding-bottom: 32px;
    }

    .form-item {
      margin-bottom: 25px;
    }

    .filter-item-list {
      &.limit-list-display {
        .filter-item-list-item {
          &:nth-child(n+6) {
            display: none;
          }
        }
      }

      .filter-item-list-item {
        &.inactive-filter:not(.active) {
          pointer-events: none;
          opacity: 0.5;
          
          .checkbox {
            /*.box {
              background: $color-lighter-grey-4;
            }*/

            .filter-item-list-item-text {
              color: $color-light-grey;
            }
          }
        }
      }
    }

    ul {
      li {
        + li {
          margin-top: 12px;
        }
      }
    }

    .custom-prices {
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 94px;
        border: 0;
        border-bottom: 1px solid $color-light-grey;
        background: none;
        font-family: $font-secondary;

        &::placeholder {
          color: $color-grey;
        }
      }
    }
  }

  .filter-limit-btn {
    display: inline-block;
    font-family: $font-secondary;
    font-weight: 500;
    margin-top: 15px;
    color: $color-red;
    text-decoration: underline;
  }

  @include media-breakpoint-down(lg) {
    .filter-item {
      border-bottom: 1px solid #F6F6F6;
      
      h3 {
        padding: 15px 0;
      }
    }
  }
  
  @include media-breakpoint-up(xs) {
    h2 {
      font-size: 24px;
    }

    .filter-item {
      h3 {
        font-size: 18px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: static;
    z-index: 1;
    width: 25%;
    max-width: 255px;
    height: auto;
    padding-top: 0;
    background: none;
    opacity: 1;
    visibility: visible;

    h2 {
      display: none;
    }

    #filter {
      overflow: visible;
    }

    .filter-item {
      padding-left: 0;
      padding-right: 0;
      margin-left: 0;
      margin-right: 0;

      &.open {
        background: none;

        h3 {
          i {
            &.fa-minus {
              display: none;
            }

            &.fa-plus {
              display: none;
            }
          }
        }
      }

      &:first-of-type {
        h3 {
          padding-top: 5px;
        }
      }

      h3 {
        display: inline-flex;
        align-items: center;
        padding-top: 15px;
        font-weight: 600;

        i {
          position: static;
          margin-left: 13px;
          transform: none;
          font-size: 14px;
          opacity: 1;

          &.fa-minus {
            display: none;
          }

          &.fa-plus {
            display: none;
          }

          &.fa-caret-down {
            display: inline-block;
          }
        }
      }

      .inner-content {
        padding-top: 8px;
        padding-bottom: 22px;
      }

      ul {
        li {
          + li {
            margin-top: 15px;
          }
        }
      }

      .checkbox {
        input {
          &:checked {
            ~ .box {
              background: $color-black;

              i {
                display: block;
              }
            }
          }
        }

        /*.box {
          background: $color-lighter-grey-2;

          i {
            color: $color-white;
          }
        }*/
      }
    }

    .filter-limit-btn {
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    width: 25.5%;
  }
}

.filter_summary {
  display: flex;
  gap: 5px;
  
  button {
    border: none;
    height: 40px;
    padding: 10px 12px;
    background-color: #F6F6F6;
    color: $color-black;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-secondary;
    
    i {
      font-size: 13px;
      margin-right: 5px;
    }

  }
}

.filter-btn.clean_all {
  font-weight: 500;
  font-size: 14px;
  border: none;
  height: 40px;
  padding: 10px 12px;
  background-color: transparent;
  font-family: $font-secondary;
  color: $color-black;
  
  span {
    text-decoration: underline;
  }
  i {
    font-size: 14px;
  }
}

#filter {
  h2 {
    .clean_all {
      margin-left: 20px;
      font-style: normal;
    }
  }
}