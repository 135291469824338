.template-text-image-bottom {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .ttib-content {
    margin-top: 25px;
    order: 2;

    h2 {
      margin-bottom: 20px;
      font-family: $font-primary;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.16;
    }
  }

  img {
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;

    .ttib-content {
      max-width: 80%;
      margin: 0 auto 30px;
      order: 1;
      text-align: center;
    }

    img {
      order: 2;
    }
  }
  @include media-breakpoint-up(xl) {
    .ttib-content {
      max-width: 75%;
      margin-bottom: 50px;

      h2 {
        font-size: 32px;
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    .ttib-content {
      max-width: 70%;
    }
  }
}