#profile-dropdown {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 48px 24px 70px;
  background-color: $color-white;
  z-index: $zindex-fixed;
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  .customer-name {
    margin-bottom: 5px;
    font-style: italic;
    font-weight: 400;
    font-size: 32px;
    line-height: 1;
    color: $color-black;
  }

  .customer-loyalty-euros {
    margin-bottom: 0;
    font-family: $font-secondary;

    span {
      font-weight: 600;
    }
  }
  
  .info-text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px;
  }
  
  .buttons {
    margin-top: 20px;
    margin-bottom: 10px;
    
    a:nth-child(1) {
      margin-bottom: 8px;
    }
    
    a:hover {
      color: #fff;
    }
  }

  ul {
    padding-top: 28px;
    margin-top: 20px;
    margin-left: -24px;
    margin-right: -24px;
    border-top: 1px solid $color-lighter-grey;

    li {
      margin-top: 20px;
      padding-left: 24px;
      padding-right: 24px;

      &:first-child {
        margin-top: 0;
      }

      &.logout {
        margin-top: 30px;
        padding-top: 26px;
        border-top: 1px solid $color-lighter-grey;
      }

      a {
        position: relative;
        display: inline-block;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        color: $color-black;

        i {
          width: 24px;
          margin-right: 16px;
          text-align: center;
          font-size: 18px;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    left: unset;
    width: 356px;
    height: auto;
    padding: 32px 40px 28px;
    overflow: visible;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

    ul {
      margin-left: -40px;
      margin-right: -40px;

      li {
        padding-left: 40px;
        padding-right: 40px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    ul {
      li {
        a {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    ul {
      li {
        a {
          &:hover {
            color: $color-hover-red;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    transform: translateX(-50%);
    right: unset;
    left: 0;
  }
}