#shop-categories {
  .accordion-item {
    border: 0;
    border-radius: 0 !important;
  }

  .accordion-header {
    padding-top: 0;
    padding-bottom: 0;

    &.no-childs {
      .accordion-button {
        font-size: 14px;
        font-weight: 400;

        &:after {
          display: none;
        }
      }
    }
  }

  .accordion-button {
    padding: 0 0 12px 0;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 20px;
    color: $color-black;
    box-shadow: none !important;
  }

  .accordion-body {
    padding: 12px 0 22px;

    ul {
      li {
        a {
          font-family: $font-secondary;
          color: $color-black;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .accordion-header {
      &.no-childs {
        .accordion-button {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }

    .accordion-body {
      ul {
        li {
          a {
            -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .accordion-header {
      &.no-childs {
        .accordion-button {
          &:hover {
            color: $color-hover-red;
          }
        }
      }
    }

    .accordion-body {
      ul {
        li {
          a {
            &:hover {
              color: $color-hover-red;
            }
          }
        }
      }
    }
  }
}