#product-tab-reviews {
  .reviews-list {
    margin-bottom: 30px;
    font-family: $font-secondary;

    .review-item {
      position: relative;
      
      + .review-item {
        margin-top: 8px;
      }

      .user_info {
        margin-top: 20px;
        display: flex;

        .flag {
          margin-right: 10px;
          img {
            height: 16px;
          }
        }

        .name {
          font-size: 16px;
          display: block;
          font-weight: 600;
          color: #222;
        }
      }

      .answer {
        margin-top: 16px;
        padding: 14px 22px;
        
        background-color: #F9F9FB;

        .answer_content {
          font-size: 12px;
          line-height: 150%;
          letter-spacing: -0.01em;
          color: #80808C;

          p {
            margin: 0;
            margin-bottom: 10px;
          }
          
          .from {
            font-weight: 600;
          }
        }
      }
      
      .answer-link {
        position: absolute;
        right: 0;
        bottom: 5px;

        font-family: $font-secondary;
      }
    }

    .totals-wrp {
      > div.flex {
        display: flex;
        align-items: baseline;
      }
      margin-bottom: 20px;
      
      .average-rating {
        font-size: 48px;
        font-weight: 600;
        margin-right: 15px;
      }
      
      .info {
        margin-left: auto;
        .count {
          color: #716B66;
        }
      }
      
      .rating {
        display: inline-block;
        position: relative;
  
        .stars {
          display:block;
          float:left;
          margin: 0;
          width:168px;
          height:24px;
          position:relative;
          background:url('/images/frontend/stars2.png') no-repeat top left;
          background-size: contain;
        }
        
        .stars-filled-block {
          width: 100%;
          overflow: hidden;
          height: 24px;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          margin-left: 0;
          
          .stars-filled {
            width:168px;
            height:24px;
            opacity: 1;
            text-indent:-9999px;
            display:block;
            background:url('/images/frontend/stars2_filled.png') no-repeat top left;
            background-size: contain;
            margin: 0;
    
            &.stars-1 {width:34px;}
            &.stars-2 {width:75px;}
            &.stars-3 {width:115px;}
            &.stars-4 {width:156px;}
            &.stars-5 {width:197px;}
          }
          
          .star-filled {
            position: absolute;
            overflow: hidden;
            
            > span {
              height: 24px;
              width: 26px;
              background:url('/images/frontend/star2_filled.png') no-repeat top left;
              background-size: contain;
              margin: 0;
            }
            
            &.star-1 {
              left: 0;
            }
            &.star-2 {
              left: 35px;
            }
            &.star-3 {
              left: 70px;
            }
            &.star-4 {
              left: 105px;
            }
            &.star-5 {
              left: 140px;
            }
          }
        }
      }
    }
  }

  .cta-btn {
    min-width: 260px;
  }

  @include media-breakpoint-up(lg) {
    .reviews-list {
      .review-item {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;

        .answer {
          margin-top: 24px;
          padding: 24px 36px;

          .answer_content {
            font-size: 14px;
          }
        }
      }
    }
  }
}