#checkout-footer {
  padding-top: 18px;
  padding-bottom: 18px;
  background: $color-lighter-grey;

  .copyright {
    display: block;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-black;
    text-align: center;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0;
    padding-bottom: 50px;

    .copyright {
      max-width: 912px;
      margin-left: auto;
      margin-right: auto;
      font-size: 12px;
      text-align: left;
    }
  }
  @include media-breakpoint-up(xxxl) {
    .copyright {
      max-width: 1070px;
    }
  }
}