.tns-outer {
  position: relative;
  z-index: 1;

  .tns-controls {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%) translateZ(0);
    pointer-events: none;

    #added2cart & {
      [data-controls="prev"],
      [data-controls="next"] {
        transform: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      padding: 0;
      background: $color-white;
      border: 1px solid #ECECEC;
      border-radius: 100%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      line-height: 0;
      pointer-events: all;
      transition: all 0.3s ease;

      &[data-controls="prev"] {
        transform: translateX(-50%);
      }

      &[data-controls="next"] {
        transform: translateX(50%);
      }

      &[disabled] {
        opacity: 0;
        pointer-events: none;
      }

      &:hover:not([disabled]) {
        background: $color-black;
        border-color: $color-black;

        i {
          color: $color-white;
        }
      }

      i {
        font-size: 24px;
        color: $color-black;
        transition: all 0.3s ease;
      }
    }
  }

  .tns-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 24px;
    padding-right: 24px;
    margin-top: 15px;

    button {
      display: block;
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      padding: 0;
      border: 0;
      border-radius: 100%;
      background: $color-lighter-grey-2;
      transition: all 0.3s ease;
      appearance: none;

      &.tns-nav-active {
        background: $color-black;
      }

      + button {
        margin-left: 12px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    .tns-controls {
      button {
        width: 56px;
        height: 56px;

        i {
          font-size: 24px;
        }
      }
    }
  }
}