#added2cart {
  .header-title {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    i {
      margin-right: 16px;
      font-size: 24px;
      color: $color-green;
    }
  }

  .product-card-small {
    display: block;
    
    > div:not(.product-price-outlet-popup) {
      display: flex;
    }
    
    position: relative;
    z-index: 1;
    align-items: center;
    margin-bottom: 20px;

    .img-wrp {
      margin-right: 12px;

      img {
        width: 74px;
        height: 74px;
      }
    }

    .bundle-img-wrp {
      margin-right: 12px;
      display: flex;
      flex-wrap: wrap;
      width: 100px;
      
      gap: 2px;
      > div {
        flex: calc(50% - 1px);
        height: 46px;
        flex-grow: 0!important;
        img {
          width: 46px;
          height: 46px;
        }
      }

      &.items-count-3, &.items-count-5, &.items-count-7, &.items-count-6, &.items-count-9 {
        width: 142px;

        > div {
          flex: calc(33% - 1px);
        }
      }
    }

    .another_cheaper {
      .bundle-img-wrp {
        width: auto!important;
        > div {
          flex: auto;
  
          &.plus {
            font-size: 34px;
            padding: 0 15px;
            
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }
  
        }

        &.items-count-3,&.items-count-4,&.items-count-5 {
          .plus {
            padding: 0;
          }
        }
      }
    }
    
    .details {
      flex-direction: unset;
      gap: 20px;
      > .price {
        padding-top: 28px; 
      }
    }
    
    .details-inner-left {
      display: flex;
      flex-direction: column;

      .warning-text {
        margin: 10px 0;
        font-size: 14px;
        font-weight: 500;
        color: $color-red;
        line-height: 16px;
        font-family: $font-secondary;

        i {
          float: left;
          margin-right: 5px;

        }

        @include media-breakpoint-down(lg) {
          margin-top: 0;
          font-size: 12px;
        }
      }
    }

    .name {
      margin-top: 8px;
      margin-bottom: 16px;
      color: $color-grey;
      margin-bottom: 3px;
    }
    
    .description {
      font-family: $font-secondary;
      color: $color-grey;
    }
    
    .bundle {
      .price {
        padding-top: 0!important;
        
        .bundle-tag {
          width: 46px;
          height: auto;
          margin-bottom: 10px;
        }
      }
    }
    
    .price {
      font-size: 14px;
      line-height: 1.11;
      white-space: nowrap;
      letter-spacing: -0.28px;
      flex-direction: column;
      align-items: flex-end;
      
      .has-discount {
        //color: $color-red;  
      }
      
      .price-logged {
        font-size: 14px;
      }
      
      .amount {
        display: block;
        text-align: right;
        color: #AFAFAF;
        font-size: 12px;
        font-weight: 500;
        margin-top: 5px;
      }
      
      .price-old {
        text-decoration: line-through;
        color: #AFAFAF;
        font-size: 14px;
        font-weight: 500;
        margin-top: 5px;
      }
    }
    
    @include media-breakpoint-down(lg) {
      .details {
        gap: 12px;
        flex: 1;
      }
      
      .bundle-img-wrp {
        height: 0%;

        width: 66px!important;

        > div {
          flex: calc(50% - 1px)!important;
          flex-grow: 0;
          
          img {
            height: 32px;
            width: 32px;
          }
        }
      }
    }
    
  }

  .action-buttons {
    position: relative;
    z-index: 1;
    margin-top: 45px;

    .cta-btn {
      + .cta-btn {
        margin-top: 12px;
      }
    }
  }

  .collection {
    margin: 40px -24px -48px;
    padding: 28px 24px 48px;
    box-shadow: 0px 0px 89px rgba(0, 0, 0, 0.1);

    .collection-header {
      margin-bottom: 20px;

      h3 {
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 1.25;
        font-weight: 600;
        font-style: normal;
      }
    }
  }
  
  .product-price-outlet-popup {
    .price-box {
      font-family: $font-secondary;
      &.outlet {
        //display: flex;
        //justify-content: right;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        line-height: 16px;

        .price-logged {
          display: block;
          color: $color-red;
          font-size: 14px;
          margin-top: 10px;
        }

        .outlet-icon {
          width: 16px;
          margin-top: -4px;
        }

        .price-desc {
          display: block;
          margin-top: 5px;
          font-size: 12px;
          font-weight: 500;
          color: #716B66;
        }

        a {
          font-size: 12px;
          font-weight: 500;
          color: #1E1E1E;
          text-decoration: underline;
        }
      }
    }
  }

  .till_free_delivery {
    position: relative;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #EBEBEB;
    margin-bottom: 10px;
    padding: 10px 0;

    .filled {
      position: absolute;
      bottom: -2px;
      height: 2px;
      display: block;
      width: 50%;
      background: #1E1E1E;
    }
  }

  .till_free_delivery {
    position: relative;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    border-bottom: 2px solid #EBEBEB;
    margin-bottom: 10px;
    padding: 10px 0;

    .filled {
      position: absolute;
      bottom: -2px;
      height: 2px;
      display: block;
      width: 50%;
      background: #1E1E1E;
    }
  }

  @include media-breakpoint-up(md) {
    .header-title {
      font-size: 32px;

      i {
        order: 2;
        margin-left: 16px;
        margin-right: 0;
      }
    }

    .product-card-small {
      flex-wrap: wrap;
      
      .details {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        > .price {
          padding-top: 28px;
        }
      }

      .img-wrp {
        img {
          width: 107px;
          height: 107px;
        }
      }
      
      .name {
        margin-bottom: 0;
        margin-right: 30px;
        font-size: 16px;
        line-height: 1.375;
      }

      .price {
        font-size: 18px;
        white-space: nowrap;
        letter-spacing: normal;

        .price-logged {
          font-size: 18px!important;
        }
        
        .price-old {
          margin-left: 0;
          margin-top: 5px;
        }
      }
    }

    .action-buttons {
      display: flex;
      align-items: center;
      margin-top: 38px;

      .cta-btn {
        + .cta-btn {
          margin-top: 0;
          margin-left: 16px;
        }
      }
    }

    .collection {
      .collection-header {
        h3 {
          font-size: 16px;
        }
      }
    }

    .product-price-outlet-popup {
      .price-box {
        &.outlet {
          position: relative;
          text-align: right;
          display: flex;
          justify-content: right;

          .price-logged {
            line-height: 33px;
            font-size: 16px;
            margin-top: 0;
          }
          
          .price-desc {
            margin-top: 0;
          }

          .outlet-txt-box {
            margin-right: 18px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .product-card-small {
      margin-bottom: 8px;
    }
  }
  @include media-breakpoint-up(xl) {
    .collection {
      margin: 40px -48px -40px;
      padding: 26px 48px 40px;
      overflow: hidden;

      &.last-slide {
        .tns-ovh {
          &:after {
            opacity: 0;
          }
        }
      }

      .tns-controls {
        z-index: 2;

        button {
          background: none;
          border: 0;
          box-shadow: none;

          &[data-controls="prev"] {
            transform: translateX(-45px);
          }

          &[data-controls="next"] {
            transform: translateX(45px);
          }

          i {
            font-size: 24px;
            color: $color-black;
          }
        }
      }

      .tns-ovh {
        position: relative;
        overflow: visible;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: -48px;
          z-index: 1;
          height: 100%;
          width: 48px;
          background: $color-white;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          z-index: 1;
          right: -48px;
          width: 158px;
          height: 100%;
          background: linear-gradient(270deg, #FFFFFF 16.98%, rgba(255, 255, 255, 0) 100%);
          pointer-events: none;
          transition: opacity 0.3s ease-in-out;
        }
      }

      .collection-items {
        display: block;
        overflow: visible;

        .product-card-compact {
          .card-inner {
            height: 306px;
          }

          .product-card-img {
            img {
              width: 106px;
              height: 112px;
            }
          }
        }
      }
    }
  }
}