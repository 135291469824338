.amount-actions {
  display: flex;
  align-items: center;

  input {
    width: 77px;
    height: 48px;
    margin-left: 1px;
    margin-right: 1px;
    padding: 0;
    border: 0;
    background: $color-lighter-grey;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      margin: 0;
      -webkit-appearance: none;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 48px;
    padding: 0;
    border: 0;
    background: $color-lighter-grey;

    i {
      font-size: 16px;
      color: $color-grey;
      transition: color 0.3s ease;
    }
  }

  @include media-breakpoint-up(lg) {
    input {
      width: 44px;
      height: 44px;
    }

    button {
      width: 44px;
      height: 44px;

      &:hover {
        i {
          color: $color-white;
        }
      }
    }
  }
}