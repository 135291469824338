.category-header {
  .page-breadcrumb {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .container {
    .inner {
      position: relative;
      z-index: 2;
    }

    &.desktop-banner, &.mobile-banner {
      position: relative;
      
      .inner {
        color: var(--text-color);
        
        .title h1 {
          color: var(--text-color);
        }

        .page-breadcrumb ol li a {
          color: var(--text-color);
        }
      }
    }
    
    .category-img {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: auto 232px;
    }
    
    &.desktop-banner {
      @include media-breakpoint-up(lg) {
        min-height: 352px;
        padding-bottom: 0;

        .inner {
          width: 520px;
          padding-bottom: 30px;
        }

        .category-img {
          background-position: right 24px center;
          background-size: auto 100%;
        }
      }
      @include media-breakpoint-up(xxl) {
          .inner {
            width: 630px;
          }
      }
      @include media-breakpoint-down(lg) {
        &.mobile-banner {
          padding-bottom: 260px;
        }
      }
    }
    
  }
  
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin-bottom: 0;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.5;
    }

    .total-products {
      margin-left: 10px;
      font-family: $font-secondary;
      color: $color-grey;
      white-space: nowrap;
    }
  }

  .description {
    p {
      margin-bottom: 15px;
      font-style: italic;
      letter-spacing: 0.02em;

      &:first-child {
        margin-top: 12px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .title {
      h1 {
        font-size: 24px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: 48px;
        line-height: 1;
      }
    }

    .description {
      p {
        font-size: 18px;
        line-height: 1.33;

        &:first-child {
          margin-top: 12px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .desktop-banner {
      .description {
        max-width: 100%;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .desktop-banner {
      .description {
        max-width: 100%;
      }
    }
  }
  
  @include media-breakpoint-up(lg) {
    
    .description {
      max-width: 55%;
    }
  }
  @include media-breakpoint-up(xl) {
    background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);

    .page-breadcrumb {
      padding-top: 25px;
      padding-bottom: 30px;
    }

    .description {
      max-width: 50%;

      p {
        margin-bottom: 20px;
        letter-spacing: 0;

        &:first-child {
          margin-top: 14px;
        }
      }
    }
  }
}