.wishlistInfo {
  i {
    position: relative;
  }

  .wish-total {
    position: absolute;
    top: -6px;
    right: -8px;
    display: block;
    min-width: 18px;
    padding: 1px 3px 1px 1px;
    background: $color-red;
    border-radius: 50px;
    border: 1px solid $color-white;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 10px;
    line-height: 1;
    letter-spacing: -0.14em;
    text-align: center;
    text-transform: uppercase;
    color: $color-white;
  }
}