.template-contacts {
  display: grid;
  row-gap: 40px;

  .c-item {
    h2 {
      margin-bottom: 20px;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.11;
      color: $color-black;
    }

    p {
      margin-bottom: 10px;
    }

    .contact-info {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      p {
        position: relative;
        padding-left: 26px;
        margin-bottom: 5px;

        a {
          display: block;

          i {
            position: absolute;
            top: 1px;
            left: 0;
            font-size: 16px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;

    .c-item {
      h2 {
        margin-bottom: 28px;
        font-size: 24px;
      }
    }
  }
}