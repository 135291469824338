#product-tab-delivery-return {
  .benefits-list {
    margin-top: 0;
    margin-bottom: 0;

    .item {
      + .item {
        margin-top: 30px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .benefits-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px 30px;

      .item {
        width: 100%;

        + .item {
          margin-top: 0;
        }
      }
    }
  }
}