.cart-info {
  display: inline-flex;
  align-items: center;
  line-height: 0;

  &:hover {
    .txt {
      color: $color-hover-red;
    }
  }

  .icon {
    position: relative;
    display: inline-block;

    .cart-total {
      position: absolute;
      top: -6px;
      right: -8px;
      display: block;
      min-width: 18px;
      padding: 1px 3px 1px 1px;
      background: $color-red;
      border-radius: 50px;
      border: 1px solid $color-white;
      font-family: $font-secondary;
      font-weight: 700;
      font-size: 10px;
      line-height: 1;
      letter-spacing: -0.14em;
      text-align: center;
      text-transform: uppercase;
      color: $color-white;
    }
  }

  .txt {
    margin-left: 16px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-black;
    transition: color 0.3s ease;
  }
}