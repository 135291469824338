.subcategories-list {
  display: flex;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-right: 27px;
  margin-right: -24px;
  overflow: auto;

  &.source-collection {
    padding-top: 0;
  }

  a {
    display: inline-flex;
    align-items: center;
    flex-shrink: 0;
    padding: 4px 16px 4px 4px;
    background: $color-white;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
    font-style: italic;
    letter-spacing: 0.02em;
    color: $color-grey;
    transition: all 0.3s ease;

    + a {
      margin-left: 8px;
    }

    span {
      display: inline-block;
      max-width: 136px;
    }

    img {
      width: 34px;
      height: 32px;
      margin-right: 12px;
      object-fit: contain;
    }
  }

  @include media-breakpoint-down(lg) {
    a {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(md) {
    margin-right: 0;

    a {
      padding: 4px 16px 4px 4px;
      font-size: 16px;
      line-height: 1.375;
      letter-spacing: 0;

      + a {
        margin-left: 17px;
      }

      img {
        width: 60px;
        height: 54px;
        margin-right: 22px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    a {
      &:hover {
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.22);
      }
    }
  }
  @include media-breakpoint-up(xl) {
    display: grid;
    gap: 18px;
    grid-template-columns: repeat(5, 1fr);
    padding-right: 0;
    padding-bottom: 5px;
    overflow: visible;

    a {
      padding: 6px 16px 6px 6px;

      + a {
        margin-left: 0;
      }

      img {
        margin-right: 24px;
      }

      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}