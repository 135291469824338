.btn-close {
  width: auto;
  height: auto;
  padding: 10px !important;
  background: none;
  border-radius: 0;
  opacity: 1;
  line-height: 0;

  &.opacity {
    opacity: 0.75;

    &:hover {
      opacity: 1;

      i {
        color: $color-black;
      }
    }
  }

  i {
    font-size: 18px;
    color: $color-black;
    transition: all 0.3s ease;
  }

  @include media-breakpoint-up(xl) {
    &:hover {
      opacity: 1;

      i {
        color: $color-red;
      }
    }
  }
}