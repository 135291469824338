.upload-photos-wrp {

  .upload-photo-text-1 {
    font-family: $font-secondary;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .upload-photo-text-2 {
    font-family: $font-secondary;
    font-size: 14px;
    line-height: 20px;
  }

  .upload-photo-text-3 {
    font-family: $font-secondary;
    font-size: 12px;
    line-height: 20px;
    color: #716B66;
  }

  .plupload-container {
    display: inline-flex;
    border: 1px dashed #AFAFAF;
    padding: 5px;
    max-width: 346px;
    margin-top: 15px;

    @media (max-width: 436px) {
      max-width: 232px;
    }

    .filelist {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      > li {
        flex: 0 0 107px;
        position: relative;

        &:nth-child(1) {
          order: 6;
        }

        .remove {
          position: absolute;
          top: 5px;
          right: 5px;
          background: #fff;
          display: block;
          height: 25px;
          width: 25px;
          line-height: 25px;
          text-align: center;
          vertical-align: middle;
          color: #000;

          > span {
            transform: rotate(45deg);
            font-size: 31px;
            display: block;
          }
        }

        .uploaded-photo {
          max-width: 107px;
          width: 100%;
        }
      }
    }

    div[id^="drop_photos_"] {
      .button-block {
        display: inline-block;
        position: relative;

        .plus {
          position: absolute;
          top: 17px;
          width: 100%;
          text-align: center;

          &:before {
            content: "+";
            font-size: 32px;
          }
        }

        input[type="button"] {
          height: 107px;
          width: 107px;
          border: 1px solid #AFAFAF;
          text-align: center;
          vertical-align: middle;
          font-family: $font-secondary;
          padding: 25px 0 10px 0;
        }
      }
    }

    .moxie-shim-html5 {
      display: none;
    }
  }
} 