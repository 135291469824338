#products-detailed {
  .bundles-block {
    margin-top: 24px;
    
    .bundle-block {
      margin-left: 97px;
      margin-bottom: 10px;

      .discount {
        font-family: $font-secondary;
        padding: 3px 10px;
        border: 1px solid #E73132;
        background: #fff;
        color: #E73132;
        font-size: 10px;
        font-weight: 600;
        letter-spacing: 0.08em;
      }
      
      .bundle-wrp {
        padding: 14px;
        border: 1px solid #E8E8E8;
        background: #FAFAFA;
        min-height: 120px;
        position: relative;
        
        display: flex;
        gap: 18px;
        
        .info {
          font-family: $font-secondary;
          display: flex;
          flex-direction: column;
          flex: 1;
          
          .name {
            font-size: 16px;
            font-weight: 600;
            line-height: 30px;
            margin-bottom: 3px;
          }
          
          .description {
            line-height: 20px;
          }
          
          .more {
            &.has-desc {
              margin-top: auto;
            }
            
            a {
              font-family: $font-primary;
              font-style: italic;
              color: #716B66;
              text-decoration: underline;
              letter-spacing: 1px;
            }
          }
        }

        .bundle-tag {
          width: 56px;
          height: auto;
        }
        
        .photos {
          position: relative;
          width: 122px;
          height: 0%;
          display: flex;
          flex-wrap: wrap;
          gap: 2px;
          
          div {
            flex: calc(50% - 1px);
            flex-grow: 0!important;
          }

          &.items-count-3, &.items-count-5, &.items-count-7, &.items-count-6, &.items-count-9 {
            width: 185px;

            > div {
              flex: calc(33% - 1px);
            }
          }
          
          &.another-cheaper {
            width: auto!important;
            > div {
              flex: auto!important;
              position: relative;
              
              &.plus {
                font-size: 34px;
                padding: 0 15px;
                z-index: 1;
              }

              .discount {
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 0 6px;
              }
              
            }

            &.items-count-3,&.items-count-4,&.items-count-5 {
              .plus {
                padding: 0;
                margin: 0 -10px;
              }
            }
          }
        }
        
        .add2cart-wrp {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          
          @include media-breakpoint-up(lg) {
            width: 251px;
          }
          
          .prices {
            display: flex;
            gap: 5px;
            font-family: $font-secondary;
            line-height: 20px;
            align-items: center;
            
            .price {
              font-size: 18px;
              font-weight: 600;
              color: $color-green;
              margin-left: 3px;
            }
            
            .old-price {
              font-size: 12px;
              margin-right: 5px;
              font-weight: 500;
              text-decoration: line-through;
              color: #AFAFAF;
            }
          }
          
          .buttons {
            margin-top: auto;
            display: flex;
            
            .add-to-cart {
              flex: 1;
            }
          }
        }
      }
      
      .bundle-items {
        display: none;
        
        border-left: 1px solid #EBEBEB;
        border-right: 1px solid #EBEBEB;
        border-bottom: 1px solid #EBEBEB;
        padding: 30px 30px 30px 150px;
        
        .item {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          
          .photo {
            position: relative;
            margin-right: 25px;
            
            .discount {
              position: absolute;
              left: 0;
              bottom: 0;
              padding: 0 6px;
            }
          }
          
          .info {
            @include media-breakpoint-up(lg) {
              max-width: 330px;
            }

            .name a {
              font-size: 14px;
              font-style: italic;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.02em;
              text-align: left;
              color: $color-black;

              &:hover {
                text-decoration: underline;
              }
            }

            .prices {
              display: flex;
              font-family: $font-secondary;
              margin-top: 5px;
              
              .price {
                margin-right: 10px;

                font-size: 12px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: -0.02em;
                text-align: left;
              }

              .old-price {
                font-size: 12px;
                font-weight: 500;
                line-height: 20px;
                text-decoration: line-through;
                color: #AFAFAF;
              }
            }
          }
        }
      }
      
      &.expanded {
        .bundle-wrp {
          &:before {
            bottom: -22px;
            content: " ";
            display: block;
            height: 20px;
            left: 161px;
            position: absolute;
            border-color: #E8E8E8 transparent transparent transparent;
            border-style: solid;
            border-width: 11px;
          }
  
          &:after {
            border-left: solid transparent 12px;
            border-right: solid transparent 12px;
            border-top: solid #FAFAFA 12px;
            bottom: -10px;
            content: " ";
            height: 0;
            left: 160px;
            position: absolute;
            width: 0;
          }
        }
        
        .bundle-items {
          display: block;
        }
      }

      @include media-breakpoint-down(lg) {
        margin-left: 0;
        
        .bundle-wrp {
          flex-direction: column;
          
          .photos {
            &.items-count-3, &.items-count-5, &.items-count-7, &.items-count-6, &.items-count-9 {
              width: 185px;
              
              > div {
                flex: calc(33% - 1px);
              }
            }

            > div {
              flex-grow: 0!important;
            }
            
            position: static;
          }
          
          .add2cart-wrp {
            margin-left: unset;
            
            .buttons {
              display: flex;
              gap: 5px;
              
              .more {
                display: flex;
                align-items: center;
                border: 1px solid $color-black;
                vertical-align: middle;
                
                a {
                  font-size: 12px;
                  font-weight: 600;
                  color: $color-black;
                  font-family: $font-secondary;
                  text-transform: uppercase;
                  letter-spacing: 2px;
                }
              }
            }
          }
          
          .prices {
            margin-bottom: 15px;
          }
        }

        .bundle-items {
          padding: 30px;
        }
      }
    }
  }
}