.review-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  //box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid #EBEBEB;
  
  p {
    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .date {
    position: absolute;
    top: 27px;
    right: 24px;
    font-family: $font-secondary;
    line-height: 1;
    color: $color-grey;
  }

  h4 {
    margin-bottom: 0;
    padding-right: 80px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.1;
    color: $color-black;
  }

  .rating {
    i {
      font-size: 16px;
    }
  }

  .desc {
    margin-top: 20px;
    margin-bottom: 15px;
    font-family: $font-secondary;
  }

  .item_name {
    font-family: $font-secondary;
    margin-top: 10px;
    margin-bottom: 0;
    color: $color-light-grey;
  }
  
  .reviewed-product {
    display: inline-block;
    margin-top: auto;
    margin-bottom: 0;
    font-style: italic;
    color: $color-light-grey;
  }

  @include media-breakpoint-up(lg) {
    min-height: 262px;

    &.product-inner-review {
      min-height: unset;
    }

    .date {
      top: 32px;
      right: 0;
    }

    .desc {
      margin-top: 22px;
      margin-bottom: 22px;
      font-size: 16px;
      line-height: 1.5;
    }

    .reviewed-product {
      font-size: 16px;
      line-height: 1.375;

      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}