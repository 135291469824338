.template-2cols-img-left{
  display: grid;
  row-gap: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-top: 1px solid $color-lighter-grey-2;
  border-bottom: 1px solid $color-lighter-grey-2;

  ~ .template-2cols-img-left{
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }

  .cil-content-img{
    img{
      max-width: 140px;
      max-height: 140px;
    }
  }

  .cil-content{
    >*:last-child{
      margin-bottom: 0;
    }

    ul{
      li{
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: 1fr 2fr;
    column-gap: 40px;
  }
}