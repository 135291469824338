.checkbox-group {
  margin-top: 16px;

  &.rules {
    margin-top: 0;
    margin-bottom: 28px;
  }

  .check-options {
    padding-top: 16px;
  }

  .checkbox {
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
    }
  }
}