#departments-listing {
  h2 {
    margin-top: 40px;
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.11;
    color: $color-natural-black;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .departments-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
  }

  .dep-item {
    display: flex;
    flex-direction: column;
    
   > div {
    height: 100%;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);
  
    .dep-img {
      display: block;
      overflow: hidden;
  
      img {
        transform-origin: center center;
        will-change: transform;
        transition: all 0.3s ease;
      }
    }
  
    img {
      width: 100%;
      height: auto;
    }
  
    .dep-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 40px;
      font-family: $font-secondary;
      color: $color-black;
  
      h3 {
        margin-bottom: 12px;
        font-family: $font-primary;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.11;
        color: $color-natural-black;
      }
  
      .address {
        margin-bottom: 20px;
      }
  
      .working-hours {
        margin-top: 26px;
      }
  
      a {
        margin-bottom: 6px;
        color: $color-black;
  
        i {
          margin-right: 12px;
          font-size: 16px;
        }
      }
    }
     
   }
  }

  @include media-breakpoint-up(md) {
    h2 {
      margin-top: 54px;
      margin-bottom: 32px;
      font-size: 24px;
    }

    .departments-list {
      gap: 30px;
      //grid-template-columns: repeat(2, 1fr);
    }

    .dep-item {
      flex: 0 0 47%;
      
      .dep-content {
        h3 {
          font-size: 24px;
        }

        .address {
          margin-bottom: 24px;
        }

        .working-hours {
          margin-top: 28px;
        }
      }
    }
  }
  
  @include media-breakpoint-up(lg) {
    .dep-item {
      .dep-img {
        &:hover {
          img {
            transform: scale(1.05) translateZ(0);
          }
        }
      }

      .dep-content {
        padding: 32px;

        a {
          transition: color 0.3s ease;

          &:hover {
            color: $color-red;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .departments-list {
      .dep-item {
        flex: 0 0 30.9%;
      }
      //grid-template-columns: repeat(3, 1fr);
    }
  }
}