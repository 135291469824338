.container-content {
  .back-wrp {
    a {
      display: inline-flex;
      align-items: center;
      font-family: $font-secondary;
      color: $color-black;
      transition: color 0.3s ease;

      i {
        margin-right: 12px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    max-width: 912px;
    padding-top: 24px;
    padding-bottom: 64px;
    margin-left: auto;
    margin-right: auto;

    .back-wrp {
      grid-area: back-arrow;
    }
  }
  @include media-breakpoint-up(xl) {
    max-width: 1070px;

    .back-wrp {
      a {
        &:hover {
          color: $color-hover-red;
        }
      }
    }
  }
}