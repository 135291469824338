.side-nav {
  .dropdown {
    button {
      position: relative;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 63px;
      padding: 0 35px 0 20px;
      border: 0;
      border-left: 4px solid $color-red;
      background: $color-white;
      box-shadow: 0px 0px 27px -4px rgba(0, 0, 0, 0.1);
      text-align: left;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.33;
      color: $color-black;

      &.show {
        .fa-minus {
          display: inline-block;
        }

        .fa-plus {
          display: none;
        }
      }

      .fa-minus {
        display: none;
      }

      i:not(.dropdown-icon) {
        width: 24px;
        margin-right: 16px;
        font-size: 18px;
        text-align: center;
      }

      .dropdown-icon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 16px;
      }
    }

    .dropdown-menu {
      width: 100%;
      padding: 0;
      border: 0;
      background: $color-white;
      box-shadow: 0px 0px 27px -4px rgba(0, 0, 0, 0.1);

      li {
        border-bottom: 1px solid $color-lighter-grey-2;

        &:last-child {
          border-bottom: 0;
        }

        a {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-height: 52px;
          padding-left: 24px;
          padding-right: 24px;
          font-style: italic;
          letter-spacing: 0.02em;
          color: $color-black;

          &.active {
            background: $color-lighter-grey;
            font-weight: 700;
          }

          i {
            width: 20px;
            margin-top: -1px;
            margin-right: 10px;
            text-align: center;
            font-size: 12px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    width: 264px;
    margin-right: 50px;
    background: $color-white;

    .dropdown {
      .dropdown-menu {
        position: static;
        display: block;

        li {
          a {
            min-height: 64px;
            padding-left: 20px;
            border-left: 4px solid transparent;
            font-size: 18px;
            letter-spacing: 0;
            transition: all 0.3s ease;

            &.active,
            &:hover {
              background: none;
              border-left: 4px solid $color-red;
            }

            i {
              width: 24px;
              margin-right: 24px;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-right: 66px;
  }
}