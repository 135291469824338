.alert {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 0;
  padding: 9px 40px 9px 20px;
  border-color: $color-red;
  background-color: rgba($color-red, 0.1);
  font-family: $font-secondary;
  color: $color-red;

  .btn-close {
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}

.alert-success {
  color: #3bc188;
  background-color: #ecfbf5;
  border-color: #bdf1db;
}