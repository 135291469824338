.template-notice {
  position: relative;
  padding: 60px 32px 50px;
  margin-bottom: 45px;
  background: rgba($color-light-yellow, 0.6);
  font-family: $font-primary;
  font-style: italic;
  line-height: 1.428;
  letter-spacing: 0.02em;
  color: $color-black;

  *:last-child {
    margin-bottom: 0;
  }

  i {
    position: absolute;
    top: 26px;
    left: 32px;
    font-size: 16px;
    color: $color-black;
  }

  ul {
    margin-bottom: 15px;

    li {
      position: relative;
      margin-bottom: 15px;
      padding-left: 12px;

      &:before {
        position: absolute;
        top: 5px;
        left: 0;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: $color-black;
        content: '';
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 28px 38px 32px 70px;

    i {
      top: 29px;
    }

    ul {
      li {
        &:before {
          top: 6px;
        }
      }
    }
  }
}