.collection {
  .collection-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    h3 {
      margin: 0;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 1.1;
      color: $color-black;
    }

    .link {
      margin-left: 20px;
      white-space: nowrap;
    }
  }

  .collection-items {
    display: flex;
    width: calc(100% + 24px);
    padding-right: 24px;
    overflow-x: auto;
    overflow-y: hidden;

    .product-card-wrp {
      + .product-card-wrp {
        margin-left: 12px;
      }

      .product-card {
        width: 155px;
        padding-left: 0;
        padding-right: 0;

        .card-inner {
          padding-left: 0;
          padding-right: 20px;
        }
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .collection-header {
      h3 {
        font-size: 24px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .collection-header {
      justify-content: flex-start;
      margin-bottom: 35px;

      h3 {
        font-size: 32px;
      }

      .link {
        margin-left: 22px;
      }
    }

    .collection-items {
      width: 100%;
      padding-right: 0;

      .product-card-wrp {
        + .product-card-wrp {
          margin-left: 20px;
        }

        .product-card {
          width: 200px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .tns-ovh {
      overflow: visible;
    }

    .collection-items {
      display: block;
      overflow: visible;

      .product-card-wrp {
        + .product-card-wrp {
          margin-left: 0;
        }

        .product-card {
          width: 250px;

          .card-inner {
            padding-left: 16px;
            padding-right: 16px;
          }
        }
      }
    }

    .tns-outer {
      .tns-controls {
        top: 35%;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    &:hover {
      .tns-controls {
        opacity: 1;
      }
    }

    .collection-items {
      .product-card-wrp {
        .product-card {
          &:hover {
            .card-inner {
              padding-left: 20px;
              padding-right: 20px;
            }
          }

          .card-inner {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    .tns-controls {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }
  @include media-breakpoint-up(xxl) {
    .collection-items {
      .product-card-wrp {
        .product-card {
          width: 300px;
        }
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    .collection-items {
      .product-card-wrp {
        .product-card {
          width: 396px;
        }
      }
    }

    .tns-outer {
      .tns-controls {
        top: 40%;
      }
    }
  }
}