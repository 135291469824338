.modal-auth {
  #customer-authorize {
    padding: 0;

    .loyalty-club-auth-info {
      margin: 48px -24px -48px;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 952px;

    .modal-body {
      padding: 0;
    }

    #customer-authorize {
      .loyalty-club-auth-info {
        margin: 0;
      }
    }
  }
}