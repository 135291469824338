.customer-page {
  .side-nav {
    margin-bottom: 32px;
  }

  h1 {
    margin-bottom: 25px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: $color-black;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: 32px;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    .side-nav {
      margin-bottom: 0;
    }

    .customer-nav-content {
      width: 46%;
      max-width: 630px;
    }
  }
}