.modifications-colors-wrp {
  .mod-colors-show-less {
    display: inline-block;
    margin-top: 12px;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-decoration-line: underline;
    color: $color-red;
  }

  .modifications-colors {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;

    &.show-less {
      .show-more-colors {
        display: block;

          width: 57px;
          height: 57px;

          @include media-breakpoint-up(xl) {
            width: 64px;
            height: 64px;
          }
      }

      .mod-color-item {
        &:nth-child(n+15) {
          display: none;
        }
      }
    }

    .show-more-colors {
      display: none;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: 0;
      appearance: none;
      background: $color-lighter-grey;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1;
      color: $color-black;
    }

    .mod-color-item {
      display: block;
      cursor: pointer;

      input {
        &:checked {
          ~ .mod-color {
            &:before,
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .mod-color {
        position: relative;
        display: block;
        width: 100%;
        height: 40px;
        border: 1px solid $color-lighter-grey-7;

        &.default {
          background: $color-lighter-grey;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          border: 4px solid $color-white;
          content: '';
          opacity: 0;
        }

        &:after {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          border: 1px solid $color-natural-black;
          content: '';
          opacity: 0;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        
        &.photo {
          width: 57px;
          height: 57px;
          
          @include media-breakpoint-up(xl) {
            width: 64px;
            height: 64px;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .modifications-colors {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  @include media-breakpoint-up(lg) {
    .modifications-colors {
      grid-template-columns: repeat(5, 1fr);
    }

    .mod-colors-show-less {
      &:hover {
        text-decoration-line: none;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .modifications-colors {
      //grid-template-columns: repeat(6, 1fr);
      grid-template-columns: repeat(5, 1fr);
    }
  }
}