#pages-help {
  .page-breadcrumb {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .page-title {
    margin-bottom: 12px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: $color-black;
  }

  .help-menu-list {
    display: grid;
    gap: 8px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: -40px;

    &.no-content {
      margin-top: 40px;
    }

    a {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 24px;
      padding-left: 16px;
      padding-right: 16px;
      aspect-ratio: 1 / 1;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
      font-style: italic;
      text-align: center;
      color: $color-grey;

      i {
        margin-bottom: 28px;
        font-size: 24px;
        color: $color-black;
      }
    }
  }

  @include media-breakpoint-up(md) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 210px;
      background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
    }

    .page-breadcrumb {
      padding-top: 34px;
      padding-bottom: 20px;
    }

    .page-title {
      margin-bottom: 0;
      font-size: 48px;
    }

    .help-menu-list {
      gap: 10px 16px;
      grid-template-columns: repeat(3, 1fr);

      a {
        aspect-ratio: 4 / 3;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        font-size: 18px;

        i {
          font-size: 38px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .container {
      max-width: 800px;
    }

    .help-menu-list {
      a {
        transition: all 0.3s ease;

        &:hover {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
        }

        i {
          margin-bottom: 32px;
          font-size: 48px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .container {
      max-width: 1118px;

      .help-menu-list {
        a {
          aspect-ratio: 1.76/1;
        }
      }
    }
  }
}