.header-filter-mobile {
  position: relative;
  
  .header-filter {
    position: relative;
    gap: 1px;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 15px;
    background: $color-white;

    li:nth-child(1) {
      position: relative;
      &:after {
        display: block;
        content: '';
        height: 26px;
        width: 1px;
        background-color: #F3F3F3;
        position: absolute;
        right: -2px;
        top: 2px;
      }
    }
    
    .filters_count {
      color: $color-red;
      padding-left: 3px;
    }
    
    .filter-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 10px;
      padding-bottom: 15px;
      border: 0;
      background: none;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-black;

      i {
        font-size: 12px;
        margin-right: 8px;
      }
    }

    @include media-breakpoint-down(lg) {
      .filter-btn {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }
  }
  
  .summary_block {
    border-top: 1px solid #F6F6F6;
    padding: 10px 0;
    
    .filter_summary {
      overflow: auto;
      padding: 0 20px;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1;
    width: 100%;
    height: 16px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }

  
}