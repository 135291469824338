#products-add2cart {
  .product-top-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .top-info-group {
      display: flex;
      align-items: center;
    }

    .product-brands {
      display: flex;
      align-items: center;
      margin-right: auto;

      .brand-item {
        + .brand-item {
          margin-left: 16px;
        }
      }

      img {
        max-width: 96px;
        max-height: 40px;
      }
    }
    
    .product-country {
      display: inline-flex;
      align-items: center;
      margin-right: 24px;
      font-style: italic;
      line-height: 1;
      color: $color-light-grey;

      img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .btn-wishlistInfo {
      display: inline-block;
      line-height: 0;

      &.active {
        i {
          font-weight: 900;
        }
      }

      i {
        font-size: 18px;
        color: $color-black;
        transition: color 0.3s ease;
      }
    }
  }

  .buttons-select.show-less {
    display: none;
    margin-top: 10px;
    
    a {
      font-family: $font-secondary;
      font-size: 14px;
      text-decoration: underline;
    }
  }
  
  .buttons-select-item, .buttons-select-modification {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 5px;
    > div {
      &.expandable {
        display: none;
      }
      &.show-more {
        a {
          font-size: 16px;
          font-weight: 600;
        }
      }
      > div {
        display: block;
        border: 1px solid #fff;

        a {
          display: block;
          line-height: 38px;
          vertical-align: middle;
          height: 40px;
          padding: 0;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: 0.5px;
          border: 2px solid $color-lighter-grey;
          text-transform: unset;
        }

        &.selected {
          border-color: #000;

          a {
            border: 2px solid #fff;
          }
        }
      }
    }
  }
  
  .buttons-select-modification {
    @include media-breakpoint-between(lg, xxl) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  
  .stock_notification_container {
    margin-top: 20px;

    #stock_form {
      padding: 20px;
      border: 1px solid #000;

      .title {
        i {
          margin-right: 10px;
        }
        font-size: 18px;
        font-style: italic;
        margin-bottom: 15px;
      }
    }
    
    .success-block {
      border: 1px solid #000;
      padding: 40px 80px 60px 80px;

      @include media-breakpoint-down(lg) {
        padding: 30px 30px 50px 30px;
      }
      
      text-align: center;
      
      &:not(.exists) {
        display: none;
      }
      h2 {
        font-style: italic;
      }
      .icon-block {
        text-align: center;
        i {
          font-size: 48px;
          color: #219653;
        }
        margin-bottom: 5px;
      }
      
      .text {
        font-family: $font-secondary;
      }
    }
  }

  .form-item:not(.stock-form-item) {
    .form-label {
      margin-bottom: 4px;
      font-size: 16px;
      line-height: 1.875;
      letter-spacing: 0;
      text-transform: none;
      color: $color-black;
    }
  }

  .message-btn {
    position: relative;
    width: 100%;
    margin-bottom: 16px;
    padding: 0 35px;

    i {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      font-size: 18px;
    }
  }

  h1 {
    margin-bottom: 15px;
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    color: $color-black;
  }

  .product-bottom-info {
    display: flex;
    align-content: center;
    margin-bottom: 40px;

    .rating {
      display: inline-block;
      position: relative;

      .stars {
        display:block;
        float:left;
        margin: 0;
        width:80px;
        height:12px;
        position:relative;
        background:url('/images/frontend/stars2.png') no-repeat top left;
        background-size: contain;
      }

      .stars-filled-block {
        width: 100%;
        overflow: hidden;
        height: 12px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 0;

        .stars-filled {
          width:80px;
          height:12px;
          opacity: 1;
          text-indent:-9999px;
          display:block;
          background:url('/images/frontend/stars2_filled.png') no-repeat top left;
          background-size: contain;
          margin: 0;
        }
      }

      a {
        display: inline-block;
        margin-left: 8px;
        color: $color-grey;
        text-decoration: underline;
        transition: color 0.3s ease;
      }
    }

    .btn-to-desc {
      font-style: italic;
      line-height: 1;
      text-decoration-line: underline;
      color: $color-grey;
      transition: color 0.3s ease;
    }
  }

  .eprel-info {
    margin-bottom: -30px;
  }

  .lowest_30d_price_msg {
    font-family: $font-secondary;
    margin-top: 10px;
    font-size: 10px;
    font-weight: 400;
    color: #716B66;
  }

  .product-price-outlet {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;

    .price-box {
      font-family: $font-secondary;
      text-align: right;

      &.outlet {
        text-align: left;
        color: $color-red;
      }

      .for-members {
        font-size: 12px;
        font-weight: 500;
        .become-member {
          color: $color-red;
          text-decoration: underline;
        }
      }

      .price {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 1;

        .outlet-icon {
          width: 18px;
          height: auto;
          margin-top: -2px;
          margin-right: 6px;
        }
      }

      .price-desc {
        display: block;
        font-weight: 500;
        font-size: 11px;
        line-height: 1.2;
      }
    }
  }

  .product-price {
    display: flex;
    align-items: center;
    margin-top: 40px;
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;

    .price-old {
      display: block;
      margin-left: 20px;
      font-size: 14px;
      font-weight: 600;

      .price {
        text-decoration-line: line-through;
        color: $color-light-grey;
      }

      &.valid-till {
        line-height: 15px;

        .price {
          font-size: 16px !important;
          font-weight: 600;
          text-decoration-line: line-through;
          color: $color-grey;
        }

        .discount-valid-till {
          color: $color-light-grey;
          font-weight: 500;
          font-size: 14px;
        }

        @include media-breakpoint-down(lg) {
          .price {
            font-size: 14px !important;
          }
          .discount-valid-till {
            font-size: 12px;
          }
        }
      }
    }

    .out-of-stock {
      display: block;
      font-size: 18px;
      font-weight: 400;
      color: $color-light-grey;
    }
  }

  .product-notice {
    font-family: $font-secondary;
    font-weight: 500;
    line-height: 20px;
    margin-top: 21px;
    vertical-align: middle;
    color: $color-green;

    .circle {
      display: inline-block;
      content: '';
      margin-right: 10px;
      height: 8px;
      width: 8px;
      border-radius: 50%;
      background-color: $color-green;
    }
    
    &.yellow {
      color: $color-yellow;
      .circle {
        background-color: $color-yellow;
      }
      a {
        color: $color-yellow;
      }
    }

    &.red {
      color: $color-red;
      .circle {
        background-color: $color-red;
      }
      a {
        color: $color-red;
      }
    }

    a {
      color: $color-green;
      text-decoration-line: underline;
    }
  }

  .product-brand-collection {
    display: grid;
    grid-template-columns: 30% 70%;
    margin-top: 10px;
    padding: 10px;
    background: $color-lighter-grey-6;
    align-items: center;
    gap: 15px;

    &:hover {
      background-color: #f1f1f1;
    }
    
    @include media-breakpoint-up(md) {
      gap: 25px;
    }
    
    .collection-info {
      .brand-name {
        font-family: $font-secondary;
        font-size: 13px;
        font-weight: 600;
        color: $color-light-grey;
        letter-spacing: 2px;
        line-height: 18px;
      }
      .name {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 25px;
        font-style: italic;
        color: $color-black;
      }
      .url {
        /*a {*/
          font-family: $font-primary;
          color: $color-grey;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          font-style: italic;
          text-decoration: underline;
        /*}*/
      }
    }
    
    .product-images {
      display: grid;
      gap: 2px;
      grid-auto-rows: 50%;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
      aspect-ratio: 1 / 1;

      &.single {
        grid-auto-rows: 100%;
        grid-template-columns: 1fr;
      }

      .img {
        height: 100%;
        background: $color-lighter-grey-6;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
  
  .product-delivery-benefits {
    margin-top: 20px;
    padding: 24px;
    background: $color-lighter-grey-6;

    a.show_delivery_methods {
      margin-top: 10px;
      display: inline-block;
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.25;
      text-decoration-line: underline;
      color: $color-grey;
    }
    
    &.hidden-methods {
      .delivery-header, .delivery-types-list {
        display: none;
      }
      
      .fast-delivery {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        
        .header {
          display: flex;
          align-items: center;
          
          .courier-icon {
            background-color: $color-black;
            margin-right: 10px;
          }
        }
      }
      
      .show_delivery_methods.less {
        display: none;
      }
    }
    
    &:not(.hidden-methods) {
      .fast-delivery {
        display: none;
      }
      .show_delivery_methods.less {
        display: block;
        margin-top: 20px;
      }
    }
    
    .oversized-info {
      padding: 12px;
      display: flex;
      gap: 10px;
      //border: 1px solid #1E1E1E;
      font-family: $font-secondary;
      background: rgba($color-light-yellow, 0.6);;
      margin-bottom: 20px;
    }

    .free-delivery-block {
      font-family: $font-secondary;
      font-weight: 500;
      margin-bottom: 25px;
      
      .red {
        color: $color-red;
        font-weight: 600;
      }
    }
    
    .fast-delivery {
      padding-bottom: 15px; 
      margin-bottom: 15px;
      border-bottom: 1px solid $color-lighter-grey-2;
      
      .header {
        font-family: $font-secondary;
        font-size: 16px;
        //font-style: italic;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
      .time {
        font-family: $font-secondary;
        line-height: 20px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
    
    .delivery-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .title {
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        vertical-align: middle;
      }

      a {
        display: inline-block;
        margin-left: 15px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.25;
        text-decoration-line: underline;
        text-align: right;
        color: $color-grey;
      }
    }

    ul {
      li {
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;

        + li {
          margin-top: 12px;
        }

        .delivery-title {
          display: flex;
          font-style: italic;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;

          .info {
            font-style: normal;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #1E1E1E;

            font-family: $font-secondary;

            .free {
              color: $color-red;
              font-weight: 600;
            }
            
            .weight {
              font-size: 12px;
              color: #716B66;
              font-weight: 500;

              a {
                text-decoration: underline;
                color: #716B66;
              }
            }
          }
        }
        
        .price {
          flex-shrink: 0;
          margin-left: 15px;
        }

        strong, b {
          font-weight: 700;
        }

        .icon {
          display: block;
          flex-shrink: 0;
          margin-right: 10px;
          width: 24px;
          height: 24px;
          background-size: 75%;
          background-repeat: no-repeat;
          background-position: center center;
        }
        
        &.disabled {
          .delivery-title {
            font-weight: 400;
            color: #716B66;
          }
        }
      }
    }
  }

  .add-to-cart-wrp {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    column-gap: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .quantity-control {
    display: flex;
    column-gap: 1px;
    margin-bottom: 8px;

    .cta-btn {
      width: 44px;
      padding: 0;
      font-size: 16px;
      color: $color-grey;

      i {
        transition: all 0.3s ease;
      }
    }

    input {
      width: 44px;
      border: 0;
      background: $color-lighter-grey;
      font-family: $font-secondary;
      font-size: 16px;
      text-align: center;
      font-style: normal;
      font-weight: 600;
      color: $color-black;
      appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .add-to-cart {
    position: relative;
    width: 100%;
  }

  .outlet-offer {
    margin-top: 8px;
    margin-bottom: 0;
    font-style: italic;

    a {
      color: $color-black;
      text-decoration: underline;

      &:hover {
        color: $color-hover-red;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .product-bottom-info {
      .btn-to-desc {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }

    .product-notice {
      a {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }

    .product-delivery-benefits {
      .delivery-header {
        a {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .lowest_30d_price_msg {
      font-weight: 500;
      font-size: 12px;
      text-align: right;
    }
  }
  
  @include media-breakpoint-up(xs) {
    h1 {
      font-size: 24px;
    }

    .add-to-cart-wrp {
      flex-direction: row;
    }

    .quantity-control {
      margin-bottom: 0;
    }

    .product-price-outlet {
      .price-box {
        .price {
          font-size: 20px;

          .outlet-icon {
            width: 20px;
            margin-top: 0;
          }
        }

        .price-desc {
          display: block;
          font-weight: 500;
          font-size: 11px;
          line-height: 1.2;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .top-info-group{
      column-gap: 24px;

      .product-country{
        margin-right: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    //width: 45%;
    //padding-left: 50px;

    .product-notice {
      a {
        &:hover {
          text-decoration-line: none;
        }
      }
    }

    .quantity-control {
      .cta-btn {
        width: 48px;

        &:hover {
          i {
            color: $color-white;
          }
        }
      }

      input {
        width: 48px;
      }
    }

    .product-top-info {
      .btn-wishlistInfo {
        &:hover {
          i {
            color: $color-hover-red;
          }
        }
      }
    }

    .product-bottom-info {
      .rating {
        i {
          color: $color-yellow;
        }
      }

      a {
        &:hover {
          text-decoration-line: none;
        }
      }
    }

    .product-delivery-benefits {
      .delivery-header {
        margin-bottom: 20px;

        a {
          &:hover {
            text-decoration-line: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product-top-info {
      margin-bottom: 16px;

      .product-brands {
        .brand-item {
          + .brand-item {
            margin-left: 24px;
          }
        }

        img {
          max-width: 114px;
          max-height: 44px;
        }
      }
    }

    h1 {
      font-size: 32px;
      line-height: 1.125;
    }

    .product-price {
      font-size: 36px;

      .price-old {
        margin-left: 24px;
        font-size: 20px;
        font-weight: 400;
      }

      .out-of-stock {
        font-size: 24px;
      }
    }

    .product-price-outlet {
      .price-box {
        .price {
          font-size: 32px;
          margin-bottom: 5px;

          .outlet-icon {
            width: 28px;
            margin-top: -3px;
            margin-right: 8px;
          }
        }
      }
    }

    .product-delivery-benefits {
      ul {
        li {
          font-size: 14px;

          + li {
            margin-top: 14px;
          }

          .icon {
            margin-top: -2px;
          }
        }
      }
    }
  }
}