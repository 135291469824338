.product-gallery {
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 28px;

  .product-tags {
    top: 16px;
    left: 16px;
    z-index: 3;
  }

  .product-gallery-view {
    position: relative;

    .product-gallery-view-item {
      display: none;
      width: 100%;
      padding-left: 24px;
      padding-right: 24px;

      &.active {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        object-fit: contain;
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 45px;
        color: $color-white;
      }

      .photo-desc {
        display: block;
        margin-top: 12px;
        font-family: $font-secondary;
        text-align: center;
        color: $color-grey;
      }
    }
  }

  .thumb-list-container {
    margin-top: 12px;
    padding-right: 10px;
  }

  .product-gallery-thumb-list {
    display: flex;
    overflow: auto;
    height: 58px;

    .product-gallery-thumb-item {
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-shrink: 0;
      width: 57px;
      height: 57px;
      margin-left: 10px;
      border: 1px solid $color-lighter-grey-2;

      &.active {
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: -1px;
          right: -1px;
          height: 2px;
          background: $color-red;
        }
      }

      &.video-item {
        img {
          object-fit: cover;
        }
      }

      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 20px;
        color: $color-white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: 0;

    .product-gallery-thumb-list {
      padding-right: 0;

      .product-gallery-thumb-item {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    .product-gallery-view {
      .product-gallery-view-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    //width: 55%;
    margin-bottom: 0;

    .thumb-list-container {
      padding-right: 33px;
      
      .tns-controls {
        flex-direction: column;
        align-items: center;
        top: 0;
        height: 100%;
        transform: none;

        button {
          width: 32px;
          height: 32px;

          &[data-controls="prev"],
          &[data-controls="next"] {
            transform: none;
          }

          i {
            font-size: 16px;
          }
        }
      }
    }

    .product-gallery-thumb-list {
      display: block;
      order: 1;
      margin-top: 0;
      overflow: visible;
      height: 100%;

      .product-gallery-thumb-item {
        margin-left: 0;
      }
    }

    .product-gallery-view {
      order: 2;
      flex-grow: 1;

      .product-gallery-view-item {
        img {
          padding: 24px;
          border: 1px solid $color-lighter-grey-8;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    //width: 62.8%;

    .product-gallery-thumb-list {
      .product-gallery-thumb-item {
        width: 64px;
        height: 64px;
      }
    }
  }
}