@mixin delivery-icon($name: '', $width: 22px, $height: 22px, $color: #219653) {
  
  background-color: $color;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-image: url("/images/frontend/#{$name}.svg");
  mask-image: url("/images/frontend/#{$name}.svg");

  /*background-color: $color;
  background-position: center center;
  -webkit-mask: url(/images/frontend/#{$name}.svg) no-repeat top left;
  -webkit-mask-position: center center;
  mask: url(/images/frontend/#{$name}.svg) no-repeat top left;
  mask-position: center center;
  display: inline-block;*/
  height: $height;
  width: $width;
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

$ondemand-color: #F18638;

.omniva-icon {
  &:not(.disabled) {
    @include delivery-icon('omniva2', 24px, 24px);
  }

  &.ondemand:not(.disabled) {
    background-color: $ondemand-color;
  }
  
  &.disabled {
    @include delivery-icon('omniva2_disabled', 24px, 24px, #AFAFAF);
  }
}

.pickup-icon {
  &:not(.disabled) {
    @include delivery-icon('pickup2', 20px, 20px);
  }

  &.ondemand:not(.disabled) {
    background-color: $ondemand-color;
  }
  
  &.disabled {
    @include delivery-icon('pickup2_disabled', 22px, 22px, #AFAFAF);
  }
}

.courier-icon {
  &:not(.disabled) {
    @include delivery-icon('courier2', 20px, 20px);
  }

  &.ondemand:not(.disabled) {
    background-color: $ondemand-color;
  }
  
  &.disabled {
    @include delivery-icon('courier2_disabled', 22px, 22px, #AFAFAF);
  }
}

.venipak-icon {
  &:not(.disabled) {
    @include delivery-icon('venipak2', 18px, 18px);
  }
  
  &.ondemand:not(.disabled) {
    background-color: $ondemand-color;
  }
  
  &.disabled {
    @include delivery-icon('venipak2_disabled', 24px, 24px, #AFAFAF);
  }
}

.terminal-icon {
  &:not(.disabled) {
    @include delivery-icon('terminal2', 18px, 18px);
  }

  &.ondemand:not(.disabled) {
    background-color: $ondemand-color;
  }
  
  &.disabled {
    @include delivery-icon('terminal2_disabled', 24px, 24px, #AFAFAF);
  }
}

.delivery-title {
  .delivery-icon {
    margin-right: 10px;
    
    &.ondemand:not(.disabled) {
      background-color: $ondemand-color;
    }
    
    &:not(.disabled) {
      background-color: #1E1E1E;
    }
  }
}