.customer-reviews {
  .reviews-header {
    display: flex;
    align-items: center;
    margin-bottom: 45px;

    h3 {
      margin-bottom: 2px;
      font-style: italic;
      font-weight: 400;
      font-size: 32px;
      line-height: 1.1;
      color: $color-black;
    }

    .rating {
      margin-left: 26px;
      margin-right: 30px;

      i {
        font-size: 16px;
        color: $color-black;
      }

      a, span {
        margin-left: 22px;
        font-family: $font-secondary;
        line-height: 1;
        color: $color-grey;
      }
    }
  }

  .reviews-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
}