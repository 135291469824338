#review-block {
  .error {
    color: red;
    margin-top: 20px;
    font-size: 14px;

    i {
      margin-right: 5px;
    }
  }
}

.rating {
  display: flex;
  align-items: center;

  i {
    font-size: 12px;
    color: #bbbbbb;
    cursor: pointer;
    
    &.fa-solid {
      color: #FBB605;
    }

    + i {
      padding-left: 8px;
    }
  }

  &.disabled {
    i {
      cursor: default!important;
    }
  }
  
  &.has_error {
    i {
      //color: red!important;
    }
  }
  
  a, span {
    display: inline-block;
    margin-left: 12px;
    font-style: italic;
    line-height: 1;
  }
}

.has_error {
  color: red!important;
  i {
    color: red!important;
  }
  
  textarea {
    border-color: red;
  }
}

.rating-in-words {
  height: 20px;
  margin-left: 8px;
  > div {
    display: none;
    font-size: 16px;
    color: #716B66;
    font-family: $font-primary;
    font-style: italic;
  }

  @include media-breakpoint-down(lg) {
    display: none;
    
    &.mobile {
      display: block;
      text-align: center;
      margin-top: 20px;
    }
  }

  @include media-breakpoint-up(lg) {
    &.mobile {
      display: none;
    }
  }
}

.reviews-total {
  display: flex;
  
  .text {
    margin-right: 10px;
  }
  
  .average-rating {
    margin-left: 10px;
  }
  
  .rating {
    display: inline-block;
    position: relative;

    .stars {
      display:block;
      float:left;
      margin: 0;
      width:97px;
      height:14px;
      position:relative;
      background:url('/images/frontend/stars2.png') no-repeat top left;
      background-size: contain;
    }

    .stars-filled-block {
      width: 100%;
      overflow: hidden;
      height: 14px;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 0;

      .stars-filled {
        width:97px;
        height:14px;
        opacity: 1;
        text-indent:-9999px;
        display:block;
        background:url('/images/frontend/stars2_filled.png') no-repeat top left;
        background-size: contain;
        margin: 0;

        &.stars-1 {width:34px;}
        &.stars-2 {width:75px;}
        &.stars-3 {width:115px;}
        &.stars-4 {width:156px;}
        &.stars-5 {width:197px;}
      }
    }
  }
}

.total-rating {
  display: inline-block;
  position: relative;

  .stars {
    display:block;
    float:left;
    margin: 0;
    width:168px;
    height:24px;
    position:relative;
    background:url('/images/frontend/stars2.png') no-repeat top left;
    background-size: contain;
  }

  .stars-filled-block {
    width: 100%;
    overflow: hidden;
    height: 24px;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    margin-left: 0;

    .stars-filled {
      width:168px;
      height:24px;
      opacity: 1;
      text-indent:-9999px;
      display:block;
      background:url('/images/frontend/stars2_filled.png') no-repeat top left;
      background-size: contain;
      margin: 0;

      &.stars-1 {width:34px;}
      &.stars-2 {width:75px;}
      &.stars-3 {width:115px;}
      &.stars-4 {width:156px;}
      &.stars-5 {width:197px;}
    }

    .star-filled {
      position: absolute;
      overflow: hidden;

      > span {
        display: block;
        height: 24px;
        width: 26px;
        background:url('/images/frontend/star2_filled.png') no-repeat top left;
        background-size: contain;
        margin: 0;
      }

      &.star-1 {
        left: 0;
      }
      &.star-2 {
        left: 35px;
      }
      &.star-3 {
        left: 70px;
      }
      &.star-4 {
        left: 105px;
      }
      &.star-5 {
        left: 140px;
      }
    }
  }

  &.medium-small {
    .stars {
      width: 97px;
      height: 14px;
    }


    .stars-filled-block {

      .star-filled {

        > span {
          display: block;
          height: 14px;
          width: 15px;
        }
        &.star-2 {
          left: 21px;
        }
        &.star-3 {
          left: 41px;
        }
        &.star-4 {
          left: 61px;
        }
        &.star-5 {
          left: 82px;
        }
      }
    }
  }
  
  &.medium {
    .stars {
      width: 125px;
      height: 18px;
    }


    .stars-filled-block {

      .star-filled {
        
      > span {
        display: block;
        height: 18px;
        width: 19px;
      }
      &.star-2 {
        left: 27px;
      }
      &.star-3 {
        left: 53px;
      }
      &.star-4 {
        left: 80px;
      }
      &.star-5 {
        left: 105px;
      }
      }
    }
  }
  
  &.small {
    .stars {
      width: 80px;
      height: 12px;
    }
    
    .stars-filled-block {
      height: 12px;

      .star-filled {
        position: absolute;
        overflow: hidden;

        > span {
          display: block;
          height: 12px;
          width: 12px;
        }

        &.star-2 {
          left: 17px;
        }
        &.star-3 {
          left: 34px;
        }
        &.star-4 {
          left: 51px;
        }
        &.star-5 {
          left: 68px;
        }
      }
    }
  }
}