.header-filter-desktop {
  align-items: center;
  justify-content: space-between;
  margin-top: -7px;

  .total-products {
    font-family: $font-secondary;
    color: $color-grey;
  }

  .dropdowns {
    display: flex;
  }

  .bootstrap-select {
    min-width: unset;

    + .bootstrap-select {
      margin-left: 55px;
    }

    > .dropdown-toggle {
      background: none;
      outline: none !important;
      border: 0;
      padding-left: 0;
      padding-right: 0;

      &:after {
        margin-left: 6px;
        border-top-color: $color-grey;
      }
    }

    .filter-option-inner-inner {
      font-family: $font-secondary;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      color: $color-grey;
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 15px;
  }
}