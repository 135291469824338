#buy-together-items {
  #buy-together-summary {
    position: relative;
    display: flex;
    padding: 24px 20px 32px;
    border: 1px solid $color-lighter-grey-2;

    @include media-breakpoint-down(lg) {
      border-top: none;
    }

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .add-to-cart {
      width: 100%;
    }

    .title {
      margin-bottom: 26px;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
    }

    .total-set-price {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 15px;

      .break {
        flex-basis: 100%;
        height: 0;
      }
      
      > span {
        flex-grow: 1
      }
      font-family: $font-secondary;
      font-size: 18px;
      line-height: 1.33;
      font-weight: 700;

      > span {
        flex-grow: 1
      }

      .price-logged {
        line-height: 24px;
        font-size: 20px;
        font-weight: 600;
        color: $color-red;
        img {
          height: 20px;
          margin-top: -2px;
        }
      }

      .for-members {
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;

        color: $color-red;
        a {
          color: $color-red;
          text-decoration: underline;
        }
      }

      .price {
        font-size: 20px;
        font-weight: 500;
        text-align: right;
        line-height: 24px;

        &.main {
          text-align: left;
          font-size: 20px;
          font-weight: 600;
        }
      }

      .price-old {
        display: block;
        font-weight: 500;
        font-size: 16px;
        text-decoration-line: line-through;
        color: $color-light-grey;
        text-align: right;
        
        &.main {
          padding-top: 5px;
          text-align: left;
        }
      }
    }
  }
  
  h2 {
    text-align: center;
    margin-bottom: 25px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.16;
    color: $color-black;
  }

  .product-sets {

    @include media-breakpoint-down(lg) {
      position: relative;
      .sets-inner {
        border: 1px solid $color-lighter-grey-2;
        border-bottom: none;
      }
      
      .plus {
        position: absolute;
        margin-top: -15px;
        width: 100%;
        text-align: center;
        
        > div {
          display: inline-block;
          font-size: 25.846px;
          background: #fff;
          height: 25px;
          width: 25px;
          line-height: 25px;
          vertical-align: middle;
        }
      }
    }
    
    .product-card-small {
      position: relative;
      align-items: center;
      padding: 16px 24px 20px 30px;
      margin: 0 20px;
      margin-top: -1px;
      border-bottom: 1px solid $color-lighter-grey-2;

      &:first-of-type {
        margin-top: 0;
      }

      &.active {
        .img-wrp {
          img {
            opacity: 1;
          }
        }

        .set-item-checkbox {
          span {
            background: $color-black;

            i {
              display: block;
              color: $color-white;
            }
          }
        }
      }

      .set-item-checkbox {
        position: absolute;
        top: 50%;
        margin-top: -14px;
        left: 0;
        z-index: 1;
        flex-shrink: 0;
        padding: 6px;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          background: $color-lighter-grey-2;

          i {
            display: none;
            font-size: 12px;
            color: $color-black;
          }
        }
      }

      .img-wrp {
        pointer-events: none;

        img {
          width: 56px;
          height: 56px;
          opacity: 0.1;
        }
      }

      .name {
        color: $color-grey;
      }

      .prices {
        display: flex;
      }
      
      .price {
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.28px;
        line-height: 20px;
      }

      .price-logged {
        font-family: $font-secondary;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.28px;
        color: $color-red;
        letter-spacing: -0.28px;

        img {
          height: 12px;
          margin-top: -3px;
        }
      }

      .price-not-logged {
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.24px;
        padding-left: 8px;
      }

      .price-old {
        font-family: $font-secondary;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 500;
        text-decoration-line: line-through;
        color: $color-light-grey;
        padding-left: 8px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .sets-box-wrp {
      display: flex;
    }

    .product-sets {
      width: 69%;
      padding: 40px;
      border: 1px solid $color-lighter-grey-2;

      .sets-inner {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
      }

      .set-checkboxes {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;

        .checkbox {
          letter-spacing: 0.02em;

          + .checkbox {
            margin-top: 10px;
          }

          input {
            &:checked {
              ~ .text {
                text-decoration-line: none;
              }
            }
          }

          .text {
            text-decoration-line: line-through;

            .name {
              font-style: italic;
              font-family: $font-primary;
              padding-right: 7px;
            }
            
            .price {
              font-weight: 600;
              font-size: 16px;
              letter-spacing: 0;
            }

            .price-logged {
              font-weight: 600;
              font-size: 16px;
              color: $color-red;
              letter-spacing: 0;
              padding-right: 10px;
              
              img {
                height: 16px;
                margin-top: -3px;
              }
            }

            .price-not-logged {
              font-weight: 600;
              font-size: 16px;
              letter-spacing: 0;
            }

            .price-old {
              font-size: 16px;
              letter-spacing: 0;
              padding-left: 10px;
              font-weight: 500;
              text-decoration-line: line-through;
              color: $color-light-grey;
            }
          }
        }
      }

      .plus {
        align-items: center;
        justify-content: center;
        font-size: 48px;
        line-height: 1;
      }

      .product-card-small {
        padding: 0;
        margin-top: 0;
        border: 0;

        .img-wrp {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 104px;
          flex-shrink: 1;
          margin-right: 0;
          pointer-events: all;

          img {
            flex-shrink: 1;
            width: auto;
            height: auto;
            max-width: 120px;
            max-height: 120px;
          }
        }
      }
    }

    #buy-together-summary {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 31%;
      padding: 40px;
      border-left: 0;

      .title {
        margin-bottom: 25px;
        margin-right: 0;
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 1.16;
        flex-grow: 1;
      }

      .total-set-price {
        padding-bottom: 20px;
        flex-direction: row;
        flex-wrap: wrap;

        > span {
          flex-grow: 1
        }
        
        .price-logged {
          line-height: 28px;
          font-size: 24px;
          font-weight: 600;
          color: $color-red;
          img {
            height: 28px;
            margin-top: -4px;
          }
        }
        
        .price {
          font-size: 24px;
          font-weight: 600;
          text-align: right;
          line-height: 28px;
          
          &.main {
            text-align: left;
            /*font-size: 32px;
            font-weight: 600;*/
          }
        }

        .price-old {
          font-size: 16px;
          font-weight: 500;
          text-align: right;
          line-height: 20px;
          order: 2;
          &.main {
            padding-top: 5px;
            text-align: left;
            font-size: 18px;
          }
        }
      }

      .add-to-cart {
        position: relative;
        left: unset;
        right: unset;
        width: 100%;
        margin-top: auto;
        
        i {
          position: absolute;
          top: 50%;
          right: 16px;
          transform: translateY(-50%);
          font-size: 18px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    h2 {
      font-size: 32px;
      line-height: 1.16;
    }
    .product-sets {
      padding: 40px 52px;


      .set-checkboxes {
        .checkbox {
          + .checkbox {
            margin-top: 12px;
          }
        }
      }
    }

    #buy-together-summary {
      padding: 40px 46px;

      .title {
        margin-bottom: 20px;
        font-size: 32px;
      }

      .total-set-price {
        font-size: 28px;

        .price-old {
          font-size: 16px;
        }
      }
    }
  }
}