#products-detailed {
  .breadcrumb-wrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 16px;

    .product-code {
      margin-left: 40px;
    }
  }

  .product-block {
    margin-bottom: 55px;
    
    .buy-together-block {
      margin-top: 20px;
    }
  }

  .product-code {
    margin-bottom: 0;
    font-family: $font-secondary;
    line-height: 1;
    color: $color-light-grey;
    white-space: nowrap;
  }

  .add-to-cart-detailed {
    i {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 18px;
    }
  }

  .product-desc-details {
    margin-top: 40px;

    .details-tabs {
      display: block;

      li {
        border-top: 1px solid $color-lighter-grey-2;

        &:first-child {
          border-top: 0;
        }

        a {
          position: relative;
          display: block;
          padding: 24px 20px 24px 50px;
          font-family: $font-secondary;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
          color: $color-black;

          i {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            font-size: 16px;

            &.tab-icon {
              left: 6px;
              right: unset;
              width: 30px;
              text-align: center;
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .products-sets-box {
    margin-top: 57px;
  }

  @include media-breakpoint-up(lg) {
    .product-block {
      display: flex;
      align-items: flex-start;
      margin-bottom: 64px;
      
      .left-content {
        width: 55%;
        
        .buy-together-block {
          display: block;
          margin-left: 97px;
        }
      }
      .right-content {
        width: 45%;
        padding-left: 50px;
      }
    }

    .breadcrumb-wrp {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    .product-desc-details {
      .details-tabs {
        position: relative;
        display: flex;
        justify-content: center;

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 1px;
          background: $color-lighter-grey-2;
        }

        li {
          margin-right: 40px;
          border-top: 0;

          &:last-child {
            margin-right: 0;
          }

          a {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;
            font-size: 14px;
            line-height: 1.25;
            transition: color 0.3s ease;

            &.active, &:hover {
              color: $color-red;

              &:after {
                opacity: 1;
              }
            }

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              z-index: 2;
              width: 100%;
              height: 2px;
              background: $color-red;
              opacity: 0;
              transition: opacity 0.3s ease;
            }

            .tab-icon {
              position: static;
              width: auto;
              transform: none;
              margin-bottom: 12px;
              font-size: 32px;
            }
          }
        }
      }

      .details-tabs-content {
        max-width: 850px;
        margin-top: 40px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .product-desc-details {
      .details-tabs-content {
        margin-top: 55px;
      }
    }
    
    .left-content {
      width: 62.8%!important;
    }
    
    .right-content {
      width: 37.2%!important;
      padding-left: 5.5%!important;
    }
  }
}