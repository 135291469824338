.site-footer {
  background-color: $color-lighter-grey;
  padding-top: 16px;
  padding-bottom: 26px;

  .container {
    display: grid;
    grid-template-areas:
      "menu"
      "reviews"
      "payments"
      "phone"
      "copy";
    padding-left: 20px;
    padding-right: 20px;
  }

  .footer-reviews {
    display: grid;
    gap: 32px 0;
    grid-area: reviews;
    margin-bottom: 42px;

    .footer-review-item {
      h3 {
        margin-bottom: 15px;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        color: $color-black;
      }

      .rating {
        margin-bottom: 15px;
        
        .stars {
          width: 125px;
          height: 18px;
        }
        
        .stars-filled-block {
          height: 18px;
          .stars-filled {
            width: 125px;
            height: 18px;
          }
        }
      }
      
      .average-rating {
        font-size: 16px;
        font-style: italic;
      }

      .link {
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 400;
        text-transform: none;
      }
      
      .text {
        font-family: $font-secondary;
        font-size: 14px;
        letter-spacing: 0;
        font-weight: 400;
      }
      
      &.projects {
        @include media-breakpoint-up(xl) {
          //margin-top: 30px;
        }
        
        .projects-wrp {
          display: flex;
          gap: 15px;
          
          a {
            text-align: center;
            color: $color-black;
            font-family: $font-secondary;
            text-transform: lowercase;
          }
        }
        
        .flag {
          display: inline-block;
          margin-bottom: 5px;
          
          img {
            width: 26px;
            height: auto;
            padding: 1px;
            border-radius: 50%;
            border: 1px solid #a5a5a5;
          }
        }
      }
    }
  }

  .payment-types {
    grid-area: payments;
    margin-bottom: 45px;
    
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      + div {
        margin-top: 20px;
      }
    }

    img {
      height: auto;

      &.seb {
        width: 37px;
      }

      &.luminor {
        width: 60px;
      }

      &.sb {
        width: 75px;
      }

      &.visa {
        width: 30px;
      }

      &.mc,
      &.ms {
        width: 34px;
      }
    }

    span {
      display: inline-flex;
      align-items: center;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;

      img {
        margin-right: 8px;

        &.wallet {
          width: 16px;
        }

        &.transfer {
          width: 20px;
        }
      }
    }
  }

  .copyright {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-area: copy;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;

    .social {
      display: flex;
      align-items: center;

      span {
        display: inline-block;
        margin-right: 9px;
      }

      a {
        font-size: 16px;
        color: $color-black;

        + a {
          margin-left: 15px;
        }

        &:hover {
          color: $color-red;
        }
      }
      
      
      .phone {
        border-right: 1px solid #AFAFAF;
        padding-right: 12px;
      }
    }
  }

  .phone-block {
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
    
    a {
      font-size: 14px;
      font-weight: 400;
      vertical-align: middle;
      color: #222222;
    }
  }
  
  .footer-menu {
    grid-area: menu;
    margin-bottom: 30px;

    .menu-col {
      border-bottom: 1px solid #E9E9E9;

      h3 {
        display: block;
        margin: 0;
        padding-top: 16px;
        padding-bottom: 16px;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.22;
        color: $color-black;

        a {
          color: $color-black;
        }

        i {
          right: 8px;
        }
      }

      ul {
        padding-bottom: 20px;

        li {
          + li {
            margin-top: 11px;
          }

          a {
            font-family: $font-secondary;
            color: $color-black;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .footer-menu {
      .menu-col {
        ul {
          li {
            a {
              -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .footer-reviews {
      display: flex;

      @include media-breakpoint-down(lg) {
        flex-wrap: wrap;
      }
      
      .footer-review-item {
        + .footer-review-item {
          margin-left: 50px;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 45px;
    padding-bottom: 40px;

    .container {
      display: grid;
      grid-template-areas:
      "payments payments payments payments"
      "menu menu menu reviews"
      "copy copy copy copy";
      gap: 50px 40px;
    }

    .footer-reviews {
      display: grid;
      gap: 25px 0;
      grid-auto-rows: minmax(min-content, max-content);
      margin-bottom: 0;

      .footer-review-item {
        + .footer-review-item {
          margin-left: 0;
        }
      }
    }

    .footer-menu {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 0 40px;
      margin-bottom: 0;

      .menu-col {
        border-bottom: 0;

        h3 {
          padding-top: 0;

          a {
            &:hover {
              color: $color-red;
            }
          }
        }

        ul {
          padding-bottom: 0;

          li {
            a {
              &:hover {
                color: $color-red;
              }
            }
          }
        }
      }
    }

    .copyright {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 60px;

    .container {
      gap: 0 40px;
    }

    .footer-reviews {
      display: flex;
      justify-content: flex-end;

      .footer-review-item {
        + .footer-review-item {
          margin-left: 100px;
        }
      }
    }

    .footer-menu {
      .menu-col {
        h3 {
          padding-bottom: 14px;
          font-size: 16px;
        }
      }
    }

    .payment-types {
      margin-bottom: 60px;

      img {
        height: auto;

        &.seb {
          width: 55px;
        }

        &.luminor {
          width: 80px;
        }

        &.sb {
          width: 95px;
        }

        &.visa {
          width: 50px;
        }

        &.mc,
        &.ms {
          width: 40px;
        }
      }

      span {
        font-size: 16px;

        img {
          margin-right: 14px;
        }
      }
    }

    .copyright {
      font-size: 12px;
      margin-top: 70px;

      .social {
        span {
          margin-right: 12px;
        }
      }
    }
  }

  @include media-breakpoint-up(xxxl) {
    .footer-menu {
      grid-template-columns: repeat(4, 1fr);

      .menu-col {
        h3 {
          padding-bottom: 14px;
          font-size: 16px;
        }
      }
    }
  }
}