.link {
  position: relative;
  display: inline-block;
  font-family: $font-secondary;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color-red;
  transition: color 0.3s ease;

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: $color-red;
    transition: all 0.3s ease;
  }

  &.black {
    color: $color-black;

    &:after {
      background-color: $color-black;
    }

    &:active {
      color: $color-red;
    }

    &.disabled {
      color: $color-light-grey;

      &:after {
        background-color: $color-light-grey;
      }
    }
  }

  &.link-icon-right {
    padding-right: 20px;
    color: $color-black;

    &:after {
      display: none;
    }

    span {
      position: relative;
      display: inline-block;

      &:after {
        content: '';
        position: absolute;
        bottom: 2px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $color-black;
        transition: all 0.3s ease;
      }
    }

    i {
      font-size: 12px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  @include media-breakpoint-down(lg) {
    -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      color: $color-hover-red;

      &:after {
        background-color: transparent;
      }
    }

    &.black {
      &:hover {
        color: $color-hover-red;

        &:after {
          opacity: 0;
        }
      }
    }

    &.link-icon-right {
      &:hover {
        color: $color-hover-red;

        span {
          &:after {
            background-color: transparent;
          }
        }
      }
    }
  }
}