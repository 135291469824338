.customer-external-returning-goods {
  display: grid;
  row-gap: 2px;

  h1 {
    margin-bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-lighter-grey;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: $color-black;
  }

  h3 {
    margin-bottom: 5px;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    color: $color-black;
  }

  p {
    margin-bottom: 0;
    font-family: $font-secondary;
    color: $color-grey;
  }

  .form-items {
    margin-top: 20px;
    margin-bottom: 32px;
  }

  .cta-btn {
    position: relative;

    i {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 16px;
      color: $color-white;
    }
  }

  @include media-breakpoint-up(md) {
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;

    .form-items {
      margin-top: 32px;
      margin-bottom: 40px;
    }

    h1 {
      padding-top: 43px;
      padding-bottom: 43px;
    }
  }
  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 32px;
    }
  }
}