.payment-option-logo {
  margin-top: 10px;
}
.payment-bank-options {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 8px;
  margin-top: 15px;

  &.options-1{
    label{
      padding: 8px !important;

      .check-radio{
        display: none !important;
      }
    }
  }

  label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    padding: 8px 8px 22px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    input {
      display: none;

      &:checked {
        ~ .check {
          border-color: $color-green;

          .check-radio{
            border-color: $color-green;
            background: $color-green;

            &:after{
              background: $color-white;
              opacity: 1;
            }
          }
        }
      }
    }

    &.active {
      .check {
        border-color: $color-green;

        .check-radio{
          border-color: $color-green;
          background: $color-green;

          &:after{
            background: $color-white;
            opacity: 1;
          }
        }
      }
    }

    .check {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid transparent;
      pointer-events: none;

      .check-radio{
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 14px;
        height: 14px;
        border: 1px solid #bebebe;
        border-radius: 100%;
        background: $color-white;

        &:after{
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 5px;
          height: 5px;
          border-radius: 100%;
          background: $color-green;
          transform: translate3d(-50%, -50%, 0);
          opacity: 0;
        }
      }
    }

    img {
      max-width: 76px;
      max-height: 24px;
      width: auto;
    }
  }

  &.esto-ESTO_PAY {
    label {
      img {
        max-width: 90px;
        max-height: 40px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(4, 1fr);
  }
}