* {
  outline: none;
}

body {
  padding-top: 64px;
  background-color: $color-white;
  font-family: $font-primary;
  font-size: 14px;
  line-height: 1.428;
  font-weight: 400;
  color: $color-black;

  @include media-breakpoint-up(xl) {
    padding-top: 0;
  }
}

form {
  [type="submit"] {
    display: flex;
  }
}

.page-wrapper {
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    &.page-grey {
      background: $color-lighter-grey;
    }

    #type_cart & {
      overflow: visible;
    }
  }
}

#type_customers {
  #customer-authorize {
    &.customer-change-password {
      max-width: 630px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.fancybox-slide--iframe {
  padding: 44px 0;

  @include media-breakpoint-up(md) {
    padding: 44px;
  }
}

.container {
  padding-left: 24px;
  padding-right: 24px;
}

.block-spacing {
  margin-bottom: 80px;

  &:last-child, &:last-of-type {
    margin-bottom: 50px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 105px;

    &:last-child, &:last-of-type {
      margin-bottom: 105px;
    }
  }
}

#customers_authorize {
  max-width: 458px;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.header-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $zindex-dropdown;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.grey-bg {
  background: $color-lighter-grey;
}

.block-wrp {
  padding: 32px 24px 50px;

  @include media-breakpoint-up(md) {
    padding-top: 56px;
    padding-bottom: 80px;
  }
}

.white-box {
  padding: 24px 20px;
  background: $color-white;

  @include media-breakpoint-up(md) {
    padding: 48px;
  }
}

@include media-breakpoint-up(lg) {
  .dont-collapse-lg {
    display: block !important;
    height: auto !important;
    visibility: visible !important;
  }
}

.collapse-header {
  position: relative;

  &[aria-expanded="true"] {
    i {
      transform: rotate(180deg) translateY(50%);
    }
  }

  i {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    display: inline-block;
    transform: translateY(-50%);
    font-size: 16px;
    color: $color-grey;
    transform-origin: center;
    transition: all 0.3s ease;
  }
}

.form-control {
  height: 40px;
  padding: 0 16px;
  background: $color-white;
  border: 1px solid $color-light-grey;
  font-family: $font-secondary;
  color: $color-black;

  &:focus {
    border: 1px solid $color-black;
  }

  &::placeholder {
    color: $color-grey;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

textarea.form-control {
  height: 68px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.fancybox-bg {
  background: $color-black;
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.7;
}

#ajax_loader {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: rgba(#1E1E1E, 0.7);

  .load-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 66px;
    height: 20px;
    animation: c 1.8s linear infinite;

    &:before, &:after {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      margin-top: -10px;
      border-radius: 20px;
      background: hsla(0, 0%, 100%, 0.9);
    }

    &:before {
      left: 0;
      animation: d 1.8s linear infinite;
    }

    &:after {
      right: 0;
      animation: e 1.8s linear infinite;
      animation-delay: -0.9s;
    }
  }
}

.disclaimer {
  font-family: $font-secondary;
  color: $color-light-grey;

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.editor-content {
  @include editor-content();
}

#pages_detailed {
  h1 {
    margin-bottom: 16px;
    font-family: $font-primary;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.11;
    color: $color-natural-black
  }

  @include media-breakpoint-up(lg) {
    h1 {
      margin-bottom: 23px;
      font-size: 32px;
    }
  }
}

.inactive-filter {
  .filter-item-color {
    background-color: #EBEBEB!important;
  }
}

.filter-item-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 7px;
  margin-top: 3px;
  border: 1px solid $color-lighter-grey-2;
  border-radius: 100%;
}

#products-listing {
  .listing-bottom-description {
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
}

.fancybox-slide--html {
  padding: 0;

  .fancybox-content {
    width: 100%;
    max-width: 850px !important;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }

  .block-spacing {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .fancybox-content {
      padding-left: 40px !important;
      padding-right: 40px !important;
    }
  }

  .page-title {
    display: block !important;
  }
}