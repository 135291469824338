.collection.collection-compact {
  .collection-items {
    .product-card-compact {
      width: 208px;

      .additional {
        margin-top: 10px;
        height: 12px;
        .inner {
          .notice {
            margin: 0;
          }
          display: none;
        }
      }
      
      &:hover {
        .additional {
          .inner {
            display: block;
          }
        }
      }
      
      &:first-child {
        .card-inner {
          border-left-color: $color-lighter-grey-2;
        }

        .cta-btn.bordered {
          border-left-color: $color-lighter-grey-2;
        }
      }

      .card-inner {
        border-left-color: transparent;
      }

      .cta-btn.bordered {
        border-left-color: transparent;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .collection-items {
      width: 100%;
      padding-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    .tns-ovh {
      overflow: hidden;
    }

    .tns-outer {
      .tns-controls {
        top: 50%;
      }
    }

    .product-card-compact {
      &:hover {
        .card-inner {
          border-left-color: $color-light-grey !important;
        }

        .cta-btn {
          &.bordered {
            border-left-color: $color-red !important;

            &.added-btn{
              border-left-color: $color-green !important;
            }
          }
        }
      }
    }
  }
}