#cart-detailed {
  .cart-summary {
    .logged-price-total {
      display: flex;
      background: #F6F7DD;
      padding: 12px;
      justify-content: space-between;
      line-height: 20px;
      vertical-align: middle;
      font-weight: 500;
      color: $color-red;
      
      .text {
        font-size: 11px;
        
        img {
          height: 14px;
          width: 14px;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      
      .price {
        font-size: 16px;
        font-weight: 700;
      }
    }
    
    .logged-price-info {
      font-family: $font-secondary;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      line-height: 18.5px;
      margin-top: 20px;
    }
  }
  .cart-products {
    grid-area: products;
    padding: 28px 24px 20px;
    border-bottom: 1px solid $color-lighter-grey-2;
    background: $color-white;

    .alert {
      margin-top: 0;
      margin-bottom: 12px;
    }

    h1 {
      margin-bottom: 13px;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      color: $color-black;
    }
    
    .cart-header {
      .column-name {
        display: none;
      }
      @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: minmax(0, 3fr) minmax(0, 1.2fr) minmax(0, 1.4fr) minmax(0, 0.8fr);
        gap: 10px;
        
        .column-name {
          display: block;
          font-family: $font-secondary;
          font-size: 12px;
          font-weight: 600;
          padding-top: 15px;
          text-transform: uppercase;
          letter-spacing: 2px;
          color: #AFAFAF;
          
          &.price, &.amount {
            margin-left: 30px;
          }
          &.total {
            text-align: right;
          }
        }
      }
    }
  }
  
  .voucher-delivery-block {
    grid-area: voucher-delivery;
    
    margin-top: 5px;
  }

  .product-delivery-benefits {
    grid-area: delivery;
    padding: 24px;
    background: $color-white;

    .oversized-info {
      padding: 12px;
      display: flex;
      gap: 10px;
      background-color: #F6F7DD;
      font-family: $font-secondary;
      margin-bottom: 20px;
    }

    .delivery-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;

      .title {
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.25;
        vertical-align: middle;
      }

      a {
        display: inline-block;
        margin-left: 15px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.25;
        text-decoration-line: underline;
        text-align: right;
        color: $color-grey;
      }
    }

    ul {
      li {
        font-weight: 400;
        line-height: 1.5;
        vertical-align: middle;

        + li {
          margin-top: 12px;
        }

        .delivery-title {
          display: flex;
          font-style: italic;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;

          .info {
            font-style: normal;
            margin-top: 5px;
            font-size: 14px;
            font-weight: 400;
            color: #1E1E1E;

            font-family: $font-secondary;

            .weight {
              font-size: 12px;
              color: #716B66;
              font-weight: 500;

              a {
                text-decoration: underline;
                color: #716B66;
              }
            }
          }
        }

        .price {
          flex-shrink: 0;
          margin-left: 15px;
        }

        strong, b {
          font-weight: 700;
        }

        .icon {
          display: block;
          flex-shrink: 0;
          margin-right: 10px;
          width: 24px;
          height: 24px;
          background-size: 75%;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.disabled {
          .delivery-title {
            font-weight: 400;
            color: #716B66;
          }
        }
      }
    }
  }
  
  .optional-cart {
    margin-bottom: 30px;
    padding: 15px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E73132' stroke-width='1' stroke-dasharray='5%2c5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");

    .additional-info {
      margin-bottom: 20px;
      display: flex;
      justify-content: right;
      margin-top: 10px;
      margin-left: 116px;
      .delivery-methods {
        > div {
          margin-right: 7px;
        }
      }

      .cart-product-logged-total {
        color: $color-red;
        font-family: $font-secondary;
        font-weight: 600;
        justify-content: right;
        text-align: right;
        font-size: 12px;
        margin-top: 20px;

        img {
          width: 12px;
          height: 12px;
          margin-top: -2px;
        }

        .for-members {
          display: block;
          margin-bottom: 2px;
          color: #1E1E1E;
          font-weight: 600;
          a {
            color: #1E1E1E;
            text-decoration: underline;
          }
        }
      }
    }
    
    @include media-breakpoint-up(lg) {
      .additional-info {
        margin-left: 131px;
      }
    }
    
    .op-title {
      margin-bottom: 25px;

      h2 {
        margin-bottom: 10px;
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 1;
        color: $color-black;
      }

      a {
        display: inline-flex;
        align-items: center;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $color-black;
        transition: all 0.3s ease;

        &:hover {
          color: $color-hover-red;

          span {
            text-decoration-line: none;
          }
        }

        span {
          text-decoration-line: underline;
        }

        i {
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }

    .product-card-small {
      margin-bottom: 0;
      
      .details {
        display: unset;
        flex-direction: unset;
        lign-items: unset;
        flex-grow: 1;

        .price-old {
          display: block;
          font-family: $font-secondary;
          color: $color-light-grey;
          font-weight: 500;
          &:not(.price-not-logged) {
            text-decoration-line: line-through;

            @include media-breakpoint-down(lg) {
              font-size: 12px;
            }
          }

          @include media-breakpoint-down(lg) {
            margin-top: 0;
          }
        }
      }
      
      .product-title {
        
        .name {
          display: block;
          margin-top: 8px;
          margin-bottom: 12px;
          font-size: 14px;
          color: $color-grey;
        }
      }

      .quantity {
        font-family: $font-secondary;
        font-weight: 500;
      }

      .mobile-prices-block {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;

        .price-unit {
          font-size: 12px;
        }
      }

      .price-total {
        color: #000;
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;


        &.logged {
          @include media-breakpoint-down(lg) {
            color: $color-red;

            img {
              width: 16px;
              height: 16px;
              margin-top: -3px;
            }
          }
        }
      }
    }

    .op-btns {
      button {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        i {
          margin-right: 16px;
          font-size: 16px;
        }
      }
    }
  }

  .cart-item {
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 30px;
    
    + .cart-item {
      margin-top: 35px;
    }

    .bundle-items {
      display: none;
      padding: 30px 30px 30px 131px;
      
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .photo {
          position: relative;
          margin-right: 25px;

          .discount {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 0 6px;
          }
        }

        .info {

          .name {
            @include media-breakpoint-up(lg) {
              width: 290px;
            }
            
            a {
              font-size: 14px;
              font-style: italic;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0.02em;
              text-align: left;
              color: #716B66;
              
              &:hover {
                text-decoration: underline;
              }
            }
          }
          
          .prices {
            font-family: $font-secondary;

            .price {
              font-size: 14px;
              font-weight: 600;
              line-height: 24px;
              margin-bottom: 4px;
            }

            .old-price {
              font-size: 12px;
              font-weight: 500;
              line-height: 12px;
              letter-spacing: -0.02em;
              text-decoration: line-through;
              color: #AFAFAF;
            }
            
            @include media-breakpoint-down(lg) {
              margin-top: 5px;
              display: flex;
              align-items: baseline;
              
              .price {
                order: 2;
              }
              
              .old-price {
                order: 1;
                margin-right: 8px;
              }
            }
          }
        }
      }
    }
    
    &.expanded {
      .bundle-items {
        display: block;
      }
    }
    
    .mobile-prices-block {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      
      .price-unit {
        font-size: 12px;
      }
    }
    
    .product-info {
      display: inline-grid;
      
      .inner-block {
        display: flex;
      }

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
      
    }

    .img-wrp {
      display: flex;
      justify-content: center;
      flex-shrink: 0;
      line-height: 0;

      img {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }
    }

    .delivery-term {
      font-family: $font-secondary;
      font-weight: 500;
      color: #AFAFAF;
    }
    
    .additional-info {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      margin-left: 116px;
      .delivery-methods {
        > div {
          margin-right: 7px;
        }
      }
    }

    .cart-product-logged-total {
      color: $color-red;
      font-family: $font-secondary;
      font-weight: 600;
      justify-content: right;
      text-align: right;
      font-size: 12px;
      margin-top: 20px;

      img {
        width: 12px;
        height: 12px;
        margin-top: -2px;
      }

      .for-members {
        display: block;
        margin-bottom: 2px;
        color: #1E1E1E;
        font-weight: 600;
        a {
          color: #1E1E1E;
          text-decoration: underline;
        }
      }
    }

    .bundle-more {
      display: flex;
      align-items: center;
      border: 1px solid $color-black;
      vertical-align: middle;
      margin-top: 15px;

      a {
        font-size: 12px;
        font-weight: 600;
        color: $color-black;
        font-family: $font-secondary;
        text-transform: uppercase;
        letter-spacing: 2px;
        text-align: center;
        flex: 1;
      }
    }
    
    .product-price {
      align-items: center;
      font-family: $font-secondary;
      font-weight: 600;

      .price {
        display: block;

        @include media-breakpoint-down(lg) {
          font-size: 12px;
          font-weight: 500;
        }
        
        &.logged-price {
          color: $color-red;
        }
      }

      &.has-discount {
        //color: #E73132;
      }
      
      .price-old {
        display: block;
        color: $color-light-grey;
        font-weight: 500;
        &:not(.price-not-logged) {
          text-decoration-line: line-through;

          @include media-breakpoint-down(lg) {
            font-size: 12px;
          }
        }

        @include media-breakpoint-down(lg) {
          margin-top: 0;
        }
      }
      
      img {
        height: 16px;
        margin-top: -2px;
      }

      .out-of-stock {
        display: block;
        font-size: 10px;
        font-weight: 600;
        color: $color-light-grey;
      }
    }

    .details {
      flex-grow: 1;
      margin-left: 16px;

      .product-brand {
        display: inline-block;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $color-light-grey;
        transition: color 0.3s ease;
      }

      .product-title {
        margin-top: 8px;
        margin-bottom: 12px;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.428;

        a {
          color: $color-grey;
        }
      }

      .description {
        font-family: $font-secondary;
        color: $color-grey;
      }
      
      .price-total {
        color: #000;
        font-family: $font-secondary;
        font-size: 16px;
        font-weight: 600;
        line-height: 22px;
        
        
        &.logged {
          @include media-breakpoint-down(lg) {
            color: $color-red;
            
            img {
              width: 16px;
              height: 16px;
              margin-top: -3px;
            }
          }
        }
      }
    }

    .cart-product-actions {
      margin-top: 20px;

      form {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .product-remove {
        &.mobile {
          align-items: center;
          justify-content: center;
          padding: 0 30px;
          margin-right: 1px;
          height: 48px;
          background: $color-lighter-grey;
          
          i {
            margin-right: 4px;
          }
        }

        color: #716B66;
        font-family: $font-secondary;
        line-height: 24px;
        vertical-align: middle;
        text-transform: uppercase;
        i {
          font-size: 14px;
        }

        span {
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 2px;
        }
        
        &:not(.mobile) {
          margin-top: 10px;
          text-align: center;
          width: 134px;
        }
      }
    }
    
    .warning-text {
      margin-bottom: 10px;
      margin-top: -8px;
      font-size: 14px;
      font-weight: 500;
      color: $color-red;
      line-height: 16px;
      font-family: $font-secondary;
      
      i {
        float: left;
        margin-right: 5px;
        
      }

      @include media-breakpoint-down(lg) {
        margin-top: 10px;
        //font-size: 12px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .optional-cart {
      .op-btns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 12px;
        align-items: center;

        button {
          margin-bottom: 0;
        }
      }
    }
  }
  
  .cart-item {
    .bundle-img-wrp {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 107px;
      position: relative;
      align-self: baseline;

      div {
        flex: 50%;
        flex-grow: 0!important;
        img {
          width: 46px;
          height: 46px;
        }
      }

      &.items-count-3, &.items-count-5, &.items-count-7, &.items-count-6, &.items-count-9 {
        width: 142px;

        > div {
          flex: calc(33% - 1px);
        }
      }
    }
    
    &.bundle {
      .bundle-tag-wrp {
        text-align: right;
        
        .bundle-tag {
          width: 46px;
          height: auto;
          text-align: right;
        }
      }
      
      .cart-product-total {
        margin-top: 0!important;

        .bundle-tag {
          margin-bottom: 7px;
        }
      }

      @include media-breakpoint-down(lg) {
        .product-price {
          .price-old {
            margin-top: 26px;
          }
        }
      }
      
      .additional-info {
        &.items-count-3, &.items-count-5, &.items-count-7, &.items-count-6, &.items-count-9 {
          margin-left: 167px;
        }
      }
    }
  }
  
  @include media-breakpoint-down(lg) {
    .cart-item {
      .details {
        .product-title {
          a {
            -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
          }
        }
      }
      
      .bundle-img-wrp {
        flex-wrap: wrap;
        width: 100px;
        
        gap: 2px;
        > div {
          flex: calc(50% - 1px);
          height: 46px;
          img {
            width: 46px;
            height: 46px;
          }
        }
      }
      
      .bundle-items {
        padding: 30px;
      }
    }
  }
  
  @include media-breakpoint-up(lg) {
    .container-content {
      display: grid;
      grid-template-areas:
      "back-arrow back-arrow"
      "products products"
      "voucher-delivery summary";
      grid-template-columns: 1.14fr 1.15fr;
      gap: 20px;
    }
    
    .voucher-delivery-block {
      display:flex;
      flex-flow: column;
      margin-top: 0;
    }
    
    .product-delivery-benefits {
      padding: 40px;
      margin-top: 20px;
      order: 2;
    }

    .vouchers {
      order: 1;
    }
    
    .cart-summary {
      //align-self: flex-start;
    }

    .cart-item {
      .cart-product-logged-total {
        margin-top: 0;
        font-size: 14px;
        
        img {
          width: 14px;
          height: 14px;
        }
        
        .for-members {
          font-weight: 500;
          display: unset;
        }
      }
      
      .info {
        display: grid;
        grid-template-columns: minmax(0, 3fr) minmax(0, 1.2fr) minmax(0, 1.4fr) minmax(0, 0.8fr);
        gap: 10px;
      }

      .details {
        .product-title {
          font-size: 16px;

          a {
            &:hover {
              text-decoration-line: underline;
            }
          }
        }

        .product-remove {
          align-items: center;
          font-family: $font-secondary;
          font-weight: 600;
          font-size: 12px;
          line-height: 1;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: $color-black;
          transition: all 0.3s ease;

          &:hover {
            color: $color-hover-red;

            span {
              text-decoration-line: none;
            }
          }

          span {
            text-decoration-line: underline;
          }

          i {
            margin-left: 6px;
            font-size: 12px;
          }
        }
      }

      .cart-product-price {
        margin-top: 17px;
        margin-left: 30px;

        .product-price {
          justify-content: flex-end;
          margin-top: 12px;
          font-size: 16px;

          .price-old {
            font-size: 12px;
          }
        }
      }

      .cart-product-total {
        margin-top: 29px;
        text-align: right;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 16px;
      }
      
      .cart-product-actions {
        margin-top: 17px;
        margin-left: 30px;
      }
    }
    
    .optional-cart {
      padding: 15px;

      .op-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;

        h2 {
          margin-bottom: 0;
          font-size: 24px;
        }
      }
      
      .additional-info {
        margin-bottom: 32px;
        justify-content: space-between;

        .cart-product-logged-total {
          margin-top: 0;
          font-size: 14px;

          img {
            width: 14px;
            height: 14px;
          }

          .for-members {
            font-weight: 500;
            display: unset;
          }
        }
      }
      
      .product-card-small {
        .img-wrp {
          margin-right: 24px;

          img {
            width: 107px;
            height: 104px;
          }
        }

        .details {
          //flex-grow: 1;
          display: grid;
          //grid-template-columns: minmax(0, 1.8fr) minmax(0, 0.4fr) minmax(0, 0.4fr) minmax(0, 0.8fr);
          grid-template-columns: minmax(0, 1.2fr) minmax(0, 0.6fr) minmax(0, 0.4fr) minmax(0, 0.6fr);
          gap: 10px;
          

          /*.price {
            align-items: flex-end;
            flex-direction: column;

            .price-old {
              margin-left: 0;
            }
          }*/

          .delivery-term {
            font-family: $font-secondary;
            font-weight: 500;
            color: #AFAFAF;
          }
          
          .cart-product-price {
            margin-top: 12px;
            
            .product-price {
              justify-content: flex-start;
              margin-top: 12px;
              font-size: 16px;

              img {
                height: 16px;
                margin-top: -2px;
                margin-right: 5px;
              }
              
              .price.logged-price {
                color: #E73132;
              }
              
              .price-old {
                font-size: 12px;
              }
            }
          }
          
          .quantity {
            margin-top: 25px;
          }
          
          .cart-product-total {
            margin-top: 25px;
            text-align: right;
            font-family: $font-secondary;
            font-weight: 600;
            font-size: 16px;
          }
        }

        
        .inner-details {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
      }
    }

    .cart-products {
      padding: 30px 40px 20px;

      h1 {
        margin-bottom: 32px;
        font-size: 32px;
      }
    }

    .cart-item {
      .additional-info {
        margin-left: 131px;
      }
      
      .img-wrp:not(.bundle-img-wrp) {
        img {
          width: 107px;
          height: 104px;
        }
      }

      .details {
        margin-left: 24px;
      }
    }
  }

  .remove-voucher {
    margin-left: 15px;
    &.mobile {
      align-items: center;
      justify-content: center;
      padding: 0 30px;
      margin-right: 1px;
      height: 48px;
      background: $color-lighter-grey;

      i {
        margin-right: 4px;
      }
    }

    color: #716B66;
    font-family: $font-secondary;
    line-height: 24px;
    vertical-align: middle;
    text-transform: uppercase;
    i {
      font-size: 14px;
    }

    span {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 2px;
    }

    &:not(.mobile) {
      margin-top: 10px;
      text-align: center;
      width: 134px;
    }
    
    &:hover {
      i {
        color: #716B66;
      }
    }
  }
  
}

.tooltip-inner {
  .delivery-tooltip {
    .tooltip-name {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
    }
    .tooltip-term, .tooltip-disabled {
      font-weight: 500;
      text-align: center;
    }
    .tooltip-disabled {
      color: $color-red;
    }
  }
} 