.modal-content {
  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  .header-title {
    margin-bottom: 30px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    color: $color-black;
  }

  @include media-breakpoint-up(xl) {
    .modal-body {
      padding: 40px 48px;
    }

    .btn-close {
      i {
        font-size: 24px;
      }
    }
  }
}