.customer-loyalty-programme {
  display: grid;
  row-gap: 2px;

  .loyalty-current-info {
    .total-euros {
      margin-top: 25px;
      margin-bottom: 5px;
      font-style: italic;
      font-weight: 400;
      font-size: 42px;
      line-height: 1;
    }

    .total-txt {
      margin-bottom: 0;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-transform: uppercase;
    }

    .loyalty-euros-valid {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px 24px;
      margin-top: 25px;
      margin-bottom: 0;
      background: $color-lighter-grey;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-grey;
    }
  }

  .loyalty-history-item {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-lighter-grey-2;

    &.negative {
      .status {
        i {
          color: $color-red;
        }
      }

      .total {
        color: $color-red;
      }
    }

    .status {
      width: 100%;
      margin-bottom: 5px;
      font-style: italic;
      font-size: 16px;
      line-height: 1.25;

      i {
        margin-right: 12px;
        vertical-align: top;
        font-size: 18px;
        color: $color-green;
      }
    }

    .date {
      width: 50%;
      font-family: $font-secondary;
      color: $color-grey;
    }

    .total {
      width: 50%;
      font-family: $font-secondary;
      font-weight: 600;
      color: $color-green;
      text-align: right;
    }
  }

  @include media-breakpoint-up(md) {
    .loyalty-current-info {
      .total-euros {
        margin-top: 40px;
        margin-bottom: 16px;
        font-size: 72px;
      }

      .loyalty-euros-valid {
        display: block;
        margin-top: 40px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .loyalty-history-item {
      flex-wrap: nowrap;
      padding-top: 24px;
      padding-bottom: 24px;

      .status {
        width: 55%;
        margin-bottom: 0;
      }

      .date {
        width: 30%;
      }

      .total {
        width: 15%;
      }
    }
  }
}