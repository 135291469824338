.eprel-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .label {
    line-height: 0;

    img {
      width: 30px;
      height: auto;
    }
  }

  .pdf {
    margin-left: 6px;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.25;
    color: $color-light-grey;
  }

  @include media-breakpoint-up(lg) {
    .label {
      img {
        width: 37px;
      }
    }

    .pdf {
      font-size: 14px;
    }
  }
  @include media-breakpoint-up(xl) {
    .label {
      img {
        width: 57px;
      }
    }

    .pdf {
      margin-left: 8px;
      //font-size: 16px;
    }
  }
  @include media-breakpoint-up(lg) {
    .pdf {
      &:hover {
        text-decoration-line: underline;
      }
    }
  }
}