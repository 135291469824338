#paymentMethods {
  .payment-option {
    .accordion-header {
      .accordion-button {
        min-height: 54px;

        .indicator {
          position: relative;
          display: block;
          margin-right: 10px;
          width: 25px;
          height: 25px;
          background-color: #eeeeee;
          border-radius: 50%;
        }

        &:not(.collapsed) {
          color: $primary;

          .indicator {
            background-color: $primary;

            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              display: block;
              height: 14px;
              width: 8px;
              border-right: 2px solid $color-white;
              border-bottom: 2px solid $color-white;
              transform: rotate(45deg) translate(-50%, -50%);
              transform-origin: top;
            }
          }
        }
      }
    }

    .accordion-collapse {
      .accordion-body {
        .payment_list {
          .payment_type {
            transition: all .3s;

            &.active {
              border-color: $primary !important;
              box-shadow: 0px 0px 0px 3px $primary;
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &.no-options {
      .accordion-header {
        .accordion-button {
          &:not(.collapsed) {
            box-shadow: none !important;
          }

          &:after {
            display: none;
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          padding: 0px !important;
        }
      }
    }
  }
}

#type_cart {
  background: #f6f6f6;
}