.radio {
  position: relative;
  display: block;
  padding-left: 38px;
  font-family: $font-secondary;
  font-size: 16px;
  line-height: 1.125;
  cursor: pointer;

  input {
    display: none;

    &:checked {
      ~ .radio-text {
        font-weight: 600;
      }

      ~ .box {
        border: 6px solid $color-black;
      }
    }
  }

  .box {
    position: absolute;
    top: -1px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #bebebe;
    border-radius: 100%;
    background: #fff;
  }
}