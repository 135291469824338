.page-breadcrumb {
  &.light {
    ol {
      li {
        &.dots {
          color: rgba($color-white, 0.3);
        }

        i {
          color: rgba($color-white, 0.3);
        }

        a {
          color: rgba($color-white, 0.3);
        }
      }
    }
  }

  ol {
    display: flex;
    flex-wrap: wrap;

    li {
      position: relative;
      padding-left: 14px;
      margin-right: 7px;
      font-family: $font-secondary;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.link-breadcrumb {
        &:not(.visible):not(:last-child) {
          display: none;
        }
      }

      &.dots {
        margin-right: 0;
        padding-right: 7px;
        cursor: pointer;
      }

      i {
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        transform: translateY(-50%);
        font-size: 10px;
      }

      a {
        color: $color-black;
        transition: all 0.3s ease;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    ol {
      li {
        a {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    ol {
      li {
        &.dots {
          display: none;
        }

        &.link-breadcrumb {
          &:not(:last-child) {
            display: inline-block !important;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.light {
      ol {
        li {
          a {
            &:hover {
              opacity: 1;
              color: $color-white;
            }
          }
        }
      }
    }

    ol {
      li {
        i {
          top: calc(50% - 2px);
        }

        a {
          &:hover {
            color: $color-hover-red;
          }
        }
      }
    }
  }
}