#wishlist-listing {
  @include media-breakpoint-up(lg) {
    .header-filter-desktop {
      margin-top: 40px;
    }
  }
  @include media-breakpoint-up(xl) {
    .product-card {
      .btn-wishlistInfo {
        opacity: 1;
      }
    }
  }
}