.delivery-options {
  .radio {
    display: flex;
    align-items: flex-start;
    padding-left: 28px;

    &.disabled {
      cursor: default;

      .radio-text {
        opacity: 0.4;
      }

      .delivery-price {
        opacity: 0.4;
      }

      .icon {
        opacity: 0.2;
      }

      .box {
        opacity: 0.4;
      }
    }

    + .radio {
      margin-top: 30px;
    }

    .icon {
      flex-shrink: 0;
      width: 28px;
      text-align: center;
      line-height: 0;

      &.pickup {
        img {
          width: 25px;
        }
      }

      &.venipak_pickup {
        transform: translateY(-2px);

        img {
          width: 21px;
        }
      }

      &.omniva {
        transform: translateY(-1px);

        img {
          width: 22px;
        }
      }

      &.venipak {
        transform: translateY(-2px);

        img {
          width: 25px;
        }
      }
    }

    .delivery-price {
      display: block;
      margin-left: auto;
      padding-left: 15px;
      font-family: $font-primary;
      font-style: italic;
      font-weight: 600;
      white-space: nowrap;
    }

    .delivery-title {
      font-family: $font-primary;
      font-style: italic;
    }

    .radio-text {
      display: flex;
      flex-direction: column;
      margin-left: 13px;
    }

    .delivery-days {
      margin-top: 5px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: $color-black;
      opacity: 0.9;
    }
    
    
    .carrying {
      &.hidden {
        display: none;
      }
      font-size: 14px;
      font-weight: 400;
      margin-top: 15px;
      .checkbox {
        .box {
          border-radius: 0!important;
        }
      }
    }
  }

  .single-delivery {
    padding-left: 0;
    cursor: default;

    &.disabled {
      .icon {
        width: 24px;   
      }
    }
    
    .delivery-title {
      font-weight: 600;
    }
  }

  @include media-breakpoint-up(lg) {
    .radio {
      padding-left: 36px;

      + .radio {
        margin-top: 25px;
      }

      .icon {
        width: 30px;

        &.pickup {
          transform: translateY(-1px);

          img {
            width: 27px;
          }
        }

        &.venipak_pickup {
          transform: translateY(-3px);

          img {
            width: 22px;
          }
        }

        &.omniva {
          img {
            width: 23px;
          }
        }

        &.venipak {
          transform: translateY(-3px);

          img {
            width: 27px;
          }
        }
      }

      .delivery-days {
        font-size: 14px;
      }
    }

    .single-delivery {
      padding-left: 0;
    }
  }
}

.delivery-options-splitter {
  height: 8px; 
  background: #F6F6F6; 
  margin: 0 -40px;
}

#delivery-info {
  padding-top: 40px;
}

.delivery-prefilled-info, .delivery-pickup-prefilled-info {
  font-family: $font-secondary;
  line-height: 24px;
  background: #F6F6F6;
  padding: 30px;
  
  .link-block {
    margin-top: 20px;
  }
  
  a {
    font-family: $font-primary;
    font-size: 16px;
    font-style: italic;
    color: #252221;
    
    i {
      margin-left: 12px;
    }
    
    .text {
      text-decoration: underline;
    }
  }
}

.delivery-inputs {
  &.delivery-inputs-hidden, &.delivery-pickup-inputs-hidden {
    display: none;
  }
}

.wrp-hidden.no-disable {
  display: none;
}