.product-card-compact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background: $color-white;
  transition: none;

  &.added{
    .add-to-cart{
      display: none;
    }

    .cta-btn.bordered.added-btn{
      display: inline-flex;
    }
  }

  .card-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 20px 16px 16px;
    border: 1px solid $color-lighter-grey-2;
  }

  .cta-btn {
    position: relative;
    width: 100%;
    margin-top: -1px;

    &.bordered {
      border: 1px solid $color-lighter-grey-2;

      &:hover {
        background: transparent;
        color: $color-black;
      }

      &:active {
        background: transparent;
      }

      &.added-btn{
        display: none;
        color: $color-green !important;
      }
    }

    i {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      font-size: 18px;
    }
  }

  .product-card-img {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    line-height: 0;

    img {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  .product-tags {
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .product-brand {
    display: inline-block;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-light-grey;
    transition: color 0.3s ease;
  }

  .product-title {
    flex-shrink: 0;
    height: 40px;
    width: 100%;
    margin-top: 8px;
    overflow: hidden;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;

    a {
      color: $color-grey;
    }
  }

  .product-price {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: auto;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.11;

    .price {
      display: block;
    }

    .price-old {
      display: block;
      font-size: 14px;
      font-weight: 400;
      text-decoration-line: line-through;
      color: $color-light-grey;
    }

    .out-of-stock {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: $color-light-grey;
    }
  }

  .product-price-outlet {
    position: relative;
    z-index: 3;
    margin-top: auto;
    width: 100%;
    line-height: 1.11;

    .price-box {
      font-family: $font-secondary;

      &.outlet {
        margin-bottom: 8px;
        color: $color-red;
      }

      .price {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;

        .outlet-icon {
          margin-top: -2px;
          margin-right: 6px;
          width: 14px;
          height: auto;
        }

        .info-icon {
          margin-top: -2px;
          margin-left: 6px;
          font-size: 12px;
          color: $color-light-grey;
        }
      }
    }
  }

  .notice {
    margin-top: 10px;
    margin-bottom: 0;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: $color-green;

    &.yellow {
      color: $color-yellow;
    }

    &.red {
      color: $color-red;
    }
  }

  @include media-breakpoint-down(lg) {
    a, button {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(lg) {
    .product-price {
      font-size: 18px;

      .price-old {
        font-size: 12px;
      }

      .out-of-stock {
        font-size: 12px;
      }
    }

    .product-price-outlet {
      .price-box {
        .price {
          font-size: 18px;

          .outlet-icon {
            width: 16px;
          }

          .info-icon {
            font-size: 14px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product-title {
      height: 44px;
      font-size: 16px;
      line-height: 1.375;
    }
  }
  @include media-breakpoint-up(lg) {
    &:hover {
      position: relative;
      z-index: 1;

      .card-inner {
        border-color: $color-light-grey;
      }

      .cta-btn {
        color: $color-red;

        &.bordered {
          border: 1px solid $color-red;

          &.added-btn{
            border-color: $color-green !important;
          }

          &:hover {
            color: $color-red;
          }
        }
      }
    }

    .product-brand {
      &:hover {
        text-decoration-line: underline;
      }
    }

    .product-title {
      margin-top: 10px;

      a {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .card-inner {
      height: 354px;
      transition: all 0.3s ease;
    }

    .product-card-img {
      margin-bottom: 18px;
    }

    .product-price {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: auto;

      .price {
        order: 1;
      }

      .price-old {
        order: 2;
        margin-left: 14px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product-price-outlet {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .price-box {
        &.outlet {
          margin-right: 15px;
          margin-bottom: 0;
        }
      }
    }
  }
}