#products-and-filters {
  .filter-scroll-notice {
    position: fixed;
    top: 40%;
    left: 720px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    opacity: 0;
    visibility: hidden;

    i {
      font-size: 32px;
    }

    span {
      display: block;
      margin-top: 15px;
      margin-bottom: 15px;
      font-family: $font-secondary;
      text-align: center;
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    .products-list-wrp {
      width: 75%;

      .products-list {
        margin-top: 0;
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    .products-list-wrp {
      width: 74.5%;
    }
  }
}