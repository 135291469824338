.cart-summary {
  grid-area: summary;
  padding: 24px;
  background: $color-white;

  &.disabled {
    opacity: 0.3;
  }

  .cart-product-logged-total {
    margin-top: 25px;
    text-align: center;
    font-family: $font-secondary;
    
    .text {
      padding-right: 15px;
    }
    
    img {
      height: 14px;
      vertical-align: middle;
      margin-bottom: 3px;
    }
    
    .logged_discount {
      color: $color-red;
      font-weight: 600;
    }
  }
  
  @include media-breakpoint-up(xl) {
    padding: 40px;

    .cta-btn {
      margin-top: 25px;
    }
  }
}

.cart-summary, .registration-start {
  .cta-btn {
    position: relative;
    margin-top: 20px;

    i {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      font-size: 16px;  
      color: $color-white;
    }
  }
}

.logged-price-discount {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 16px;
  background: rgba($color-light-yellow, 0.6);

  p {
    width: 55%;
    margin-bottom: 0;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    a {
      color: $color-black;
      text-decoration-line: underline;
    }
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
    padding-left: 15px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: $color-red;
    white-space: nowrap;

    .outlet-icon {
      flex-shrink: 0;
      margin-right: 6px;
      width: 16px;
      height: auto;
      transform: translateY(-1px);
    }
  }

  @include media-breakpoint-up(xs) {
    p {
      width: 60%;
    }

    .price {
      width: 40%;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 24px;
  }
}