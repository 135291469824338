.product-card-small {
  display: flex;
  align-items: flex-start;

  .img-wrp {
    display: inline-block;
    flex-shrink: 0;
    margin-right: 20px;
    line-height: 0;

    img {
      width: 64px;
      height: 64px;
      object-fit: contain;
      flex-shrink: 0;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .brand {
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-light-grey;
  }

  .name {
    margin: 6px 0 8px;
    font-style: italic;
    letter-spacing: 0.02em;
    color: $color-black;
  }

  .price {
    display: flex;
    align-items: center;
    font-family: $font-secondary;
    font-weight: 600;

    .price-old {
      margin-left: 10px;
      font-size: 10px;
      font-weight: 500;
      text-decoration-line: line-through;
      color: $color-light-grey;
    }
  }

  @include media-breakpoint-down(lg) {
    a {
      &.brand,
      &.name {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }
  }
  @include media-breakpoint-up(lg) {
    a {
      &.brand,
      &.name {
        &:hover {
          text-decoration-line: underline;
        }
      }
    }
  }
}