.template-2-blocks {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .t2b-content {
    order: 2;
    margin-top: 25px;

    h2 {
      margin-bottom: 20px;
      font-family: $font-primary;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.16;
    }
  }

  img {
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;

    &.first-content {
      .t2b-content {
        order: 1;
        padding-left: 0;
        padding-right: 8%;
      }

      img {
        order: 2;
      }
    }

    .t2b-content {
      width: 51%;
      margin-top: 0;
      padding-left: 8%;
    }

    img {
      width: 49%;
      height: 320px;
      object-fit: cover;
    }
  }
  @include media-breakpoint-up(xl) {
    &.first-content {
      .t2b-content {
        padding-right: 9%;
      }
    }

    .t2b-content {
      padding-left: 9%;

      h2 {
        font-size: 32px;
      }
    }

    img {
      height: 472px;
    }
  }
}