#reviews-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px 50px;

  .white-box {
    width: 100%;
    margin-bottom: 8px;
  }

  .reviews-form {
    h1 {
      margin-bottom: 16px;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      text-align: center;
      color: $color-black;
    }

    .general-ratio {
      justify-content: center;
      margin-bottom: 12px;

      i {
        font-size: 16px;
      }

      span {
        font-size: 16px;
      }
    }

    .your-ratio {
      i {
        font-size: 20px;
      }

      span {
        margin-left: 20px;
        font-size: 32px;
      }
    }

    .your-ratio-wrp {
      margin-top: 28px;
    }

    .total-reviews {
      margin-bottom: 0;
      font-family: $font-secondary;
      text-align: center;
    }

    form {
      margin-top: 32px;
    }

    .your-ratio-txt {
      margin-bottom: 2px;
      font-family: $font-secondary;
      font-size: 16px;
    }

    .cta-btn {
      margin-top: 34px;
    }
  }

  .quest-review-item {
    .date {
      display: block;
      font-family: $font-secondary;
      color: $color-grey;
    }

    .rating {
      margin-bottom: 20px;
    }

    .description {
      margin-top: 20px;
      margin-bottom: 12px;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      color: $color-black;

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .author {
      display: block;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: $color-black;
    }
  }

  .show-more-reviews {
    margin-top: 20px;
  }

  @include media-breakpoint-up(md) {
    padding: 56px 24px 80px;

    .white-box {
      width: 600px;
      margin-left: auto;
      margin-right: auto;
    }

    .reviews-form {
      h1 {
        margin-bottom: 12px;
        font-size: 48px;
      }

      .your-ratio-wrp {
        display: flex;
        align-items: center;

        .your-ratio-txt {
          margin-bottom: 0;
        }

        .your-ratio {
          margin-left: 20px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .white-box {
      width: 758px;
    }

    .show-more-reviews {
      margin-top: 48px;
    }
  }
}