@mixin make-grid-col-auto() {
  grid-template-columns: auto;
}

@mixin grid-cols($count) {
  grid-template-columns: repeat($count, minmax(0, 1fr));
}

@mixin make-grid-columns-project($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .grid-cols#{$infix}-#{$i} {
            @include grid-cols($i);
          }
        }
      }
    }
  }
}

@mixin editor-content() {
  font-family: $font-secondary;

  > *:first-child {
    margin-top: 0 !important;
  }
  > *:last-child {
    margin-bottom: 0 !important;
  }

  .block-spacing {
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    text-decoration-line: underline;
    color: $color-black;
    transition: all 0.3s ease;
  }

  table {
    width: 100% !important;
    height: auto !important;

    td {
      padding: 10px;
      border: 1px solid $color-lighter-grey-2;
    }
  }

  ol, ul {
    margin-bottom: 25px;
  }

  h2 {
    margin-bottom: 16px;
    font-family: $font-primary;
    font-style: italic;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.11;
    color: $color-natural-black
  }

  h3 {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.125;
    color: $color-black;
  }

  ol {
    margin-bottom: 15px;
    margin-left: 20px;
    list-style: decimal;

    li {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .palyginimo_lentele_keturi_stulpeliai {
    margin-bottom: 20px;

    > div {
      padding: 10px 0;
    }

    .savybe {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $color-lighter-grey-2;

      &:last-child {
        border-bottom: 0;
      }

      .ikona {
        width: 12%;
      }

      .info {
        width: 88%;
      }
    }

    .mygtukas {
      padding: 0;
      border-bottom: none;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        padding: 0 24px;
        border: 0;
        appearance: none;
        background: $color-red;
        font-family: $font-secondary;
        text-align: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 2px;
        text-decoration-line: none !important;
        text-transform: uppercase;
        color: $color-white;
        cursor: pointer;
        transition: all 0.3s ease;
      }
    }

    .nuotrauka {
      border-bottom: solid 2px $color-red;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    .pavadinimas {
      font-size: 24px;
      text-align: center;
    }
  }

  ul {
    list-style: none;

    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 16px;
      line-height: 1.57;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        position: absolute;
        top: 8px;
        left: 0;
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background: $color-black;
        content: '';
      }
    }
  }

  strong {
    font-weight: 600;
  }

  p {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    a:not(.cta-btn) {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(lg) {
    font-size: 16px;
    line-height: 1.5;

    .mygtukas {
      a {
        &:hover {
          background: $color-hover-red;
        }
      }
    }

    a {
      &:hover {
        text-decoration-line: none;
        color: $color-hover-red;
      }
    }

    h2 {
      margin-bottom: 23px;
      font-size: 32px;
    }
  }
}