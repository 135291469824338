.template-3blocks{
  display: grid;
  row-gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;

  .item{
    >i{
      margin-bottom: 10px;
      font-size: 24px;
    }

    h4{
      margin-bottom: 5px;
      font-size: 16px;
      font-weight: 600;
    }

    p{
      font-family: $font-primary;
      color: $color-grey;
      font-style: italic;
    }
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 40px;

    .item{
      >i{
        margin-bottom: 15px;
        font-size: 30px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    column-gap: 30px;
  }
  @include media-breakpoint-up(xl) {
    column-gap: 50px;
  }
}