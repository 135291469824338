#brands-listing {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .brands-wrp {
    padding-left: 0;
    padding-right: 0;
  }

  .brands-all-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    border-top: 1px solid $color-lighter-grey-2;
    border-bottom: 1px solid $color-lighter-grey-2;
    border-left: 1px solid $color-lighter-grey-2;

    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 15px 17px 24px;
      border-bottom: 1px solid $color-lighter-grey-2;
      border-right: 1px solid $color-lighter-grey-2;
      text-align: center;
      color: $color-black;

      img {
        margin-bottom: 12px;
        width: 104px;
        height: 64px;
        object-fit: contain;
      }

      .desc {
        margin-bottom: 28px;
        font-family: $font-secondary;
        line-height: 1.14;
      }

      .country {
        margin-top: auto;
        margin-bottom: 0;
        font-style: italic;
        line-height: 1.285;
        color: $color-light-grey;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .brands-all-list {
      .item {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }
  }
  @include media-breakpoint-up(md) {
    .container {
      padding-left: 24px;
      padding-right: 24px;
    }

    .brands-wrp {
      padding-left: 24px;
      padding-right: 24px;
    }

    .brands-all-list {
      grid-template-columns: repeat(3, 1fr);
      gap: 48px 40px;
      margin-bottom: 80px;
      border-top: 0;
      border-bottom: 0;
      border-left: 0;

      .item {
        margin-left: 0;
        padding: 0;
        border-bottom: 0;
        border-right: 0;

        .desc {
          margin-bottom: 12px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .brands-all-list {
      grid-template-columns: repeat(4, 1fr);

      .item {
        &:hover {
          img {
            opacity: 1;
          }
        }

        img {
          opacity: 0.75;
          transition: opacity 0.3s ease;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .brands-all-list {
      grid-template-columns: repeat(5, 1fr);
      margin-bottom: 105px;
    }
  }
  @include media-breakpoint-up(xxxl) {
    .brands-all-list {
      grid-template-columns: repeat(7, 1fr);
    }
  }
}