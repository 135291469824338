.checkout-summary-table {
  tr {
    &.total {
      td {
        padding-top: 10px;
        font-weight: 700;
        font-size: 18px;
      }
    }
  }

  td {
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: $font-secondary;

    &:nth-child(2) {
      padding-left: 10px;
      text-align: right;
      white-space: nowrap;
    }
  }

  .price-box {
    color: $color-red;

    .price {
      display: flex;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
      float: right;

      .outlet-icon {
        margin-top: -1px;
        margin-right: 6px;
        width: 16px;
        height: auto;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    td {
      a {
        -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
      }
    }
  }
}

#cart_summary {
  .till_free_delivery {
    font-family: $font-secondary;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 2px solid #EBEBEB;
    margin-bottom: 30px;
    padding-bottom: 10px;

    .filled {
      position: absolute;
      bottom: -2px;
      height: 2px;
      display: block;
      width: 50%;
      background: #1E1E1E;
    }
  }
}