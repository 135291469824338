.template-secure-purchasing {
  .secure-notice {
    font-family: $font-primary;
    font-style: italic;
    text-align: right;
    letter-spacing: 0.02em;
    color: $color-light-grey;

    i {
      margin-right: 10px;
      font-size: 16px;
    }
  }

  .secure-list {
    .secure-item {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid $color-lighter-grey-2;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: 0;
      }

      img {
        margin-bottom: 10px;

        &[width="24"] {
          width: 24px;
        }

        &[width="27"] {
          width: 27px;
        }

        &[width="39"] {
          width: 39px;
        }
      }

      h4 {
        margin-bottom: 5px;
        font-size: 16px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .secure-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-left: -48px;
      margin-right: -48px;
      margin-bottom: -48px;
      border-top: 1px solid $color-lighter-grey-2;

      .secure-item {
        padding: 40px 48px;

        &:first-child {
          padding-top: 40px;
        }

        &:nth-child(odd) {
          border-right: 1px solid $color-lighter-grey-2;
        }

        &:nth-last-child(-n+2) {
          border-bottom: 0;
        }

        h4 {
          margin-bottom: 12px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .secure-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-left: -48px;
      margin-right: -48px;
      margin-bottom: -48px;
      border-top: 1px solid $color-lighter-grey-2;

      .secure-item {
        display: flex;
        align-items: flex-start;

        img {
          flex-shrink: 0;
          margin-bottom: 0;
          margin-right: 22px;
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .secure-list {
      margin-left: -56px;
      margin-right: -56px;
      margin-bottom: -56px;

      .secure-item {
        padding-left: 56px;
        padding-right: 56px;
      }
    }
  }
}