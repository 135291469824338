@use "sass:map";

.cookie_bar {
  box-shadow: 0 1px 17px 0px rgba(0, 0, 0, 0.07);
  
  .container-fluid {
    padding: 0 32px;
    
    > div {
      @include media-breakpoint-up(lg) {
        display: flex;
      }
    }
    
    p {
      flex: 1;
    }
    .agree-all-wrp {
      margin-left: auto;
      display: flex;
      gap: 10px;
      align-items: center;

      @include media-breakpoint-down(lg) {
        display: unset;
        
        a:nth-child(1) {
          margin-bottom: 10px;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 0 20px;
      
      p {
        margin-top: 0px;
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
  position: fixed;
  bottom: 0;
  z-index: 4;
  padding: 8px 0;
  width: 100%;
  background-color: #fff;

  p {
    float: none;
    margin: 28px 40px 17px 12px;
    width: auto;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;

    /*a {
      color: white;
      text-decoration: underline;
      text-transform: lowercase;
    }*/
  }

  .close {
    float: right;
    margin-right: 10px;
    margin-top: 20px;
    width: 28px;
    height: 28px;
    background: url('/public/images/frontend/cookie_bar_close.png') no-repeat center center;
    cursor: pointer;
    opacity: 1;
  }

  @include media-breakpoint-down(lg) {
    height: auto;
    padding: 20px 0;
  }
}

.cookie_bar ~ #footer {
  margin-bottom: 70px;
}

#cookies-form {
  max-width: 500px!important;
  padding: 50px 30px 30px 30px!important;
  
  h3 {
    font-size: 18px;
    font-style: italic;
    margin-bottom: 30px;
    margin-top: -20px;
  }
  
  p {
    margin-top: 10px;
  }
  
  .agree-selected-wrp {
    margin-top: 20px;
  }
}