#customer-authorize:not(.customer-change-password) {
  padding: 48px 24px;

  h1 {
    margin-bottom: 22px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.2;
    color: $color-black;
  }

  .social-logins {
    margin-bottom: 32px;

    a {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      border-radius: 10px;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 1;
      cursor: pointer;

      + a {
        margin-top: 12px;
      }

      &.fb {
        background: #1877F2;
        box-shadow: 0px 19.3393px 35.9159px rgba(0, 0, 0, 0.07);
        color: $color-white;
        font-weight: 600;
      }

      &.google {
        box-shadow: 0px 11px 24px rgba(0, 0, 0, 0.07);
        color: rgba(0, 0, 0, 0.54);
        font-weight: 500;
      }

      img {
        width: 24px;
        height: auto;
        margin-right: 24px;
      }
    }
  }

  .login-notice {
    margin-bottom: 16px;
  }

  .ask-change-form {
    margin-top: 24px;
    margin-bottom: 0;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;

    a {
      text-decoration: underline;
      color: $color-black;
    }
  }

  .checkbox-group {
    margin-top: 24px;
  }

  .back-link {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    font-family: $font-secondary;
    color: $color-black;
    transition: color 0.3s ease;

    i {
      margin-right: 12px;
    }
  }

  .cta-btn {
    margin-top: 32px;

    &.register {
      margin-top: 24px;
    }

    &.remember {
      margin-top: 16px;
    }
  }

  .remind-pass-wrp {
    margin-top: -4px;
    text-align: right;

    .link {
      font-size: 10px;
      letter-spacing: 0.12em;
      color: $color-grey;

      &:after {
        background: $color-grey;
      }
    }
  }

  .loyalty-club-auth-info {
    margin: 48px 0 0;
    padding: 48px 24px;
    background: $color-lighter-grey;

    .loyalty-img {
      width: 136px;
      height: auto;
      margin-bottom: 40px;
    }

    .club-benefit-item {
      margin-bottom: 32px;
      padding-bottom: 32px;
      border-bottom: 1px solid $color-lighter-grey-5;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: 0;
      }

      img {
        height: auto;
      }

      h2 {
        margin-top: 16px;
        margin-bottom: 8px;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 1.33;
        color: $color-black;
      }

      p {
        margin-bottom: 0;
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 1.428;
        letter-spacing: 0.02em;
        color: $color-grey;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .prelogin-image {
      display: none;
    }
  }
  
  @include media-breakpoint-up(md) {
    display: flex;
    max-width: 952px;
    /*min-height: 564px;*/
    margin-left: auto;
    margin-right: auto;

    h1 {
      margin-bottom: 24px;
      font-size: 32px;
    }


    .form-wrapper {
      order: 2;
      width: 50%;
      padding: 48px;
    }

    .loyalty-club-auth-info {
      order: 1;
      width: 50%;
      margin: 0;
      padding: 48px;

      .loyalty-img {
        margin-bottom: 70px;
      }

      h1 {
        margin-bottom: 30px;
      }

      .club-benefit-item {
        display: flex;
        align-items: center;
        margin-bottom: 36px;
        padding-bottom: 0;
        border-bottom: 0;

        h2 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        img {
          flex-shrink: 0;
        }

        .cb-inner {
          margin-left: 24px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .form-wrapper {
      padding: 56px;
    }

    .loyalty-club-auth-info {
      padding: 56px;
    }
  }
  
  .prelogin-image {
    img {
      width: 100%;
      height: auto;
    }
  }
  
}