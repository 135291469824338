.cta-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  border: 0;
  appearance: none;
  background: $color-black;
  font-family: $font-secondary;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 2px;
  text-decoration-line: none !important;
  text-transform: uppercase;
  color: $color-white;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: rgba($color-black, 0.8);
    color: $color-white;
  }

  &:disabled {
    background: rgba($color-black, 0.3);
    cursor: not-allowed;
  }

  &.bordered {
    background: transparent;
    color: $color-black;
    border: 1px solid $color-black;

    &:disabled {
      opacity: 0.3;
    }

    &:hover {
      background: rgba($color-black, 0.8);
      color: $color-white;

      &:disabled {
        background: transparent;
        color: $color-black;
        opacity: 0.3;
      }
    }

    &:active {
      background: $color-natural-black;
    }
  }

  &.red {
    background: $color-red;
    color: $color-white;

    &:hover,
    &:active {
      background: $color-hover-red;

      &:disabled {
        background: rgba($color-black, 0.3);
      }
    }

    &:disabled {
      background: rgba($color-black, 0.3);
    }
  }

  &.white {
    background: $color-white;
    border: 1px solid $color-black;
    color: $color-black;

    &:hover,
    &:active {
      background: $color-lighter-grey;
      
      &:disabled {
        background: rgba($color-black, 0.3);
      }
    }

    &:disabled {
      background: rgba($color-black, 0.3);
    }
  }

  &.grey {
    background: $color-lighter-grey;
    color: $color-black;

    &:hover {
      background: rgba($color-black, 0.8);
      color: $color-white;

      &:disabled {
        background: $color-lighter-grey-2;
        color: $color-light-grey;
      }
    }

    &:active {
      background: $color-natural-black;
      color: $color-white;
    }

    &:disabled {
      background: $color-lighter-grey-2;
      color: $color-light-grey;
    }
  }
}