// Abstracts
@import "theme-variables";
@import "mixins";
@import "vendors";
@import 'typography';

// Layout
@import 'layout/site-header-top';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/checkout-footer';
@import 'layout/main-nav';
@import 'layout/page-block-banners';
@import 'layout/category-banner';
@import 'layout/breadcrumb';
@import 'layout/cart-toggle-block';
@import 'layout/category-header';
@import 'layout/products-and-filters';
@import 'layout/pagination';
@import 'layout/header-filter-mobile';
@import 'layout/header-filter-desktop';
@import 'layout/filters';
@import 'layout/customer-reviews';
@import 'layout/products-detailed';
@import 'layout/product-gallery';
@import 'layout/product-bundles';
@import 'layout/products-add2cart';
@import 'layout/cart';
@import 'layout/modal';
@import 'layout/profile-dropdown';
@import 'layout/quick-search';
@import 'layout/product-tab-description';
@import 'layout/product-tab-availability';
@import 'layout/product-tab-delivery-return';
@import 'layout/product-tab-reviews';
@import 'layout/added2cart';
@import 'layout/buy-together-items';
@import 'layout/cart-detailed';
@import 'layout/cart-summary';
@import 'layout/cart-action-block';
@import 'layout/customer-login-page';
@import 'layout/checkout-form';
@import 'layout/container-content';
@import 'layout/order-status';
@import 'layout/side-nav';
@import 'layout/customer-authorize';
@import 'layout/customer-page';
@import 'layout/customer-order-view';
@import 'layout/customer-order-return';
@import 'layout/customer-notification-settings';
@import 'layout/customer-change-password';
@import 'layout/customer-external-returning-goods';
@import 'layout/customer-account-settings';
@import 'layout/customer-order-history';
@import 'layout/customer-loyalty-programme';
@import 'layout/modal-auth';
@import 'layout/wishlist-listing';
@import 'layout/brands-listing';
@import 'layout/pages-detailed';
@import 'layout/departments-listing';
@import 'layout/pages-help';
@import 'layout/reviews-page';
@import 'layout/feedback-page';
@import "layout/guestbook-page";

// Components
@import 'components/eprel-info';
@import 'components/intlTelInput';
@import 'components/collection-compact';
@import "components/collection";
@import 'components/listing-bottom-description';
@import 'components/template-contacts';
@import 'components/template-secure-purchasing';
@import 'components/template-2-blocks';
@import 'components/template-text-image-bottom';
@import 'components/template-notice';
@import 'components/template-2sides-content';
@import 'components/template-3blocks';
@import 'components/template-2cols-img-left';
@import 'components/subcategories-list';
@import 'components/wishlistInfo';
@import 'components/products-list';
@import 'components/benefits-list';
@import 'components/page-header';
@import 'components/checkout-summary-table';
@import "components/product-card";
@import "components/product-card-compact";
@import "components/product-card-small";
@import "components/product-tags";
@import "components/product-detailed-photos";
@import "components/info-msg";
@import 'components/tooltip';
@import 'components/sorting';
@import 'components/delivery-options';
@import "components/cart-info";
@import "components/cta-btn";
@import "components/link";
@import "components/alert";
@import "components/rating";
@import "components/slider";
@import "components/categories-list";
@import "components/btn-close";
@import "components/page_editor";
@import "components/form-item";
@import "components/bootstrap-select";
@import "components/checkbox";
@import "components/checkbox-switch";
@import "components/checkbox-group";
@import "components/radio";
@import "components/amount-actions";
@import "components/product-list-banner";
@import 'components/review-item';
@import "components/cookie_popup";
@import "components/cookie-bar";
@import "components/modifications-colors"; 
@import "components/product-use-tabs";
@import "components/range_slider";
@import "components/payment-options";
@import "components/payment-bank-options";
@import "components/upload-photos";
@import "components/orders_pay";
@import "components/shop-categories";
@import "components/delivery-icons";
@import "components/guestbook-reviews";

#type_products {
  .fancybox-thumbs-x {
    top: auto;
    width: auto;
    //max-width: 500px;
    //margin: 0 auto;
    bottom: 0;
    left: 0;
    right : 0;
    height: 95px;
    padding: 10px 10px 5px 10px;
    box-sizing: border-box;
    background: transparent;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 0;
    bottom: 95px;
  }
}