.categories-list {
  display: grid;
  gap: 20px 12px;
  grid-template-columns: repeat(3, 1fr);

  .item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .img-wrp {
      position: relative;
      width: 100%;
      display: block;
      margin-bottom: 10px;
      padding-top: 100%;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform-origin: center center;
        will-change: transform;
        transition: all 0.3s ease;
      }
    }

    h2 {
      padding: 0 2px;
      margin: 0;
      font-style: italic;
      font-size: 12px;
      line-height: 1.5;
      text-align: center;
      color: $color-grey;
      transition: color 0.3s ease;
    }
  }

  @include media-breakpoint-up(md) {
    gap: 36px 4px;

    .item {
      h2 {
        padding: 0 5px;
        font-size: 18px;
        line-height: 1.33;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    grid-template-columns: repeat(4, 1fr);

    .item {
      &:hover {
        img {
          transform: scale(1.05) translateZ(0);
        }

        h2 {
          color: $color-black;
        }
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    grid-template-columns: repeat(5, 1fr);
  }
}