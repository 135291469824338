.cart-action-block {
  padding: 27px 24px;
  background: $color-white;

  h2 {
    margin-bottom: 28px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: $color-black;
  }
  
  &.quest {
    padding: 0;
    
    > div {
      padding: 36px 40px 48px;
      background: $color-white;

      @include media-breakpoint-down(md) {
        padding: 40px 24px;
      }
    }
  }

  
}