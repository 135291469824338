#order-status {
  &.return-order {
    .content {
      padding-left: 0;
      padding-right: 0;
      margin: 0 auto;
    }
  }

  .container-content {
    /*display: flex;
    flex-direction: column;*/
  }

  .content {
    /*order: 2;*/
    padding: 40px 24px 50px;
    max-width: 550px;
    margin: 0 auto;
    text-align: center;

    h1 {
      margin-bottom: 20px;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1.5;
      color: $color-black;
    }

    .description {
      margin-bottom: 32px;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 1.375;

      p {
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btns {
      .cta-btn {
        + .cta-btn {
          margin-top: 12px;
        }
      }
    }
  }

  img {
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    .container-content {
      flex-direction: row;
      align-items: flex-start;
      padding-top: 64px;
      padding-bottom: 50px;
    }

    .content {
      /*order: 1;
      width: 52%;
      padding: 3% 78px 0 0;*/

      h1 {
        margin-bottom: 10px;
        font-size: 48px;
      }

      .description {
        margin-bottom: 42px;

        p {
          margin-bottom: 10px;
        }
      }

      .btns {
        display: grid;
        grid-template-columns: 1.15fr 1fr;
        gap: 16px;

        .cta-btn {
          + .cta-btn {
            margin-top: 0;
          }
        }
      }
    }

    img {
      order: 2;
      width: 48%;
    }
  }
}