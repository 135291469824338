#product-tab-availability {
  h3 {
    margin-top: 35px;
    margin-bottom: 15px;
    font-style: italic;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.33;
    color: $color-black;

    &:first-of-type {
      margin-top: 0;
    }
  }

  .shop-location {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color-lighter-grey-2;
    font-family: $font-secondary;

    &.sold {
      color: $color-light-grey;
    }

    .shop-title {
      margin-right: 40px;
    }

    .shop-quantity {
      white-space: nowrap;
    }
  }

  @include media-breakpoint-up(lg) {
    h3 {
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 18px;
    }

    .shop-location {
      padding-bottom: 16px;
      margin-bottom: 16px;
      font-size: 16px;
    }
  }
}