.checkbox-switch {
  display: inline-block;

  input {
    display: none;

    &:checked {
      ~ span {
        background: $color-green;

        &:after {
          left: calc(100% - 26px);
        }
      }
    }
  }

  span {
    position: relative;
    display: block;
    width: 48px;
    height: 28px;
    background: $color-red;
    border-radius: 30px;
    transition: all 0.3s ease;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%) translateZ(0);
      width: 22px;
      height: 22px;
      background: $color-white;
      border-radius: 30px;
      transition: all 0.3s ease;
    }
  }
}