.iti {
  width: 100%;
}

.iti__flag {
  display: none;
  background: none;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background: none;
  }
}

.iti__selected-dial-code {
  margin-left: 0;
}

.iti__flag-box {
  display: none;
}

.iti__selected-flag {
  width: 74px;
}

.iti input, .iti input[type=tel], .iti input[type=text] {
  padding-left: 90px !important;
  padding-right: 16px !important;
}

.iti__selected-flag {
  border-right: 1px solid $color-light-grey;
  font-family: $font-secondary;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.428;
  color: $color-black;
}

.iti--separate-dial-code .iti__selected-flag {
  background-color: $color-lighter-grey;
}

.iti__arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid $color-black;
}

.iti__arrow--up {
  border-top: none;
  border-bottom: 4px solid $color-black;
}