#order-status.review {
  #review-block {
    margin-top: 0;
    padding-top: 0;
    border: none;
  }
}
#review-block {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $color-lighter-grey-2;
  font-family: $font-secondary;
  font-weight: 400;

  h3 {
    font-style: italic;
    font-size: 32px;
    font-family: $font-primary;
    margin-bottom: 25px;

    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }
  }

  font-size: 16px;

  .rating-in-words {
    height: 20px;
    margin: 15px 0 25px 0;
    > div {
      display: none;
      font-size: 24px;
      font-family: $font-primary;
      font-style: italic;
    }
  }

  .has_error {
    .title {
      color: red;
    }
    textarea {
      border-color: red;
    }
  }
  
  .error {
    color: red;
    margin-top: 20px;
    font-size: 14px;

    i {
      margin-right: 5px;
    }
  }
  
  .your-ratio-wrp {
    margin-bottom: 20px;
  }
  
  .your-ratio-txt {
    margin-bottom: 25px;
  }
  
  .textarea-box {
    > div {
      margin-bottom: 10px;
    }
    
    textarea {
      height: 120px;
    }
  }
  
  .align-left {
    text-align: left;
  }

  .shop-review {

    .rating {
      display: block;

      i {
        font-size: 32px;
        &.fa-regular {
          color: #E0E0E0;
          font-weight: 300;
        }
      }
    }
  }
}