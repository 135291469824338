.product-card {
  position: relative;
  flex-shrink: 0;

  .card-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 100%;
    padding: 28px 16px 24px;
    background: $color-white;
  }

  .hidden-notice {
      height: 12px;
      margin-bottom: 20px;
    .inner {
      display: none;
      .notice {
        margin-bottom: 0;
      }
    }
  }
  
  &:hover {
    .hidden-notice {
      .inner {
        display: block;
      }
    }
  }
  
  .additional,
  .product-types {
    display: none;
  }

  .eprel-info {
    //margin-top: 8px;
    //margin-bottom: 16px;
  }

  .notice {
    margin-top: 8px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: $color-green;

    &.yellow {
      color: $color-yellow;
    }

    &.red {
      color: $color-red;
    }
  }

  .add-to-cart-wrp {
    position: relative;
    left: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% + 40px);
    height: 60px;
    border-top: 1px solid $color-lighter-grey-2;

    a {
      display: inline-flex;
      align-items: center;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      text-align: center;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-red;
      cursor: pointer;

      i {
        font-size: 18px;
        margin-left: 11px;
      }
    }
  }

  .btn-wishlistInfo {
    position: absolute;
    top: 11px;
    right: 11px;
    z-index: 4;
    display: inline-block;
    padding: 5px;
    line-height: 0;

    &.active {
      i {
        font-weight: 900;
      }
    }

    i {
      font-size: 18px;
      color: $color-black;
      transition: color 0.3s ease;
    }
  }

  .product-card-img {
    position: relative;
    z-index: 3;
    display: block;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    line-height: 0;

    .bundle-tag {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 56px;
      height: auto;
    }
    
    img {
      width: 146px;
      height: 146px;
      object-fit: contain;
    }
  }

  .product-tags {
    bottom: 0;
    left: 0;
    z-index: 3;
  }

  .product-brand {
    a {
      display: inline-block;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: $color-light-grey;
      transition: color 0.3s ease;
    }
  }

  .product-title {
    position: relative;
    z-index: 3;
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 4px;
    overflow: hidden;
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;

    a {
      color: $color-grey;
    }
  }

  .rating_eprel_block {
    margin-bottom: 8px;
    height: 27px;
    width: 100%;
    display: flex;
    position: relative;
  
    .rating {
      /*height: 27px;
      display: block;
      margin-top: 5px;
      position: relative;
      
      .stars {
        display:block;
        float:left;
        margin: 0;
        width:80px;
        height:12px;
        position:relative;
        background:url('/images/frontend/stars2.png') no-repeat top left;
        background-size: contain;
      }
  
      .stars-filled-block {
        width: 100%;
        overflow: hidden;
        height: 12px;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: 0;
  
        .stars-filled {
          width:80px;
          height:12px;
          opacity: 1;
          text-indent:-9999px;
          display:block;
          background:url('/images/frontend/stars2_filled.png') no-repeat top left;
          background-size: contain;
          margin: 0;
        }
      }
  */
      a {
        display: inline-block;
        margin-left: 8px;
        color: $color-grey;
        text-decoration: underline;
        transition: color 0.3s ease;
        vertical-align: top;
      }
    }
    @include media-breakpoint-up(xl) {
      &.has-reviews {
        justify-content: space-between;
      }
    }
    @include media-breakpoint-down(xl) {
      flex-direction: column;
      height: 50px;
      justify-content: center;
      
      .rating {
        margin-top: 0;
        height: 20px;
      }
      .eprel-info {
        height: 20px;
      }
    }
  }
  .product-price-outlet {
    position: relative;
    z-index: 3;
    margin-top: auto;
    width: 100%;
    line-height: 1.11;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    .price-box {
      font-family: $font-secondary;

      &.outlet {
        margin-right: 12px;
        margin-bottom: 0;
        color: $color-red;
      }

      .price {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 1;

        .outlet-icon {
          margin-top: -2px;
          margin-right: 6px;
          width: 14px;
          height: auto;
        }

        .info-icon {
          margin-top: -2px;
          margin-left: 6px;
          font-size: 12px;
          color: $color-light-grey;
        }
      }
    }
  }

  .product-price {
    position: relative;
    z-index: 3;
    display: flex;
    //margin-top: auto;
    font-family: $font-secondary;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.11;

    .price {
      order: 1;
      margin-right: 10px;
    }

    .price-old {
      font-size: 12px;
      font-weight: 500;
      text-decoration-line: line-through;
      color: $color-light-grey;
      order: 2;
      margin-top: 2px;
    }

    .out-of-stock {
      display: block;
      font-size: 14px;
      font-weight: 400;
      color: $color-light-grey;
    }
  }

  @include media-breakpoint-down(lg) {
    a {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(lg) {
    .product-price {
      font-size: 18px;

      .price-old {
        font-size: 12px;
      }

      .out-of-stock {
        font-size: 12px;
      }
    }

    .product-price-outlet {
      .price-box {
        .price {
          font-size: 18px;

          .outlet-icon {
            width: 16px;
          }

          .info-icon {
            font-size: 14px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    .product-card-img {
      img {
        width: 200px;
        height: 200px;
      }
    }

    .product-title {
      height: 44px;
      font-size: 16px;
      line-height: 1.375;
    }
  }
  @include media-breakpoint-up(lg) {
    .product-price-outlet {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .price-box {
        &.outlet {
          margin-right: 22px;
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    height: 425px;

    .card-inner {
      padding: 0;
    }

    &:hover {
      z-index: 10;

      .card-inner {
        position: absolute;
        top: -20px;
        left: -20px;
        right: -20px;
        z-index: 3;
        display: flex;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.1));
      }

      &.has-types {
        .product-types {
          display: flex;
        }
      }

      .btn-wishlistInfo {
        opacity: 1;
      }

      .additional {
        z-index: 4;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 75px;
        width: 100%;
        padding-top: 8px;
        background: $color-white;

        &.out-of-stock {
          justify-content: flex-start;
        }
      }
    }

    .eprel-info {
      //margin-bottom: 20px;
    }

    .product-types {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 4;
      display: none;
      flex-direction: column;
      height: 100%;
      padding: 8px;
      background: $color-white;
      border-right: 1px solid $color-lighter-grey-2;
      line-height: 0;
      transform: translateX(-100%);

      a {
        position: relative;
        display: block;

        &.active {
          border: 1px solid $color-lighter-grey-2;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: $color-red;
          }
        }

        + a {
          margin-top: 8px;
        }

        img {
          width: 48px;
          height: 48px;
          object-fit: contain;
        }
      }
    }

    .btn-wishlistInfo {
      top: 10px;
      right: 10px;
      padding: 10px;
      opacity: 0;

      &:hover {
        i {
          color: $color-hover-red;
        }
      }
    }

    .product-brand {
      a {
        &:hover {
          color: $color-hover-red;
        }
      }
    }

    .product-title {
      margin-top: 10px;
      margin-bottom: 8px;

      a {
        &:hover {
          color: $color-hover-red;
        }
      }
    }

    .product-card-img {
      img {
        width: 250px;
        height: 250px;
      }
    }
  .rating_eprel_block {
    margin-bottom: 15px;
    .rating {
      a {
        &:hover {
          color: $color-hover-red;
        }
      }
    }
  }

    .product-price {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: auto;

      .price {
        margin-right: 16px;
      }
      
      .price-old {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .add-to-cart {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      i {
        position: static !important;
        transform: none !important;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    height: 475px;

    .product-card-img {
      img {
        width: 300px;
        height: 300px;
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    height: 572px;

    .product-card-img {
      img {
        width: 396px;
        height: 396px;
      }
    }
  }
}