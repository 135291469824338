.share-cart-block {
  display: flex;
  font-family: $font-secondary;
  padding-top: 20px;
  
  .share-title {
    padding-right: 15px;
    line-height: 40px;
    vertical-align: middle;
  }
  
  .copy-link {
    height: 40px;
    padding: 0;
    color: $color-grey!important;
    background-color: transparent!important;
    
    &:hover {
     background-color: transparent!important;
      color: $color-grey!important;
    }

    .icon {
      flex-shrink: 0;
      margin-left: 8px;
      width: 13px;
      height: 16px;
      mask-image: url('../../../images/frontend/copy.svg');
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: $color-grey!important;
      transition: all 0.3s ease;
    }

    .text {
      text-decoration-line: underline;
    }
  }
}

.cart-toggle-block {
  .inner-block {
    padding: 27px 24px;
    border-bottom: 1px solid $color-lighter-grey-2;
    background: $color-white;
  }

  &.vouchers {
    grid-area: vouchers;
    
    .bundle-msg {
      font-family: $font-secondary;
      color: $color-grey;
      margin-top: 10px;
      font-weight: 500;
    }
  }

  &.copy-link {
    grid-area: copy-link;
  }

  h3 {
    margin-bottom: 0;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $color-black;
  }

  .content {
    padding-top: 12px;
  }

  .used-vouchers {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;

    p {
      margin-bottom: 8px;
      font-family: $font-secondary;
      text-transform: uppercase;
      color: $color-black;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        margin-right: 8px;
        padding: 0;
        border: 0;
        background: none;

        &:hover {
          i {
            color: $color-red;
          }
        }

        i {
          font-size: 12px;
          color: $color-grey;
          transition: color 0.3s ease;
        }
      }
    }
  }

  .copy-link {
    display: flex;
    height: 40px;
    padding: 0;
    color: $color-black;

    .icon {
      flex-shrink: 0;
      margin-right: 8px;
      width: 13px;
      height: 16px;
      mask-image: url('../../../images/frontend/copy.svg');
      mask-size: contain;
      mask-position: center;
      mask-repeat: no-repeat;
      background-color: $color-black;
      transition: all 0.3s ease;
    }

    .text {
      text-decoration-line: underline;
    }
  }

  form {
    position: relative;

    input {
      padding: 0 95px 0 20px;
      border: 1px solid transparent;
      background: $color-lighter-grey;
    }

    button {
      position: absolute;
      top: 50%;
      right: 20px;
      padding: 5px;
      border: 0;
      transform: translateY(-50%);
      background: none;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
  }

  @include media-breakpoint-up(lg) {
    h3 {
      i {
        display: none !important;
      }
    }

    form {
      button {
        transition: all 0.3s ease;

        &:hover {
          color: $color-hover-red;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .inner-block {
      padding: 32px 40px;
    }

    .copy-link {
      &:hover,
      &:active {
        .icon {
          background-color: $color-white;
        }
      }
    }
  }
}