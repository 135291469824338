.customer-notification-settings {
  display: grid;
  row-gap: 2px;

  h1 {
    margin-bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .content {
      margin-right: 30px;
    }

    h2 {
      margin-bottom: 5px;
      font-style: italic;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.33;
      color: $color-black;
    }

    p {
      margin-bottom: 0;
      color: $color-grey;
    }
  }

  @include media-breakpoint-up(md) {
    h1 {
      padding-top: 43px;
      padding-bottom: 43px;
    }
  }
  @include media-breakpoint-up(lg) {
    .btn-wrp {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .switch-container {
      padding-top: 32px;
      padding-bottom: 32px;
    }
  }
}