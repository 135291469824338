#product-tab-description {
  .disclaimer {
    margin-top: 50px;
  }

  @include media-breakpoint-up(lg) {
    .disclaimer {
      margin-top: 80px;
    }
  }
}