#customer-order-view {
  padding-top: 16px;
  padding-bottom: 40px;

  .page-breadcrumb {
    margin-bottom: 24px;
  }

  h1 {
    margin-bottom: 6px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: $color-black;
  }

  .order-status {
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
    font-style: italic;
    font-size: 18px;
    line-height: 1.33;

    img {
      margin-right: 8px;
    }

    i {
      margin-right: 8px;
      font-size: 18px;
    }
  }

  .customer-order-actions {
    padding: 32px 20px;
    margin-bottom: 8px;
    background: $color-white;

    .cta-btn {
      + .cta-btn {
        margin-top: 8px;
      }
    }
  }

  .order-details {
    display: grid;
    grid-row-gap: 1px;
  }

  .info-for-payment {
    padding: 20px;
    margin-bottom: 40px;
    background: rgba($color-light-yellow, 0.6);

    h2 {
      margin-bottom: 20px;
      font-style: italic;
      font-weight: 400;
      font-size: 20px;
      line-height: 1;
      color: $color-black;
    }

    .payment-details {
      margin-bottom: 20px;
      font-family: $font-secondary;

      span {
        display: block;
        margin-top: 15px;
        font-weight: 600;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    p {
      position: relative;
      margin-bottom: 0;
      padding-left: 25px;
      font-style: italic;
      font-weight: 700;
      line-height: 1.375;

      i {
        position: absolute;
        top: 1px;
        left: 0;
        font-size: 16px;
      }
    }
  }

  .order-info {
    padding: 32px 20px 40px;
    background: $color-white;

    .info-item {
      margin-bottom: 34px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-bottom: 12px;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: $color-black;
    }

    p {
      margin-bottom: 12px;
      font-family: $font-secondary;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .order-products {
    padding: 32px 20px 40px;
    background: $color-white;

    h3 {
      margin-bottom: 12px;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: $color-black;
    }
  }

  #orders_pay {
    padding: 32px 20px 40px;
    background: $color-white;

    h2 {
      margin-bottom: 12px;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.5;
      color: $color-black;
    }

    .payment-bank-options {
      padding-right: 0;
    }

    [type=submit] {
      width: 100%;
      margin-top: 28px;
    }
  }

  .checkout-summary-table {
    width: 100%;
    margin-top: 32px;
  }

  .product-card-small {
    margin-bottom: 32px;

    &:last-of-type {
      margin-bottom: 0;
    }

    .review-item-block {
      margin-top: 15px;
      font-family: $font-secondary;

      span {
        color: $color-green;
      }
      a {
        color: #000;
        text-transform: uppercase;
        text-decoration: underline;
        letter-spacing: 2px;
        font-size: 12px;
        font-weight: 600;
      }
    }
    
    .img-wrp {
      margin-right: 12px;

      img {
        width: 87px;
        height: 84px;
      }
    }

    .details {
      flex-grow: 1;
    }

    .details-inner {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 8px;
    }

    .name {
      display: block;
      width: 100%;
      margin-top: 0;
      margin-bottom: 16px;
      color: $color-grey;
    }

    .price {
      flex-direction: column;
      align-items: flex-start;

      .price-old {
        margin-left: 0;
      }
    }

    .quantity {
      font-family: $font-secondary;
    }
    
    .returned-text {
      font-family: $font-secondary;
      font-size: 12px;
      color: $color-red;
      font-weight: 500;
      margin-top: 10px;
    }
  }

  @include media-breakpoint-up(md) {
    .info-list {
      display: grid;
      gap: 32px 44px;
      grid-template-columns: repeat(2, 1fr);

      .info-item {
        margin-bottom: 0;
      }
    }

    .info-for-payment {
      .payment-details {
        display: grid;
        gap: 9px 32px;
        grid-template-columns: 1fr 2fr;

        span {
          margin-top: 0;
        }
      }
    }

    .checkout-summary-table {
      width: 54%;
      margin-top: 40px;
      margin-left: auto;
    }

    .product-card-small {
      align-items: center;

      .img-wrp {
        margin-right: 20px;

        img {
          width: 72px;
          height: 72px;
        }
      }

      .name {
        margin-bottom: 0;
      }

      .details-inner {
        display: grid;
        align-items: flex-start;
        column-gap: 30px;
        grid-template-columns: 3fr 65px 1fr;
        margin-top: 6px;
        width: 100%;
      }

      .title {
        order: 1;
      }

      .quantity {
        order: 2
      }

      .price {
        order: 3;
        align-items: flex-end;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 24px;
    padding-bottom: 80px;

    .container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .inner-wrp {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1288px;

      &.no-actions {
        display: block;

        .customer-order-actions {
          display: none;
        }

        .order-details {
          min-width: 756px;
        }
      }
    }

    .checkout-summary-table {
      margin-top: 50px;
    }

    .info-for-payment {
      h2 {
        margin-bottom: 32px;
        font-size: 32px;
      }

      .payment-details {
        margin-bottom: 45px;
        font-size: 16px;

        span {
          font-weight: 400;
        }
      }

      p {
        padding-left: 32px;
        font-size: 16px;
      }
    }

    .customer-order-actions {
      order: 2;
      width: 34%;
      max-width: 440px;
      margin-bottom: 0;
    }

    .page-breadcrumb {
      width: 100%;
      margin-bottom: 26px;
    }

    .product-card-small {
      .details-inner {
        grid-template-columns: 3fr 50px 1.1fr;
        column-gap: 30px;
      }
    }

    .order-status-wrp {
      display: grid;
      column-gap: 44px;
      grid-template-columns: repeat(2, 1fr);
      padding: 32px 20px;
      background: $color-white;

      h3 {
        margin-bottom: 0;
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.5;
        color: $color-black;
      }

      .order-status {
        margin-bottom: 0;
      }
    }

    h1 {
      width: 100%;
      margin-bottom: 38px;
      font-size: 38px;
      line-height: 1;
    }

    .order-details {
      order: 1;
      grid-row-gap: 2px;
      width: 58.5%;
      max-width: 756px;
    }
  }
  @include media-breakpoint-up(xl) {
    h1 {
      margin-bottom: 48px;
      font-size: 48px;
    }

    .order-info {
      padding: 48px 56px 56px;
    }

    .order-products {
      padding: 38px 56px 48px;
    }

    #orders_pay {
      padding: 38px 56px 48px;
    }

    .order-status-wrp {
      padding: 32px 56px;
    }

    .product-card-small {
      .details-inner {
        grid-template-columns: 4fr 50px 1fr;
        column-gap: 50px;
      }
    }

    .info-for-payment {
      padding: 48px 65px;
    }

    .customer-order-actions {
      padding: 44px 50px;
    }
  }
}