#feedback-page {
  padding-top: 32px;
  padding-bottom: 50px;

  @include media-breakpoint-down(lg) {
    padding: 0;

    .container {
      padding: 0;
    }
  }
  
  h1 {
    margin-bottom: 25px;
    font-style: italic;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.5;
    color: $color-black;

    @include media-breakpoint-down(lg) {
      font-size: 32px;
    }
  }
  

  .header-block {
    padding: 55px;
    background: #fff;
    text-align: center;
    margin-bottom: 15px;

    @include media-breakpoint-down(lg) {
      padding: 55px 24px;
      margin-bottom: 1px;
    }
    
    p {
      max-width: 420px;
      margin: 0 auto;
      font-family: 'Verdana';
    }
  }
  
  h2 {
    margin-bottom: 20px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.33;
    color: $color-black;
  }

  #feedback-form {
    display: grid;
    grid-row-gap: 1px;
  }

  .questions {
    padding: 32px 20px 40px;
    background: $color-white;
  }

  .feedback-notice-box {
    padding: 32px 20px 40px;
    background: $color-white;
  }

  .products-feedback-wrp {
    .header-block {
      
    }
  }

  .submit {
    margin-top: 20px;
    background: $color-white;
  }

  .questions-group {
    margin-bottom: 35px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .question {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 10px;
      font-family: $font-secondary;
      font-size: 14px;
      line-height: 1.428;
      font-weight: 400;
      color: $color-black;
    }
  }

  .answers {
    display: grid;
    gap: 10px;
  }

  .answer {
    display: block;
    cursor: pointer;

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
      padding: 0 5px;
      border: 0;
      appearance: none;
      background: $color-black;
      font-family: $font-secondary;
      text-align: center;
      font-weight: 600;
      font-size: 10px;
      line-height: 1.2;
      letter-spacing: 2px;
      text-decoration-line: none !important;
      text-transform: uppercase;
      color: $color-white;
      transition: all 0.3s ease;
    }

    input {
      display: none;

      &:checked {
        ~ span {
          background: $color-red;
          color: $color-white;
        }
      }
    }
  }

  .product-feedback {
    .has_error {
      textarea {
        border-color: red;
      }
    }
    
    .error {
      font-family: $font-secondary;
      color: red;
      margin-top: 20px;
      font-size: 14px;

      i {
        margin-right: 5px;
      }
    }
    
    margin-bottom: 15px;
    padding: 45px 55px 55px 55px;
    background: #fff;
    
    .product-review-flex {
      display: flex;
      align-items: flex-start;

      @include media-breakpoint-down(lg) {
        display: block;
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 24px;
      margin-bottom: 1px;
    }
    
    .review-header {
      font-size: 32px;
      font-style: italic;
      margin-bottom: 30px;
    }
    
    .product-info {
      display: flex;
      align-items: center;
      
      

      .img-wrp {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        line-height: 0;

        img {
          width: 100px;
          height: 100px;
          object-fit: contain;
          
          @include media-breakpoint-down(lg) {
            width: 84px;
            height: 84px;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        display: block;
        float: left;
        padding-right: 10px;
      }
    }

    .product-feedback-review {
      flex: 1;

      .details {
        flex-grow: 1;

        .product-brand {
          display: inline-block;
          font-family: $font-secondary;
          font-weight: 600;
          font-size: 12px;
          line-height: 1.5;
          letter-spacing: 2px;
          text-transform: uppercase;
          color: $color-light-grey;
          transition: color 0.3s ease;
        }

        .product-title {
          margin-top: 8px;
          margin-bottom: 15px;
          font-style: italic;
          font-weight: 400;
          font-size: 16px;
          line-height: 1.428;

          a {
            color: $color-grey;
          }
        }
      }
    }
    
    .reviewed {
      font-family: $font-secondary;
      line-height: 20px;
      margin-top: 30px;
      
      .review {
        margin-bottom: 30px;
      }
      
      .thank-you-text {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 2px;
        color: #219653;
        
        i {
          font-weight: 400;
          margin-left: 5px;
        }
      }
    }
    
    .your-ratio-wrp {
      .your-ratio-txt {
        margin-bottom: 2px;
        font-family: $font-secondary;
        font-size: 14px;
      }

      .your-ratio {
        i {
          font-size: 25px;
        }

        span {
          margin-left: 20px;
          font-size: 32px;
        }
      }
    }

    .textarea-box {
      margin-top: 30px;
      width: 100%;

      p {
        margin-bottom: 5px;
        font-family: $font-secondary;
      }

      textarea {
        height: 90px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .your-ratio-wrp {
      align-items: center;

      .your-ratio-txt {
        margin-bottom: 0;
      }

      .your-ratio {
        
      }
    }

    .product-feedback {
      .product-info {
        padding-right: 30px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 38px;
    padding-bottom: 80px;

    .container {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }

    .feedback-box {
      width: 756px;
    }

    h1 {
      width: 100%;
      margin-bottom: 38px;
      font-size: 38px;
      line-height: 1;
    }

    .answers {
      grid-auto-flow: column;
    }
  }
  @include media-breakpoint-up(xl) {
    padding-top: 48px;

    h1 {
      margin-bottom: 48px;
      font-size: 48px;
    }

    .questions {
      padding: 38px 56px 48px;
    }

    .feedback-notice-box {
      padding: 38px 56px 48px;
    }
  }
  
  .rating {
    @include media-breakpoint-down(lg) {
      justify-content: center;
      width: 100%;
      margin-top: 20px;
      
      i {
        font-size: 32px;
        
        + i {
          padding-left: 24px;
        }
      }
    }
  }
}