// FOR COLORS DO NOT OVERRIDE $theme-colors ARRAY
// OVERRIDE VARIABLES cartzilla/_variables.scss 28-36 lines

$font-primary: 'PTSerifPro-Book';
$font-secondary: 'Avenir Next';

$border-radius: 0rem !default;

$color-white: #FFFFFF;
$color-grey: #716B66;
$color-light-grey: #AFAFAF;
$color-lighter-grey: #F6F6F6;
$color-lighter-grey-2: #EBEBEB;
$color-lighter-grey-3: #F9F9FB;
$color-lighter-grey-4: #F4F4F4;
$color-lighter-grey-5: #D9D9D9;
$color-lighter-grey-6: #FAFAFA;
$color-lighter-grey-7: #EEEEEE;
$color-lighter-grey-8: #dcdcdc;
$color-red: #E73132;
$color-red2: #FAEBEB;
$color-black: #1E1E1E;
$color-natural-black: #000000;
$color-light-yellow: #F0F1C7;
$color-yellow: #F2994A;
$color-green: #219653;

$color-hover-red: #C7191A;

$font-size-base: 1rem;
$line-height-base: 1.5;

$icons-font-path: '../../../public/fonts/cartzilla';

$enable-grid-classes: true !default;

$grid-gutter-width: 16px !default;

$grid-breakpoints: (
        xss: 0,
        xs: 360px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1810px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1338px,
        xxxl: 1722px
);

// lightGallery
$lg-path-fonts: '../../fonts/lg' !default;

// Tooltips

// scss-docs-start tooltip-variables
$tooltip-border-radius: 0;
$tooltip-opacity: 1;
$tooltip-margin: 0;
$tooltip-arrow-width: 22px;
$tooltip-arrow-height: 12px;
$tooltip-arrow-color: $color-white;
// scss-docs-end tooltip-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding: 48px 24px;

$modal-footer-margin-between: 0;

$modal-dialog-margin: 0;
$modal-dialog-margin-y-sm-up: 0;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $color-white;
$modal-content-border-color: $color-black;
$modal-content-border-width: 0;
$modal-content-border-radius: 0;
$modal-content-inner-border-radius: 0;
$modal-content-box-shadow-xs: none;
$modal-content-box-shadow-sm-up: none;

$modal-backdrop-bg: $color-black;
$modal-backdrop-opacity: .7;
$modal-header-border-color: $color-black;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: 0;
$modal-header-padding-x: 0;
$modal-header-padding: 0; // Keep this for backwards compatibility

$modal-sm: 300px;
$modal-md: 672px;
$modal-lg: 828px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(1.02);
// scss-docs-end modal-variables