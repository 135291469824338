.products-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: -24px;
  margin-right: -24px;
  border-left: 1px solid $color-lighter-grey-2;

  .product-card {
    border-bottom: 1px solid $color-lighter-grey-2;
    border-right: 1px solid $color-lighter-grey-2;
  }

  .products-list-alert-wrp {
    grid-column: span 2;
    padding-left: 24px;
    padding-right: 24px;
  }

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(3, 1fr);
    margin-left: 0;
    margin-right: 0;
    border-left: 0;

    &.list-4 {
      grid-template-columns: repeat(4, 1fr);
    }

    .products-list-alert-wrp {
      grid-column: span 3;
      padding-left: 0;
      padding-right: 0;
    }

    .product-card {
      border-bottom: 0;
      border-right: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 40px;

    .product-card {
      margin-bottom: 40px;
    }
  }
  @include media-breakpoint-up(xl) {
    gap: 40px 30px;

    .product-card {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(xxxl) {
    gap: 70px 30px;
  }
}