.tooltip {
  pointer-events: none;

  &.colors-tooltip {
    &.bs-tooltip-end,
    &.bs-tooltip-start {
      .tooltip-arrow {
        top: 50% !important;
        transform: translate3d(0px, -50%, 0px) !important;
      }
    }

    .tooltip-inner {
      padding: 6px 8px;
      border: 0;
    }
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 12px 14px;
  font-size: 12px;
  line-height: 1.428;
  font-weight: 400;
  text-align: left;
  font-family: $font-secondary;
  color: $color-black;
  background-color: $color-white;
  box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

  @include media-breakpoint-up(md) {
    max-width: 326px;
    padding: 16px 20px;
    font-size: 14px;
  }
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  top: 50% !important;
  transform: translate3d(0, -50%, 0) !important;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  top: 50% !important;
  transform: translate3d(0, -50%, 0) !important;
}