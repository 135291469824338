.checkbox {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-left: 32px;
  font-family: $font-secondary;
  cursor: pointer;

  /*&.grey {
    .box {
      background: $color-lighter-grey-2;
    }
  }*/
    
  &.checkbox-collapse {
    &[aria-expanded="true"] {
      .box {
        background: $color-black;

        i {
          color: $color-white;
        }
      }
    }
  }

  &:not(.checkbox-collapse) {
    input {
      &:checked {
        ~ .box {
          background: $color-black;

          i {
            color: $color-white;
          }
        }
      }
    }
  }

    

  &.checkbox-collapse {
    &[aria-expanded="true"] {
      .box {
        i {
          display: block;
        }
      }
    }
  }

  &:not(.checkbox-collapse) {
    input {
      &:checked {
        ~ .box {
          i {
            display: block;
          }
        }
      }
    }
  }

  input {
    display: none;
  }

  a {
    color: $color-black;
    text-decoration-line: underline;
  }

  .box {
    position: absolute;
    top: 1px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #bebebe;
    //border-radius: 3px;
    background: $color-white;

    i {
      display: none;
      font-size: 12px;
      color: #fff;
    }
  }

  &.item-has-error {
    .box {
      border-color: $color-red;
    }
  }
  
  @include media-breakpoint-down(lg) {
    a {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(lg) {
    a {
      &:hover {
        text-decoration-line: none;
      }
    }
  }
}