.quick-search-wrp {
  .quick-search-form {
    width: 100%;
    position: relative;

    input {
      width: 100%;
      height: 40px;
      padding: 0 16px 0 44px;
      border: 0;
      background-color: $color-lighter-grey;
      font-family: $font-secondary;
      color: $color-black;
    }

    i {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      font-size: 16px;
      color: $color-black;
    }
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    margin-left: 45px;

    .quick-search-form {
      width: 340px;
    }
  }
  @include media-breakpoint-up(xxl) {
    margin-left: 74px;

    .quick-search-form {
      width: 440px;
    }
  }
}

#search-suggestions {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 48px 24px 70px;
  background-color: $color-white;
  z-index: $zindex-fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  #search-suggestions-results {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);
    overflow: auto;
  }

  p {
    font-family: $font-secondary;
  }

  .part {
    order: 3;

    &.products {
      order: 2;
    }

    &.product_categories {
      order: 1;
    }
  }

  .quick-search-form {
    margin-bottom: 20px;
  }

  .title {
    margin-bottom: 24px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: $color-black;
  }

  .view-all {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    background-color: $color-white;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 24px;
      width: calc(100% - 48px);
      height: 1px;
      background-color: $color-lighter-grey-2;
    }
  }

  .search-type-title {
    margin-bottom: 0;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: $color-grey;
  }

  .results {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $color-lighter-grey-2;
  }

  .search-product_categories {
    margin-top: 20px;

    div {
      + div {
        margin-top: 7px;
      }

      a {
        font-family: $font-secondary;
        color: $color-black;
      }
    }
  }

  .search-products {
    margin-top: 10px;
    padding-bottom: 0;
    border-bottom: 0;

    .product-card-small {
      + .product-card-small {
        margin-top: 24px;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .search-product_categories {
      div {
        a {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 100%;
    width: 650px;
    height: auto;
    max-height: 510px;
    padding: 0;
    overflow: auto;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

    p {
      display: flex;
      align-items: center;
      height: 40px;
      padding-left: 32px;
      padding-right: 32px;
      margin-bottom: 0;
    }

    .view-all {
      position: static;
      border-top: 1px solid $color-lighter-grey-2;
      padding-top: 16px;
      padding-bottom: 16px;
    }

    #search-suggestions-results {
      height: auto;
      min-height: 100%;
      flex-direction: row;
      padding-right: 32px;
      overflow: visible;
    }

    .results {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    .part {
      padding-top: 24px;

      &.products {
        width: 70%;
        order: 1;
        border-right: 1px solid $color-lighter-grey-2;

        .search-type-title {
          padding-left: 32px;
          padding-right: 32px;
        }
      }

      &.product_categories {
        width: 30%;
        order: 2;
        padding-left: 22px;
      }
    }

    .search-product_categories {
      margin-top: 16px;

      div {
        a {
          &:hover {
            color: $color-hover-red;
          }
        }
      }
    }

    .search-products {
      margin-top: 16px;

      .product-card-small {
        padding-left: 32px;
        padding-right: 32px;

        + .product-card-small {
          margin-top: 8px;
        }

        .img-wrp {
          img {
            width: 88px;
            height: 88px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    width: 738px;
  }
}