.payment-options {
  display: grid;
  row-gap: 22px;

  .payment-option {
    order: 1;
    width: 100%;

    &.disabled {
      order: 2;
      color: rgba($color-black, 0.4);
      //pointer-events: none;

      .box {
        background: rgba($color-lighter-grey-2, 0.4);
      }
    }
    
    .payment-title {
      font-family: $font-primary;
      font-style: italic;
    }
  }

  .payment-option-content {
    padding-left: 38px;
    font-family: $font-secondary;
    color: $color-black;

    .inner {
      padding-top: 4px;
    }

    p {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}