.main-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-fixed;
  width: 100%;
  height: 100%;
  background: $color-white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.1s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .container {
    height: 100%;
    padding: 80px 40px 65px;
    overflow: auto;
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  .menu {
    > li {
      margin-top: 23px;

      &:first-child {
        margin-bottom: 0;
      }

      > a {
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 15px;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        color: $color-black;

        &.title-link {
          font-weight: 600;
        }

        &.outlet {
          //color: $color-red;
          .icon-outlet {
            margin-left: 3px;
          }
        }

        i {
          position: absolute;
          top: 50%;
          right: 0;
          z-index: 1;
          transform: translateY(-50%);
          font-size: 20px;
        }

        .icon-discount {
          display: inline-block;
          margin-left: 7px;
          background: $color-red;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          text-align: center;
          line-height: 17px;
          height: 16px;
          width: 16px;
          color: $color-white;
        }

        .icon-outlet {
          margin-left: 7px;
        }

        @include media-breakpoint-between(xl, xxl) {
          font-size: 14px!important;
        }
      }
    }
  }

  .submenu {
    position: fixed;
    top: 48px;
    left: 24px;
    z-index: 2;
    width: 100%;
    height: calc(100% - 48px);
    background: $color-white;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.15s ease-in-out;

    &.active {
      left: 0;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    .container {
      padding-top: 0;
      padding-bottom: 38px;
    }

    h5 {
      margin-bottom: 40px;

      a {
        position: relative;
        display: block;
        padding-right: 0;
        padding-left: 28px;
        font-family: $font-primary;
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        padding-top: 3px;
        line-height: 24px;
        color: $color-black;

        i {
          position: absolute;
          top: 50%;
          left: 0;
          z-index: 1;
          transform: translateY(-50%);
          font-size: 24px;
        }
      }
    }

    h6 {
      display: block;
      margin-bottom: 24px;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.11;
      color: $color-black;

      a {
        font-weight: 600;
        color: $color-black;
      }
    }

    .menu-block {
      padding-top: 32px;
      margin-top: 32px;
      border-top: 1px solid $color-lighter-grey-2;

      &:first-of-type {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
      }
    }

    .menu-images {
      display: flex;
      overflow: auto;

      a {
        display: block;
        width: 212px;
        padding: 0;
        flex-shrink: 0;
        line-height: 0;

        + a {
          margin-left: 4px;
        }

        img {
          width: 212px;
          height: 212px;
          object-fit: cover;
        }

        span {
          display: block;
          margin-top: 10px;
          font-family: $font-primary;
          font-style: italic;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.33;
          color: $color-grey;
        }
      }
    }
  }

  .additional-menu {
    margin-top: 55px;

    li {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.428;
        color: $color-black;

        i {
          width: 24px;
          margin-right: 16px;
          font-size: 18px;
          text-align: center;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    a {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(xs) {
    .menu {
      > li {
        > a {
          font-size: 18px;

          i {
            font-size: 24px;
          }
        }
      }
    }

    .submenu {
      h5 {
        a {
          font-size: 24px;
          line-height: 24px;
        }
      }

      h6 {
        font-size: 18px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .menu {
      > li {
        margin-top: 28px;

        > a {
          line-height: 1.11;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: 80px;
    z-index: $zindex-dropdown;
    height: auto;
    padding-bottom: 15px;
    opacity: 1;
    visibility: visible;
    transition: all 0.3s ease;

    .scroll-down & {
      top: 0;
    }

    .container {
      height: auto;
      padding: 0 24px;
      overflow: visible;
    }

    .menu {
      &.main {
        position: relative;
        display: flex;
        justify-content: space-between;

        > li {
          display: inline-block;
          margin-top: 0;

          &:hover {
            > a {
              color: $color-black;
              border-bottom: 2px solid $color-red;

              &.outlet {
                color: $color-red;
              }
            }

            .submenu {
              opacity: 1;
              visibility: visible;
              pointer-events: all;
            }
          }

          > a {
            display: inline-block;
            padding-bottom: 7px;
            border-bottom: 2px solid rgba($color-red, 0);
            font-size: 16px;
            font-weight: 500;
          }
        }
      }

      > li {
        margin-top: 18px;

        &.dropdown {
          position: static;

          &.compact {
            position: relative;

            .submenu {
              left: -24px;
              right: unset;
              width: auto;

              .container {
                display: block;
              }

              .menu-block {
                min-width: 12rem;
                display: none;

                &.order-1 {
                  display: block;
                }

                &.menu-split {
                  padding-right: 0;

                  .menu {
                    column-count: 1;
                  }
                }
              }
            }
          }

          &.floating {
            position: relative;

            .submenu {
              left: -24px;
              right: unset;
              width: auto;

              .menu-block {
                width: 11rem;
                max-width: 11rem;

                &.mb-img {
                  display: none;
                }

                &.menu-split {
                  padding-right: 0;

                  .menu {
                    column-count: 1;
                  }
                }
              }
            }
          }
        }

        &.label {
          margin-top: 25px;

          a {
            font-weight: 600;
            text-decoration-line: underline;
          }
        }

        > a {
          padding-right: 0;
          font-size: 14px;
          font-weight: 400;
          transition: all 0.3s ease;

          &:hover {
            color: $color-hover-red;
          }

          &.highlight {
            font-weight: 600;
          }

          i {
            display: none;
          }
        }
      }
    }

    .submenu {
      position: absolute;
      top: 100%;
      left: -45px;
      right: -45px;
      width: calc(100% + 90px);
      height: auto;
      box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.1);

      .container {
        display: flex;
        padding-top: 40px;
        padding-bottom: 50px;
      }

      h6 {
        margin-bottom: 18px;
        font-size: 16px;
      }

      .menu-block {
        padding-top: 0;
        margin-top: 0;
        border-top: 0;
        width: 8rem;
        max-width: 9rem;
        margin-right: 40px;

        &.order-1 {
          width: auto;
          max-width: 15rem;
          margin-right: 45px;
          padding-right: 45px;
        }

        &.menu-split {
          max-width: 31rem;

          .menu {
            column-count: 2;
            column-gap: 60px;

            > li {
              break-inside: avoid;

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &.all-block {
          display: none;
        }

        &.order-1 {
          border-right: 1px solid $color-lighter-grey-2;
        }

        &.only1_block {
          border-right: 0;
        }

        &.mb-img {
          width: auto;
          flex-shrink: 0;
          max-width: unset;

          &.order-4 {
            margin-left: auto;
          }
        }
      }

      .menu-images {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px 4px;
        overflow: visible;

        &.images-cols-3 {
          grid-template-columns: repeat(3, 1fr);
        }

        &.image-items-1 {
          grid-template-columns: 1fr;
        }

        a {
          + a {
            margin-left: 0;
          }

          &:hover {
            span {
              color: $color-black;
            }
          }

          span {
            margin-top: 18px;
            text-align: center;
            transition: color 0.3s ease;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    top: 96px;
    padding-top: 4px;

    .submenu {
      .menu-block {
        &.menu-split {
          max-width: 36rem;
        }
      }

      .menu-images {
        a {
          width: 278px;

          img {
            width: 278px;
            height: 282px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    .menu {
      &.main {
        justify-content: flex-start;

        > li {
          margin-right: 56px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .submenu {
      .menu-block {
        width: 14rem;
        max-width: 14rem;
      }
    }
  }
}