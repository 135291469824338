.customer-login-page {
  .registration-start {
    padding: 40px 24px;
    background: $color-white;
    border-bottom: 1px solid #EBEBEB;

    h2 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 26px;
      font-style: italic;
      font-weight: 400;
      font-size: 24px;
      line-height: 1;
      color: $color-black;

      img {
        height: 19px;
        margin-left: 20px;
      }
    }

    .box-desc {
      margin-bottom: 24px;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 1.5;

      span{
        font-weight: 600;
        color: $color-red;
      }
    }

    .cta-btn {
      width: 100%;
    }

    .registration-benefits {
      margin-top: 35px;

      .benefit-item {
        + .benefit-item {
          margin-top: 20px;
        }

        h3 {
          margin-top: 10px;
          margin-bottom: 12px;
          font-family: $font-secondary;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.25;
          color: $color-black;
        }

        p {
          font-style: italic;
          letter-spacing: 0.02em;
          color: $color-grey;
        }
      }
    }
  }

  .cart-action-block {
    &.quest {
     background: transparent;
      > div {
        background: #fff;
      }
    }
  }
  
  @include media-breakpoint-up(lg) {
    .container-content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      grid-template-areas:
      "back-arrow back-arrow"
      "registration quest";
    }

    .registration-start {
      grid-area: registration;

      h2 {
        margin-bottom: 12px;
        
        img {
          
        }
      }

      .cta-btn {
        width: auto;
        min-width: 260px;
      }

      .registration-benefits {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(2, 1fr);

        .benefit-item {
          + .benefit-item {
            margin-top: 0;
          }

          h3 {
            margin-top: 20px;
          }
        }
      }
    }

    .cart-action-block {
      &.login {
        grid-area: login;
      }

      &.quest {
        grid-area: quest;
      }
    }

    .cart-summary {
      align-self: flex-start;
    }
  }
  @include media-breakpoint-up(xl) {
    .registration-start {
      padding: 36px 40px 40px;
      border-bottom: none;

      .registration-benefits {
        gap: 45px;
        margin-top: 48px;
      }
    }
  }
}