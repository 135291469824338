#pages-detailed {
  .page-breadcrumb {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .side-nav {
    margin-bottom: 24px;
  }

  @include media-breakpoint-up(md) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 210px;
      background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
    }
  }
  @include media-breakpoint-up(lg) {
    .container {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    .page-breadcrumb {
      width: 100%;
      margin-top: 24px;
      margin-bottom: 28px;
    }

    .side-nav {
      margin-bottom: 0;
      margin-right: 10%;

      &.content-white-block {
        margin-right: 66px;

        ~ .page-content {
          max-width: 850px;
        }
      }
    }

    .page-content {
      max-width: calc(90% - 264px);
      flex-grow: 1;
      flex-basis: 0;

      &.content-box {
        background: $color-white;
        padding: 48px;

        .page-title {
          font-family: $font-primary;
          font-size: 32px;
        }

        .editor-content {
          font-size: 14px;
          line-height: 1.4285;
        }
      }

      .page-title {
        margin-bottom: 32px;
        font-family: $font-primary;
        font-style: italic;
        font-weight: 400;
        font-size: 48px;
        line-height: 1;
        color: $color-black;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .side-nav {
      margin-right: 176px;

      &.content-white-block {
        margin-right: 66px;
      }
    }

    .page-content {
      max-width: 850px;

      &.content-box {
        padding: 56px;
      }

      &.departments-box {
        max-width: 100%;
      }
    }
  }
}