.customer-order-return {
  padding: 32px 24px 50px;

  .back-wrp {
    margin-bottom: 28px;
    text-align: center;
  }

  .back-link {
    display: inline-flex;
    align-items: center;
    font-family: $font-secondary;
    color: $color-black;
    transition: color 0.3s ease;

    i {
      margin-right: 12px;
    }
  }

  .customer-order-return-steps {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;

    a {
      display: inline-flex;
      align-items: center;
      margin-right: 12px;
      font-family: $font-secondary;
      font-size: 16px;
      line-height: 1;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        cursor: default;

        .step-no {
          background: $color-black;
          color: $color-white;
        }
      }

      &.done {
        .step-no {
          background: none;
          color: $color-black;
          border: 1px solid $color-black;
        }

        .step-title {
          color: $color-black;
        }
      }

      &.inactive {
        cursor: default;

        .step-no {
          color: $color-grey;
        }

        .step-title {
          color: $color-grey;
        }
      }

      .step-no {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        background: $color-lighter-grey-2;
        color: $color-grey;
        transition: all 0.3s ease;
      }

      .step-title {
        display: none;
        margin-left: 16px;
        color: $color-black;
      }
    }
  }

  h1 {
    margin-bottom: 40px;
    font-style: italic;
    font-weight: 400;
    font-size: 32px;
    line-height: 1.125;
    text-align: center;
    color: $color-black;
  }

  h2 {
    margin-bottom: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: $color-black;
  }

  h3 {
    margin-bottom: 16px;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: $color-black;
  }

  .notice-top {
    margin-bottom: 12px;
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }

  .customer-order-return-content {
    display: grid;
    row-gap: 2px;
  }

  .white-box {
    padding: 24px 20px;
    background: $color-white;
  }

  .product-return-method {
    padding-bottom: 40px;
  }

  .returning-goods {
    .returning-product {
      padding-top: 24px;
      padding-bottom: 24px;
      border-bottom: 2px solid $color-lighter-grey;

      &:last-of-type {
        border-bottom: 0;
      }

      .product-card-small {
        .img-wrp {
          img {
            width: 80px;
            height: 80px;
          }
        }

        .name {
          margin-top: 12px;
          margin-bottom: 16px;
        }

        .details-wrp {
          display: flex;
          flex-direction: column;
        }

        .details-inner {
          display: flex;
          align-items: center;

          .quantity {
            margin-left: 12px;
            font-family: $font-secondary;
          }
        }
      }

      .returning-reason {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 2px solid $color-lighter-grey;

        h3 {
          margin-bottom: 0;
        }

        a {
          display: inline-block;
          margin-left: 12px;
          font-family: $font-secondary;
          text-decoration: underline;
          color: $color-black;
          line-height: 1.5;
        }

        .reason-desc {
          width: 100%;
          font-family: $font-secondary;
        }
      }
    }
  }

  .return-sender-data {
    padding-bottom: 40px;

    .sender-details {
      margin-bottom: 28px;
      padding: 24px 32px 32px;
      background: $color-lighter-grey;

      h3 {
        font-family: $font-primary;
        font-style: italic;
        font-size: 18px;
        font-weight: 400;
      }

      p {
        margin-bottom: 5px;
        font-family: $font-secondary;
        color: $color-grey;
      }

      .link {
        margin-top: 6px;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        text-transform: none;
      }
    }
  }

  .product-return-reason-item {
    padding-top: 20px;
    padding-bottom: 24px;

    .product-card-small {
      align-items: center;
      margin-bottom: 20px;

      .img-wrp {
        img {
          width: 80px;
          height: 80px;
        }
      }

      .name {
        margin-bottom: 0;
      }
    }

    .form-item {
      &.reason-selectable {
        display: none;
      }

      &.form-item-file {
        margin-top: 24px;
        margin-bottom: 0;
      }

      textarea {
        height: 90px;
      }
    }
  }

  .product-return-item {
    position: relative;

    .checkbox {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 1;
    }

    .product-card-small {
      .img-wrp {
        img {
          width: 80px;
          height: 80px;
        }
      }

      .name {
        margin-top: 12px;
        margin-bottom: 16px;
      }

      .details-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .amount-actions {
      display: grid;
      grid-template-columns: 1.23fr 1.6fr 1.23fr;
      column-gap: 1px;
      margin-top: 20px;

      input {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      button {
        width: 100%;
      }
    }
  }

  .btn-box {
    &.align-v {
      align-items: flex-start;
    }

    .checkbox {
      margin-bottom: 20px;
    }

    .cta-btn {
      position: relative;
      width: 100%;

      i {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-size: 16px;
        color: $color-white;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .white-box {
      padding: 28px 38px;
    }

    .return-sender-data {
      display: grid;
      grid-template-columns: 0.91fr 1.09fr;
      column-gap: 30px;
      align-items: flex-start;

      h2 {
        grid-column: 1 / -1;
      }

      .sender-details {
        order: 2;
        align-self: flex-start;
        min-height: 210px;
        margin-bottom: 0;
        //margin-top: 28px;
      }

      .sender-form {
        order: 1;
        @include media-breakpoint-up(md) {
          max-width: 320px;
        }
      }
    }

    .returning-goods {
      h3 {
        margin-bottom: 24px;
      }

      .returning-product {
        .product-card-small {
          align-items: center;

          .img-wrp {
            margin-right: 24px;

            img {
              width: 107px;
              height: 104px;
            }
          }

          .name {
            margin-top: 8px;
            margin-bottom: 14px;
          }

          .price {
            font-size: 16px;
          }

          .details-inner {
            .quantity {
              margin-left: 15px;
            }
          }

          .details {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            flex-grow: 1;
          }

          .returning-reason {
            flex-direction: column;
            align-items: flex-end;
            max-width: 200px;
            margin-left: 30px;
            padding-top: 0;
            margin-top: 0;
            border-top: 0;
            text-align: right;

            h3 {
              order: 1;
            }

            .reason-desc {
              order: 2;
            }

            a {
              order: 3;
              margin-top: 20px;
              margin-left: 0;
            }
          }
        }
      }
    }

    .product-return-reason-item {
      display: grid;
      align-items: flex-start;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 40px;

      .product-card-small {
        .img-wrp {
          margin-right: 20px;

          img {
            width: 72px;
            height: 72px;
          }
        }

        .name {
          margin-bottom: 8px;
        }
      }

      .reason {
        order: 1;
      }

      .product-card-small {
        order: 2;
      }

      .photos {
        order: 3;
        grid-column: span 2;
      }
    }

    .product-return-item {
      display: flex;
      align-items: center;
      padding-top: 28px;
      padding-bottom: 28px;

      .product-card-small {
        align-items: center;
        margin-left: 24px;
        margin-right: auto;

        .img-wrp {
          margin-right: 24px;

          img {
            width: 107px;
            height: 104px;
          }
        }

        .price {
          font-size: 16px;
        }

        .name {
          margin-top: 8px;
          margin-bottom: 14px;
        }
      }

      .checkbox {
        position: relative;
        top: unset;
        left: unset;
        width: 24px;
        height: 24px;
        padding-left: 0;

        .box {
          width: 100%;
          height: 100%;

          i {
            font-size: 18px;
          }
        }
      }

      .amount-actions {
        display: flex;
        margin-left: 24px;

        input {
          width: 77px;
        }

        button {
          width: 58px;
        }
      }

      .quantity {
        font-family: $font-secondary;
        font-weight: 600;
        font-size: 16px;
        line-height: 1;
      }

      .quantity-select {
        max-width: 80px;
        min-width: auto;

        button {
          border: 1px solid #F6F6F6;
          background: #F6F6F6;

          .filter-option-inner-inner {
            font-weight: 600;
          }
        }
      }
    }

    .customer-order-return-steps {
      a {
        margin-right: 30px;

        .step-title {
          display: inline-block;
        }
      }
    }

    .btn-box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .cta-btn {
        width: auto;
        min-width: 284px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 50px;

    .inner-wrp {
      max-width: 850px;
      margin-left: auto;
      margin-right: auto;
    }

    h1 {
      margin-bottom: 56px;
      font-size: 48px;
      text-align: left;
    }

    .notice-top {
      margin-bottom: 20px;
      text-align: left;
    }

    .white-box {
      padding-left: 48px;
      padding-right: 48px;
    }

    .product-return-reason-item {
      padding-top: 40px;
      padding-bottom: 24px;
      column-gap: 64px;
    }

    .product-return-item {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .customer-order-return-steps {
      justify-content: flex-start;
      margin-bottom: 56px;

      a {
        margin-right: 56px;
      }
    }
  }
  @include media-breakpoint-up(xl) {
    h2 {
      margin-bottom: 30px;
      font-size: 32px;
    }

    .product-return-method {
      padding-top: 48px;
      padding-bottom: 56px;
    }

    .return-sender-data {
      padding-top: 48px;
      padding-bottom: 56px;
    }

    .btn-box {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .returning-goods {
      padding-top: 40px;
      padding-bottom: 40px;

      .returning-product {
        padding-top: 0;
        padding-bottom: 40px;
        border-bottom: 0;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    .return-sender-data {
      column-gap: 50px;

      .sender-details {
        padding: 32px;
      }
    }

    .product-return-reason-item {
      .reason {
        .form-item {
          .file {
            display: grid;
            align-items: flex-start;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 16px;

            .form-label-file {
              cursor: pointer;
              margin-bottom: 0;
            }

            &.disabled {
              .form-label-file {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .customer-order-return-steps {
      a:not(.inactive) {
        &:hover {
          .step-no {
            background: $color-black;
            color: $color-white;
          }
        }
      }
    }
  }
}