#added2cart {
  .info-msg {
    .info {
      padding: 12px 24px;
    }
  }  
}

.info-msg {
  margin-bottom: 16px;
  background: rgba($color-light-yellow, 0.6);
  font-style: italic;
  font-size: 14px;
  line-height: 1.375;

  .info {
    display: flex;
    align-items: flex-start;
    padding: 20px 24px;
  }

  .info-desc {
    margin-left: 24px;

    p {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }

      strong, b {
        font-weight: 700;
      }

      i {
        font-size: 12px;
        color: $color-grey;
      }

      span {
        text-decoration: underline;
        color: $color-black;
      }
    }
  }

  i {
    margin-top: 1px;
  }

  .info-additional {
    width: 100%;
    padding: 20px 24px;
    border-top: 2px solid $color-white;

    .checkbox {
      .box {
        margin-right: 24px;
      }
    }
  }
  
  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }
}

.info-msg2 {
  margin-bottom: 16px;
  background: rgba($color-light-yellow, 0.6);
  font-size: 14px;
  font-family: $font-secondary;
  line-height: 20px;

  .info {
    display: flex;
    align-items: flex-start;
    padding: 20px 24px;
  }

  .info-desc {
    margin-left: 12px;

    p {
      margin-bottom: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }

      strong, b {
        font-weight: 700;
      }

      i {
        font-size: 12px;
        color: $color-grey;
      }

      span {
        text-decoration: underline;
        color: $color-black;
      }
    }
  }

  &.oversized-disabled {
    margin-bottom: 20px;
    margin-top: 30px;
  }
  
  div.link-block {
    margin-top: 12px;
    a {
      text-transform: uppercase;
      text-decoration: underline;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 2px;
      color: #000;
    }
  }
  
  i {
    margin-top: 1px;
  }

  .info-additional {
    width: 100%;
    padding: 20px 24px;
    border-top: 2px solid $color-white;

    .checkbox {
      .box {
        margin-right: 24px;
      }
    }
  }
}

.important-delivery-msg {
  display: flex;
  column-gap: 8px;
  padding: 12px 12px 12px 16px;
  margin-bottom: 15px;
  background: $color-red2;
  color: $color-red;
  font-family: $font-secondary;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  i{
    margin-top: 2px;
    font-size: 16px;
  }

  span{
    display: block;
  }
}