.form-item {
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &.form-psw-item {
    input {
      padding-right: 65px;
    }

    .show-psw {
      position: absolute;
      top: 50%;
      right: 11px;
      z-index: 4;
      transform: translateY(-50%);
      padding: 5px;
      border: 0;
      background: none;
      font-family: $font-secondary;
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 1;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      color: $color-grey;
      cursor: pointer;
    }
  }

  &.item-has-error {
    input {
      border-color: $color-red;
    }

    .bootstrap-select {
      .btn {
        border-color: $color-red;
      }
    }

    .form-item-error {
      display: block;
      margin-top: 5px;
      font-family: $font-secondary;
      font-size: 12px;
      color: $color-red;
    }
  }

  .file {
    .form-label-file {
      display: inline-block;
      margin-bottom: 8px;
      padding: 9px 16px;
      border: 1px solid $color-light-grey;
      font-family: $font-secondary;
      text-align: center;
      color: $color-black;
    }

    .file-name {
      margin-bottom: 0;
      font-family: $font-secondary;
    }

    .file-ext {
      margin-bottom: 0;
      font-family: $font-secondary;
      font-size: 12px;
      color: $color-grey;
    }

    input {
      display: none;
    }
  }

  .form-label {
    margin-bottom: 10px;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 1.5;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    color: $color-grey;
  }

  .link {
    margin-top: 12px;
    font-size: 10px;
    letter-spacing: 0.12em;
  }

  .warning-text {
    margin-top: 9px;
    color: $color-red;
  }

  .form-item-info {
    margin-top: 8px;
    margin-bottom: 0;
    font-family: $font-secondary;
    color: $color-grey;

    &.inline-list {
      li {
        display: inline-block;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .icon-group {
    position: relative;

    input {
      padding-left: 44px;
    }

    i {
      position: absolute;
      top: 50%;
      left: 16px;
      z-index: 1;
      transform: translateY(-50%);
      font-size: 16px;
      color: $color-black;
    }
  }

  .input-group-text {
    padding: 9px 19px;
    border: 1px solid $color-light-grey;
    background: $color-lighter-grey;
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: $color-black;
  }
}