.page_editor_template {
  margin-left: auto;
  margin-right: auto;

  &.full_width {
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;

    .row, .product_listing {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  
  &.no-bottom-margin {
    margin-bottom: 0!important;
  }
}

.page_editor_banner_right {
  > div {
    &:nth-child(1) {
      order: 2;
    }

    &:nth-child(2) {
      order: 1;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column-reverse;

    .modal-body & {
      flex-direction: column;
    }
  }
}

.page_editor_banner_top {
  @include media-breakpoint-down(sm) {
    > div {
      &.banner-wrp {
        order: 1;
      }

      &.texts-wrp {
        order: 2;
      }
    }
  }
}

.page_editor_banner_bottom {
  @include media-breakpoint-down(sm) {
    > div {
      &:nth-child(1) {
        order: 2;
      }

      &:nth-child(2) {
        order: 1;
      }
    }
  }
}

.editor_type_text {
  &.editor_type_title_block {
    font-weight: 300;

    h1, .h1 {
      margin-bottom: 14px;
    }
  }

  @include media-breakpoint-down(md) {
    h2, .h2, h3, .h3 {
      text-align: left;
    }
  }
  @include media-breakpoint-up(md) {
    &.editor_type_title_block {
      h1, .h1 {
        margin-bottom: 19px;
      }
    }
  }
}

.editor_type_banner_text {
  margin-bottom: 24px;

  .inner-wrp {
    flex-direction: column;
    width: 100%;

    > div {
      width: 100%;
    }

    
    
    .banner-wrp {
      height: 290px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      &.shadow {
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.3) !important;
      }
    }

    .texts-wrp {
      padding: 40px 24px;
      text-align: center;

      h2 {
        margin-top: 0;
        margin-bottom: 23px;
        color: $color-black;
        font-size: 24px;
        font-style: italic;
        font-weight: 400;
        line-height: 28px;
      }

      .text-wrp {
        margin-top: 24px;
        color: $color-black;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.fonts-1 {
        line-height: 22px;
        font-size: 15px;
      }

      &.margin-tb {
        margin: 0px 8px;
        width: calc(100% - 16px);
      }
    }
  }

  @include media-breakpoint-down(md) {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include media-breakpoint-up(md) {
    .inner-wrp {
      flex-direction: row;

      > div {
        width: 50%;
      }

      .banner-wrp {
        height: auto;

        &.wide {
          width: 58.3%;
        }
      }

      .texts-wrp {
        padding: 142px 32px;
        text-align: left;

        .text-column {
          max-width: 390px;
        }

        .text-wrp {
          margin-bottom: 30px;
        }

        &.fonts-1 {
          line-height: 30px;
          font-size: 17px;
        }

        &.margin-tb {
          margin: 28px 0;
          width: 41.7%;
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    margin-bottom: 80px;

    .content-box & {
      .inner-wrp {
        align-items: center;

        &.page_editor_banner_right {
          .texts-wrp {
            padding: 30px 40px 30px 0;
          }
        }

        .banner-wrp {
          height: 280px;
        }

        .texts-wrp {
          padding: 30px 0 30px 40px;
        }
      }
    }
  }
}

.editor_type_features {
  padding-top: 64px;
  padding-bottom: 64px;

  .content-box & {
    .features-list {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .features-list {
    .item {
      display: flex;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }

      .img-wrp {
        width: 65px;
        flex-shrink: 0;

        img {
          height: 38px;
        }
      }

      .content {
        flex-grow: 1;
      }

      h3 {
        margin-bottom: 8px;
        color: $color-black;
        font-family: $font-primary;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px;
      }

      p {
        margin-bottom: 0;
        color: $color-grey;
        font-family: $font-primary;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.28px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    padding-top: 80px;
    padding-bottom: 80px;

    .features-list {
      display: grid !important;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 22px;

      .item {
        flex-direction: column;
        margin-bottom: 0;

        .img-wrp {
          width: auto;
          height: 65px;

          img {
            height: 44px;
          }
        }

        h3 {
          margin-bottom: 14px;
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }
}

.editor_type_banner_banner {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .banner-wrp {
      .banner-inner-wrp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 28px;
        min-height: 280px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        h3 {
          margin: 0px 0px 12px 0px;
          font-weight: normal;
          font-size: 28px;
          line-height: 24px;
          color: inherit;
        }
      }
    }

    &.wt_gap {
      gap: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .banner-wrp {
        .banner-inner-wrp {
          min-height: 564px;
        }
      }
    }
  }
}

.editor_type_text_text_banner {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;

    margin: 20px 0;

  }
}

.editor_type_texts_banner {
  .inner-wrp {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;

    .banner-wrp {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      position: relative;
      overflow: hidden;
      margin: -40px 27px 0px 27px;
      min-height: 290px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: initial;
        min-height: 100%;
        min-width: 100%;
        width: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text-wrp {
      &.text-top-wrp {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        padding: 45px 28px 75px 28px;
        background-color: $color-lighter-grey-3;

        h1 {
          margin-top: 0px;
          margin-bottom: 9px;
        }

        p {
          line-height: 20px;
        }
      }

      &.text-bottom-wrp {
        grid-column: 1 / 2;
        grid-row: 3 / 4;
        padding: 54px 28px 32px 28px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: 50% 50%;
      grid-template-rows: auto auto;

      .banner-wrp {
        grid-column: 2 / 3;
        grid-row: 1 / 3;
        margin: 0px;

        img {
          width: auto;
        }
      }

      .text-wrp {
        &.text-top-wrp {
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          padding: 96px 69px 79px 69px;

          .inner-text-wrp {
            max-width: 380px;
          }

          h1 {
            margin-bottom: 19px;
          }

          p {
            line-height: 26px;
          }
        }

        &.text-bottom-wrp {
          grid-column: 1 / 2;
          grid-row: 2 / 3;
          padding: 101px 69px 75px 69px;

          .inner-text-wrp {
            max-width: 500px;
          }
        }
      }
    }
  }
}

.editor_type_categories_list {
  margin-bottom: 64px;

  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
  }
}

.editor_type_brands_list {
  margin-bottom: 64px;
  overflow: hidden;

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .brands-list {
    .item {
      text-align: center;

      a {
        display: inline-block;
        width: 80px;
        height: 48px;
        line-height: 0;
        opacity: 0.75;
        transition: opacity 0.3s ease;

        &:hover {
          opacity: 1;
        }

        img {
          width: 100%;
          height: 100%;
          max-width: unset;
          object-fit: contain;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  @include media-breakpoint-up(lg) {
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .brands-list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;

      .item {
        &:nth-child(n + 9) {
          display: none;
        }
      }
    }

    .link {
      margin-left: 5.5%;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-up(xxxl) {
    .brands-list {
      .item {
        &:nth-child(n + 9) {
          display: inline-block;
        }

        &:nth-child(n + 11) {
          display: none;
        }
      }
    }

    .link {
      margin-left: 6%
    }
  }
}

.editor_type_banners_slider {
  position: relative;
  margin-bottom: 56px;

  .custom-controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    line-height: 0;
    transform: translateX(-50%) translateY(50%);

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      padding: 0;
      margin-right: 12px;
      background: $color-white;
      border: 1px solid #ECECEC;
      border-radius: 100%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
      line-height: 0;

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 16px;
        color: $color-black;
        transition: all 0.3s ease;
      }
    }
  }

  .custom-nav {
    position: absolute;
    top: calc(100% + 24px);
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    order: 2;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;

    button {
      display: block;
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      margin-right: 8px;
      padding: 0;
      border: 0;
      border-radius: 100%;
      background: $color-lighter-grey-2;
      transition: all 0.3s ease;
      appearance: none;

      &.tns-nav-active, &:hover {
        background: $color-black;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .item {
    height: 461px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-content {
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      height: 210px;
      padding-top: 25px;
      transform: translateX(-50%);

      h3 {
        margin-bottom: 15px;
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 1;
      }

      h2 {
        margin-bottom: 24px;
        font-style: italic;
        font-weight: 400;
        font-size: 28px;
        line-height: 1;
      }

      p {
        margin-bottom: 0;
        font-style: italic;
        letter-spacing: 0.02em;
      }
    }
  }

  @include media-breakpoint-up(xs) {
    h2 {
      font-size: 32px;
    }
  }
  @include media-breakpoint-up(sm) {
    .item {
      .banner-content {
        text-align: center;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .item {
      height: 500px;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 64px;

    .custom-controls {
      button {
        width: 56px;
        height: 56px;
        margin-right: 16px;

        i {
          font-size: 24px;
        }
      }
    }

    .custom-nav {
      button {
        width: 12px;
        height: 12px;
        margin-right: 12px;
      }
    }

    .item {
      &.content-right {
        img {
          right: unset;
          left: 0;
        }

        .banner-content {
          justify-content: flex-end;
          text-align: right;
        }
      }
      
      &.content-center {
        .banner-content {
          justify-content: center;
          text-align: center;
        }
      }

      img {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }

      .banner-content {
        display: flex;
        height: 100%;
        padding-top: 10%;
        padding-bottom: 10%;
        text-align: left;

        .inner {
          width: 45%;
        }

        h3 {
          font-size: 20px;
        }

        h2 {
          font-size: 34px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    .item {
      .banner-content {
        padding-top: 7%;
        padding-bottom: 7%;

        h3 {
          font-size: 30px;
        }

        h2 {
          font-size: 48px;
        }

        p {
          font-size: 18px;
        }
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    margin-bottom: 108px;

    .custom-nav {
      top: calc(100% + 32px);
    }

    .item {
      height: 598px;
    }
  }
}

.editor_type_banner {
  margin-bottom: 64px;

  .content-box & {
    padding-left: 0;
    padding-right: 0;

    &.full_width {
      max-width: unset;
      margin-left: -24px;
      width: calc(100% + 48px);
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }

  .banner-inner-wrp {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    min-height: 400px;
    padding: 48px 24px;

    .cover-image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .banner-content-wrp {
      position: relative;
      z-index: 2;
      max-width: 450px;

      h2 {
        margin-bottom: 20px;
        font-family: $font-primary;
        font-size: 32px;
        font-style: italic;
        font-weight: 400;
        line-height: 1.11;
        color: $color-white;
      }

      p {
        margin: 0;
        font-family: $font-primary;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.28px;
        color: $color-white;
      }

      .btn-wrp {
        margin-top: 24px;
      }
    }

    &.ha-left {
      justify-content: flex-start;
    }

    &.ha-center {
      justify-content: center;
    }

    &.ha-right {
      justify-content: flex-end;
    }
  }

  &.ha-left {
    .banner-inner-wrp {
      background-position: left center;
    }
  }

  &.ha-center {
    .banner-inner-wrp {
      background-position: center;
    }
  }

  &.ha-right {
    .banner-inner-wrp {
      background-position: right center;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 55px;

    .banner-inner-wrp {
      min-height: 440px;
      padding: 60px 80px;

      .banner-content-wrp {
        h2 {
          margin-bottom: 24px;
          font-size: 48px;
        }

        p {
          font-size: 18px;
          line-height: 24px;
        }

        .btn-wrp {
          margin-top: 32px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .content-box & {
      &.full_width {
        margin-left: -48px;
        width: calc(100% + 96px);
      }
    }

    .banner-inner-wrp {
      padding: 40px 50px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 100px;

    .banner-inner-wrp {
      padding: 60px 80px;
    }
  }
  @include media-breakpoint-up(xxl) {
    .content-box & {
      &.full_width {
        margin-left: -56px;
        width: calc(100% + 112px);
      }
    }
  }
}

.editor_type_duk {
  margin-top: 30px;
  margin-bottom: 30px;

  .accordion-title {
    margin-bottom: 0;
    font-size: 20px;
  }

  .accordion-item {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid $color-lighter-grey-2;

    &:last-child {
      border-color: transparent;
    }
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-button {
    padding: 18px 20px 18px 0;
    border-radius: 0;
    box-shadow: none;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    text-decoration-line: none;
    color: $color-black;

    &:after {
      display: none;
    }

    &:hover {
      color: $color-black;
    }

    &[aria-expanded="true"] {
      font-weight: 600;

      i {
        &.fa-plus {
          display: none;
        }

        &.fa-minus {
          display: block;
        }
      }
    }

    i {
      position: absolute;
      top: 50%;
      right: 0;
      z-index: 1;
      transform: translateY(-50%);
      font-size: 16px;
      color: $color-black;

      &.fa-minus {
        display: none;
      }
    }
  }

  .accordion-body {
    padding: 0 0 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.428;
    color: $color-black;
  }

  @include media-breakpoint-up(lg) {
    .accordion-title {
      margin-bottom: 10px;
      font-size: 24px;
    }

    .accordion-button {
      padding: 24px 20px 24px 0;
      font-size: 16px;
    }

    .accordion-body {
      padding-bottom: 35px;
    }
  }
}

#type_products {
  .editor-content {
    .container {
      padding-left: 0px!important;
      padding-right: 0px!important;
    }
  }
  
  .page_editor_template {
    &:not(.no-bottom-margin) {
      margin-bottom: 64px!important;
  
      @include media-breakpoint-down(lg) {
        margin-bottom: 50px!important;
      }
    }
  }

  .editor_type_banner {
    /*.banner-inner-wrp {
      min-height: unset!important;
      padding: 0!important;
      
      img {
        position: static;
      }
    }*/
    /*@include media-breakpoint-up(lg) {
      .banner-inner-wrp {
        padding: 0!important;
        
        img {
          position: static;
        }
      }
    }*/
    @include media-breakpoint-down(lg) { 
      .banner-inner-wrp {
        min-height: 247px;
        
        .banner-content-wrp {
          max-width: unset;
        }
      }
    }
  }
  
  .editor_type_banner {
    p, h2 {
      font-style: normal;
      font-family: $font-secondary;
    }
    
    h2 {
      font-size: 30px;
      font-weight: 600;
      
      
      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
    }
    
    p {
      font-size: 16px;
      letter-spacing: 0;
    }

    .banner-inner-wrp {
      padding: 60px;
      
      img {
        .align-right-center {
          object-position: right center;
        }
      }
      
      .banner-content-wrp {
        @include media-breakpoint-up(lg) {
          max-width: 50%;
          background: transparent!important;
        }
      }

      @include media-breakpoint-down(lg) {
        display: grid;
        padding: 0;
        
        img {
          position: static;
          order: 2;
          min-height: 204px;
        }
        
        .banner-content-wrp {
          order: 1;
          padding-bottom: 30px;
          
          &.bg-color {
            padding: 56px 24px 0 24px;
          }
        }
      }
    }
  }
  
  .editor_type_banner_text {
    h2 {
      font-family: $font-secondary;
      font-size: 21px;
      font-weight: 600;
      font-style: normal;
    }
    
    .inner-wrp {
      gap: 60px;
      
      .banner-wrp {
        max-width: 380px;
        height: 290px;
        background-size: initial; 
      }
      
      .texts-wrp {
        width: 50%;
        padding: 0;
        margin: 0;
        justify-content: flex-start !important;
        
        .text-wrp {
          margin-bottom: 0;
        }
      }

      @include media-breakpoint-down(lg) {
        display: block!important;

        .banner-wrp, .texts-wrp {
          width: 100%;
          max-width: unset;
        }
        .banner-wrp {
          /*display: block!important;
          max-width: unset;
          background-image: none!important;
          
          img {
            display: block!important;
          }*/
        }
        .texts-wrp {
          padding-top: 28px;
        }
      }
    }
  }
}

.editor_type_text {
  &.paddings {
    padding: 50px 150px 68px 150px;

    @include media-breakpoint-down(lg) {
      padding: 56px 24px 64px 24px;
    }
  }
}

.editor_type_banner_text_banner_text {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .banner-wrp {
      .banner-inner-wrp {
        padding-top: 28px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoint-up(lg) {
          min-height: 280px;
        }
        
        h3 {
          margin: 0px 0px 12px 0px;
          font-weight: 600;
          font-size: 21px;
          line-height: 28px;
          color: inherit;
        }
      }
    }

    a.button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 24px;
      border: 0;
      appearance: none;
      background: $color-red;
      font-family: $font-secondary;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-decoration-line: none !important;
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;
      transition: all 0.3s ease;

      a:hover {
        //color: $color-hover-red;
      }
    }
    
    &.wt_gap {
      gap: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(2, minmax(0, 1fr));

      .banner-wrp {
        .banner-inner-wrp {

        }
      }
    }
  }
}

.editor_type_series_info {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .banner-wrp {
      .banner-inner-wrp {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        @include media-breakpoint-up(lg) {
          min-height: 280px;
        }

        h3 {
          padding: 10px 0;
          margin: 0;
          line-height: 1.5;
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          color: inherit;
        }
        
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          border: none;
          
          li {
            position: relative;
            margin-top: 10px;
            padding-bottom: 10px;
            padding-left: 32px;
            border-bottom: 1px solid #EBEBEB;
            
            &:before {
              font-family: "Font Awesome 6 Pro";
              font-weight: 400;
              content: "\f00c";
              background: transparent;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }
      
      .img {
        border-bottom: solid 2px $color-red;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }
    
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 24px;
      border: 0;
      appearance: none;
      background: $color-red;
      font-family: $font-secondary;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-decoration-line: none !important;
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;
      transition: all 0.3s ease;
      
      a:hover {
        color: $color-hover-red;
      }
    }

    &.wt_gap {
      gap: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      .banner-wrp {
        display: flex;
        flex-direction: column;
        .banner-inner-wrp {

        }
        
        a {
          margin-top: auto;
        }
      }
    }
  }
}

.editor_type_banner_text3 {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(1, minmax(0, 1fr));

    .banner-wrp {
      .banner-inner-wrp {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        @include media-breakpoint-up(lg) {
          //text-align: left;
          //min-height: 66px;
        }

        h3 {
          padding: 10px 0;
          margin: 0;
          line-height: 1.5;
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          color: inherit;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          border: none;

          li {
            position: relative;
            margin-top: 10px;
            padding-bottom: 10px;
            padding-left: 32px;
            border-bottom: 1px solid #EBEBEB;

            &:before {
              font-family: "Font Awesome 6 Pro";
              font-weight: 400;
              content: "\f00c";
              background: transparent;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }

      .img {
      
      }
    }

    a.button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 24px;
      border: 0;
      appearance: none;
      background: $color-red;
      font-family: $font-secondary;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-decoration-line: none !important;
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;
      transition: all 0.3s ease;

      a:hover {
        //color: $color-hover-red;
      }
    }

    &.wt_gap {
      gap: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(3, minmax(0, 1fr));

      .banner-wrp {
        display: flex;
        flex-direction: column;
        .banner-inner-wrp {

        }

        a {
          margin-top: auto;
        }
      }
    }
  }
}

.editor_type_banner_text4  {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .inner-wrp {
    display: grid;
    flex-direction: column;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .banner-wrp {
      .banner-inner-wrp {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        @include media-breakpoint-up(lg) {
          text-align: left;
          //min-height: 280px;
        }

        h3 {
          padding: 10px 0;
          margin: 0;
          line-height: 1.5;
          font-size: 24px;
          font-weight: 400;
          text-align: center;
          color: inherit;
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          border: none;

          li {
            position: relative;
            margin-top: 10px;
            padding-bottom: 10px;
            padding-left: 32px;
            border-bottom: 1px solid #EBEBEB;

            &:before {
              font-family: "Font Awesome 6 Pro";
              font-weight: 400;
              content: "\f00c";
              background: transparent;
              position: absolute;
              left: 0;
              top: 0;
            }
          }
        }
      }

      .img {

      }
    }

    a.button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      padding: 0 24px;
      border: 0;
      appearance: none;
      background: $color-red;
      font-family: $font-secondary;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 2px;
      text-decoration-line: none !important;
      text-transform: uppercase;
      color: $color-white;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        //color: $color-hover-red;
      }
    }

    &.wt_gap {
      gap: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    .inner-wrp {
      grid-template-columns: repeat(4, minmax(0, 1fr));

      .banner-wrp {
        display: flex;
        flex-direction: column;
        .banner-inner-wrp {

        }

        a.button {
          margin-top: auto;
        }
      }
    }
  }
}