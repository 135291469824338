.benefits-list {
  margin-top: 64px;
  margin-bottom: 64px;

  .item {
    + .item {
      margin-top: 48px;
    }

    > a {
      display: inline-block;
      color: $color-black;
    }

    img {
      margin-bottom: 16px;
    }

    h3 {
      margin-bottom: 8px;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 16px;
      line-height: 1.25;
      color: $color-black;
      transition: color 0.3s ease;
    }

    p, span {
      display: block;
      margin-bottom: 0;
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.375;
      color: $color-grey;

      a {
        color: $color-red;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 50px 30px;
    }

    .item {
      width: 100%;

      + .item {
        margin-top: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .container {
      grid-template-columns: repeat(4, 1fr);
    }

    .item {
      > a {
        &:hover {
          h3 {
            color: $color-red;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 96px;
    margin-bottom: 80px;

    .item {
      img {
        margin-bottom: 20px;
      }
    }
  }
}