.product-tags {
  position: absolute;
  z-index: 1;
  display: flex;
  pointer-events: none;

  .tag {
    display: inline-block;
    padding: 7px 9px;
    border: 1px solid var(--tag-color);
    background: $color-white;
    font-family: $font-secondary;
    font-weight: 600;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    color: var(--tag-color);

    + .tag {
      margin-left: 2px;
    }

    .partial-name {
      display: none;
    }
    
    &.tag-outlet {
      border-color: #219653!important;
      color: #219653!important;
      padding: 7px 11px 4px 11px;
      white-space: nowrap;
      
      .icon-outlet {
        padding-right: 2px;
        img {
          width: 19px;
          height: 18px;
          margin-top: -1px;
        }
      }
      .partial-name {
        padding: 0 5px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .tag {
      padding: 9px 11px;
      pointer-events: all;
      cursor: default;

      + .tag {
        margin-left: 4px;
      }

      &:hover {
        .partial-name {
          display: inline;
        }
      }
    }
  }
}