.product-list-banner {
  position: relative;
  height: 430px;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(36, 36, 36, 0) 61.95%, #242424 88.4%);
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    transform-origin: center center;
    will-change: transform;
    transition: all 0.3s ease;
  }

  .banner-content {
    position: absolute;
    bottom: 30px;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;
    text-align: center;
    color: $color-white;

    .title {
      display: block;
      margin-bottom: 15px;
      font-style: italic;
      font-weight: 400;
      font-size: 32px;
      line-height: 1.1;
    }

    .link-txt {
      display: block;
      font-family: $font-secondary;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2px;
      text-decoration-line: underline;
      text-transform: uppercase;
      opacity: 0.4;
    }
  }

  @include media-breakpoint-up(xl) {
    height: 390px;

    &:hover:not(.non-link) {
      img {
        transform: scale(1.05) translateZ(0);
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    height: 430px;
  }
  @include media-breakpoint-up(xxxl) {
    height: 514px;
  }
}