.page-header {
  .page-breadcrumb {
    padding-top: 16px;
  }

  h1 {
    padding-top: 24px;
    margin-bottom: 28px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
  }

  @include media-breakpoint-up(md) {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 210px;
      background: linear-gradient(180deg, #F8F8F8 0%, rgba(248, 248, 248, 0) 100%);
    }

    .page-breadcrumb {
      padding-top: 24px;
    }

    h1 {
      padding-top: 26px;
      margin-bottom: 48px;
      font-size: 48px;
      line-height: 1;
    }
  }
}