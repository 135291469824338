.product-use-tabs {
  .nav-tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 16px;
    margin-bottom: 30px;
    border-bottom: 0;

    button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 5px;
      border: 1px solid transparent;
      opacity: 0.5;
      background: none;

      &.active {
        opacity: 1;
        border-color: $color-hover-red;
      }

      .title {
        display: block;
        margin-top: 5px;
        margin-bottom: 12px;
        font-family: $font-secondary;
        font-size: 12px;
        line-height: 1.25;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .nav-tabs {
      grid-template-columns: repeat(5, 1fr);
      gap: 24px 16px;
      margin-bottom: 48px;

      button {
        padding: 10px;

        .title {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .nav-tabs {
      max-width: 790px;
      margin-left: auto;
      margin-right: auto;
      column-gap: 47px;
    }
  }
}