#type_guestbook {
  .page-wrapper {
    background: #F6F6F6;
    padding-top: 40px;
    
    @include media-breakpoint-down(lg) {
      padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
#guestbook_page {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 756px;

  .element-block {
    position: relative;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 35px 50px;
    
    .answer-link {
      position: absolute;
      right: 50px;
      bottom: 5px;
      
      font-family: $font-secondary;
    }
  }
  
  h1.page-title {
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 48px;
    font-style: italic;
  }
  .totals-wrp {
    display: flex;
    font-family: $font-secondary;

    .average-rating {
      font-family: $font-secondary;
      font-size: 48px;
      font-weight: 600;
    }
    
    .rating {
      margin-top: auto;
      margin-bottom: 20px;
      margin-left: 10px;
    }
  }

  .info {
    font-family: $font-secondary;
    font-size: 14px;
    margin-top: 10px;
    
    span {
      //text-decoration: underline;
    }
    
    a {
      color: #716B66;
      margin-left: 20px;
      
      &.active {
        color: $color-black;
        text-decoration: underline;
      }
    }
  }
  
  .rating {
    display: inline-block;
    position: relative;
    
    .stars {
      display:block;
      float:left;
      margin: 0;
      width:198px;
      height:32px;
      opacity: 0.3;
      position:relative;
      background:url('/images/frontend/stars_filled.png') bottom;
    }
    .stars-filled {
      width:0px;
      height:32px;
      position:absolute;
      left:0;
      top:0;
      opacity: 1;
      text-indent:-9999px;
      display:block;
      background:url('/images/frontend/stars_filled.png') top left;
      margin: 0;

      &.stars-1 {width:34px;}
      &.stars-2 {width:75px;}
      &.stars-3 {width:115px;}
      &.stars-4 {width:156px;}
      &.stars-5 {width:197px;}
    }
  }
  
  .comment-header {
    display: flex;
    line-height: 32px;
    vertical-align: middle;
    
    .rating {
      margin-left: -48px;
      scale: 50%;
      
      .stars {
        
      }
      .stars, .stars-filled {
        
      }
    }
    
    .date {
      margin-left: auto;
      font-family: $font-secondary;
      color: $color-grey;
    }
  }
  
    margin-bottom: 30px;

      .row {
        margin-left: -6px;
        margin-right: -6px;

        > div {
          padding-left: 6px;
          padding-right: 6px;
        }
      }
      .form-group {
        margin-bottom: 16px;
      }
      .form-control {
        height: 40px;
      }
      #comment_input {
        height: 87px;
      }
      .site-rating-wrp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 25px;

        .pre-text {
          display: inline-block;
          margin-right: 17px;
          font-size: 16px;
          line-height: 150%;
          letter-spacing: -0.01em;
        }
        
        a:hover {
          background:url('/images/frontend/stars_filled.png') top left;
        }
        a {
          width:160px;
          height:29px;
          position:absolute;
          left:0;
          top:0;
          text-indent:-9999px;
          overflow:hidden;

          &.rate1 {width:32px; z-index:5;}
          &.rate2 {width:64px; z-index:4;}
          &.rate3 {width:96px; z-index:3;}
          &.rate4 {width:128px; z-index:2;}
          &.rate5 {width:160px; z-index:1;}
        }
        .number-rating {
          font-weight: 800;
          font-size: 26px;
          line-height: 120%;
          letter-spacing: -0.02em;
        }
      }
    .btn.btn-lg {
      height: 54px;
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;
    }

  .comments {
    .comment {
      font-family: $font-secondary;
      .comment-header {
        margin-bottom: 20px;
      }
      .user_comment {
        display: block;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
      .user_info {
        display: flex;

        .flag {
          margin-right: 10px;
          img {
            height: 16px;
          }
        }
        
        .name {
          font-size: 16px;
          display: block;
          font-weight: 600;
          color: #222;
        }
      }
      .answer {
        margin-top: 24px;
        padding: 24px 36px;
        background-color: #F9F9FB;

        .answer_content {
          font-size: 14px;
          line-height: 150%;
          letter-spacing: -0.01em;
          color: #80808C;

          p {
            margin: 0;
            margin-bottom: 10px;
          }

          .from {
            font-weight: 600;
          }
        }
      }
      .product_info {
        float: right;
        margin-left: 20px;
        width: 160px;
        text-align: center;

        a {
          color: #222;
          text-decoration: none;
        }
      }
    }
  }
  .pagination-wrp {
    .lazy {
      text-align: center;
      margin-top: 30px;
      #lazy_load {
        background-color: transparent;
        color: #1E1E1E;
        border: 1px solid;
        border-color: #1E1E1E;
        font-size: 12px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    h1.page-title {
      font-size: 32px;
      line-height: 130%;
    }
    .totals-wrp {
      margin-bottom: 23px;

      .average-rating {
        font-size: 32px;
      }
      
      .rating {
        margin-bottom: 1px;
      }
    }
    
    .stars {
      //scale: 80%;
    }
    
    .guestbook_form {
      margin-bottom: 27px;

      form {
        .site-rating-wrp {
          margin-top: 20px;
          margin-bottom: 20px;

          .pre-text {
            flex-grow: 1;
            width: 100%;
          }
        }
      }
      .btn.btn-lg {
        height: 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
      }
    }
    .comments {
      .element-block {
        padding: 24px 20px;

        .answer-link {
          right: 20px;
        }
      }
      .comment {
        .comment-header {
          font-size: 12px;
          margin-bottom: 10px;
        }
        .user_comment {
          margin-bottom: 12px;
          font-size: 14px;
        }
        .user_info {
          font-size: 14px;
        }
        .answer {
          margin-top: 16px;
          padding: 14px 22px;

          .answer_content {
            font-size: 12px;
          }
        }
      }
    }
    .pagination-wrp {
      .lazy {
        #lazy_load {
          font-size: 14px;
        }
      }
    }
  }
}