.site-header-top {
  position: relative;
  z-index: $zindex-sticky;
  background: $color-lighter-grey;
  font-style: italic;
  line-height: 1;
  letter-spacing: 0.02em;
  color: $color-grey;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 13px;
    padding-bottom: 13px;
  }

  .links {
    display: inline-flex;
    align-items: center;

    a {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .reviews-total {
    margin-left: 45px;
  }
  
  .phone {
    display: inline-flex;
    align-items: center;
    margin-left: auto;

    a {
      margin-left: 10px;
    }
  }

  a {
    color: $color-grey;
    transition: color 0.3s ease;

    &:hover {
      color: $color-red;
    }
  }

  @include media-breakpoint-up(xl) {
    .links {
      a {
        margin-right: 30px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .links {
      a {
        margin-right: 45px;
      }
    }
  }
}