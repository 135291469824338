.listing-bottom-description {
  margin-top: 55px;
  margin-bottom: 65px;

  .editor-content {
    h1, h2, h3, h4, h5, h6 {
      font-family: $font-primary;
    }

    h3 {
      margin-top: 50px;
      font-size: 18px;
    }
  }

  @include media-breakpoint-up(lg) {
    font-size: 24px;
  }
}