#sorting {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 48px 40px;
  background-color: $color-white;
  z-index: $zindex-fixed;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .btn-close {
    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
  }

  h2 {
    margin-bottom: 22px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    color: $color-black;
  }

  ul {
    li {
      + li {
        margin-top: 9px;
      }

      a {
        font-family: $font-secondary;
        color: $color-black;

        &.active {
          color: $color-hover-red;
        }
      }
    }
  }
}