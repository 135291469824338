.category-banner {
  position: relative;
  margin-bottom: 36px;
  background: $color-black;

  &.collection {
    background: $color-lighter-grey-4;

    h1 {
      color: $color-black;
    }

    .description {
      p {
        color: $color-black;
      }
    }
  }

  .page-breadcrumb {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  h1 {
    margin-bottom: 12px;
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.5;
    color: $color-white;
  }

  .description {
    p {
      margin-bottom: 15px;
      font-style: italic;
      font-weight: 400;
      letter-spacing: 0.02em;
      color: $color-white;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .container {
    position: relative;
    padding-bottom: 260px;

    .inner {
      position: relative;
      z-index: 2;
    }
  }

  .category-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: auto 232px;
  }

  @include media-breakpoint-up(md) {
    .page-breadcrumb {
      padding-bottom: 26px;
    }

    h1 {
      margin-bottom: 16px;
      font-size: 48px;
      line-height: 1;
    }

    p {
      font-size: 18px;
      line-height: 1.33;
    }
  }
  @include media-breakpoint-up(lg) {
    position: relative;

    &.collection {
      margin-bottom: 32px;
    }

    .category-img {
      background-position: right 24px center;
      background-size: auto 100%;
    }

    .container {
      min-height: 352px;
      padding-bottom: 0;

      .inner {
        width: 520px;
        padding-bottom: 30px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .container {
      .inner {
        width: 630px;
      }
    }
  }
  @include media-breakpoint-up(xxxl) {
    margin-bottom: 64px;

    &.collection {
      margin-bottom: 32px;
    }

    .container {
      .inner {
        width: 640px;
      }
    }
  }
}