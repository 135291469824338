.header-info-line {
  position: relative;
  height: 40px;
  line-height: 20px;
  padding: 10px 15px;
  vertical-align: middle;
  text-align: center;
  background: $color-red;
  color: #fff;
  font-family: $font-secondary;
  font-size: 15px;
  font-weight: 500;
  z-index: 1020;
  
  @include media-breakpoint-up(xl) {
    position: sticky;
    top: 0;
    z-index: 1021;
  }
}

.info-line-enabled { 
  @include media-breakpoint-up(xl) {
    .site-header {
      top: 40px;
    }
  }
}

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-sticky;

  &.checkout-header {
    .main-nav,
    .menu-bars,
    .secure-buy,
    .nav-icons {
      display: none;
    }

    .main-bar {
      .phone {
        display: inline-flex;
      }
    }
  }

  .main-bar {
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.07);
    background-color: $color-white;

    .container {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .secure-buy {
      display: none;
    }

    .phone {
      display: none;
      flex-direction: column;
      margin-left: auto;
      font-style: italic;
      text-align: right;
      letter-spacing: 0.02em;
      color: $color-grey;

      a {
        color: $color-grey;
        text-decoration: underline;
      }
    }
  }

  .menu-bars {
    display: inline-block;
    line-height: 0;
    margin-right: 20px;

    i {
      font-size: 18px;
      color: $color-black;
    }
  }

  .logo {
    display: inline-block;
    line-height: 0;

    img {
      width: 110px;
      height: auto;
    }
  }

  .nav-icons {
    display: inline-flex;
    align-items: center;
    margin-left: auto;
    line-height: 0;

    li {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }

      &.wish-item {
        display: none;
      }

      &.cart-item {
        display: inline-flex;
      }

      &.profile-item {
        position: relative;

        > a {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: -12px;
            left: 50%;
            width: 28px;
            height: 2px;
            transform: translateX(-50%);
            background: $color-red;
            opacity: 0;
            transition: all 0.3s ease-in-out;
          }

          &.active {
            &:after {
              opacity: 1;
            }
          }
        }

        &.logged {
          > a {
            i {
              color: #07b307;
            }
          }
        }
      }

      a {
        display: inline-flex;
        align-items: center;
        line-height: 0;
        transition: color 0.3s ease;

        &.wishlistInfo {
          .wish-text {
            display: none;
          }
        }

        i {
          font-size: 18px;
          color: $color-black;
          transition: color 0.3s ease;
        }
      }
    }
  }

  @include media-breakpoint-up(xs) {
    .logo {
      img {
        width: 122px;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .main-bar {
      .phone {
        a {
          -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.checkout-header {
      .main-bar {
        .container {
          max-width: 960px;
        }
      }
    }

    .main-bar {
      .phone {
        a {
          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .nav-icons {
      li {
        &.search-item {
          display: none;
        }

        &.wish-item {
          display: inline-block;
        }

        a {
          &:hover {
            color: $color-hover-red;

            i {
              color: $color-hover-red;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    position: sticky;
    left: unset;
    background-color: $color-white;

    &.checkout-header {
      .quick-search-wrp {
        display: none;
      }

      .main-bar {
        padding-top: 20px;
        padding-bottom: 20px;

        .container {
          max-width: 1118px;
        }

        .secure-buy {
          display: inline-flex;
          align-items: center;
          margin-left: 32px;
          font-style: italic;
          letter-spacing: 0.02em;
          color: $color-grey;

          .icon {
            flex-shrink: 0;
            margin-right: 10px;
            width: 16px;
            height: 20px;
            mask-image: url('../../../images/frontend/secure.svg');
            mask-size: contain;
            mask-position: center;
            mask-repeat: no-repeat;
            background-color: $color-grey;
          }
        }

        .phone {
          margin-right: auto;
          display: inline-block;
          margin-left: 40px;
          text-align: left;

          a {
            &:hover {
              text-decoration-line: none;
            }
          }
        }
      }
    }

    .main-bar {
      padding-top: 20px;
      padding-bottom: 20px;
      box-shadow: none;

      .container {
        height: auto;
      }
    }

    .menu-bars {
      display: none;
    }

    .logo {
      img {
        width: 142px;
      }
    }

    .nav-icons {
      li {
        margin-right: 40px;
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    .main-bar {
      padding-top: 28px;
      padding-bottom: 28px;
    }

    .logo {
      img {
        width: 162px;
      }
    }
  }
}