.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-left: -7px;
  margin-right: -7px;

  .pages {
    display: flex;
    align-items: center;

    .page-item {
      margin-right: 1px;

      &:first-child {
        margin-left: 1px;
      }
    }
  }

  .page-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    background: $color-lighter-grey;
    font-style: italic;
    line-height: 1;
    letter-spacing: 0.02em;
    color: $color-black;
    transition: all 0.3s ease;

    &.active {
      background: $color-black;
      color: $color-white;
      cursor: default;
    }

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    &.arrow {
      width: 45px;
      font-size: 16px;

      span {
        display: none;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    a {
      -webkit-tap-highlight-color: rgba($color-hover-red, 0.2);
    }
  }
  @include media-breakpoint-up(xs) {
    .page-item {
      width: 40px;
      height: 40px;

      &.arrow {
        width: 50px;
        font-size: 18px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    margin-top: 40px;

    .page-item {
      &:hover:not(.disabled) {
        background: $color-black;
        color: $color-white;

        &.arrow {
          span {
            color: $color-white;
          }
        }

        span {
          color: $color-white;
        }
      }

      &.arrow {
        width: auto;
        padding-left: 15px;
        padding-right: 15px;
        font-size: 16px;

        &.next {
          span {
            margin-left: 0;
            margin-right: 25px;
          }
        }

        span {
          display: inline-block;
          margin-left: 25px;
          font-style: italic;
          font-weight: 400;
          font-size: 14px;
          line-height: 1;
          letter-spacing: 0.02em;
          color: $color-black;
          transition: all 0.3s ease;
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    margin-top: 90px;
  }
}