// Widget: Range slider

.range-slider-ui {
  height: $range-slider-height;
  margin: 30px 11px 50px;
  border: 0;
  background-color: $range-slider-bg;
  box-shadow: none;

  .noUi-connect {
    background-color: $range-slider-connect-bg;
  }

  .noUi-handle {
    top: 50%;
    width: $range-slider-handle-size;
    height: $range-slider-handle-size;
    margin-top: -($range-slider-handle-size * .5);
    border: 0;
    border-radius: 50%;
    box-shadow: $range-slider-handle-box-shadow;

    &::before, &::after {
      display: none;
    }

    &:focus {
      outline: none;
    }
  }

  .noUi-pips-horizontal {
    padding: 10px 0 0;
    height: 40px;
  }

  .noUi-marker-normal {
    display: none;
  }

  .noUi-marker-horizontal {
    &.noUi-marker {
      width: $border-width;
      background-color: darken($border-color, 6%);
    }

    &.noUi-marker-large {
      height: .75rem;
    }
  }

  .noUi-value {
    padding-top: .125rem;
    color: $body-color;
    font-size: $font-size-ms;
  }

  .noUi-tooltip {
    padding: .25rem .5rem;
    border: 0;
    background-color: $color-black;
    font-family: $font-secondary;
    color: $color-white;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    border-radius: 0;
  }
}

html:not([dir=rtl]) .range-slider-ui.noUi-horizontal .noUi-handle {
  right: -($range-slider-handle-size * .5);
}